import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseService} from 'public-shared/services/base-http/base.service';
import {Observable} from 'rxjs';
import {ScreeningEndpoints} from 'shared/services/endpoint-constants';
import {ScreeningFilter, ScreeningMatchStatusFilter, ScreeningMonitoringFilter} from 'shared/services/verification/screening-filter.dto';
import {ScreeningResult} from 'shared/services/verification/screening-result.dto';

@Injectable()
export class ScreeningService extends BaseService {
  constructor(protected override http: HttpClient) {
    super(http);
  }

  public getScreeningResults$(scanReference: string): Observable<Array<ScreeningResult>> {
    return this.get$<Array<ScreeningResult>>(ScreeningEndpoints.SCREENING_LIST(scanReference));
  }

  public search$(scanReference: string | undefined, filter: ScreeningFilter): Observable<ScreeningResult> {
    return this.post$<ScreeningResult>(ScreeningEndpoints.SCREENING_LIST(scanReference), filter);
  }

  public setMonitoringStatus$(searchId: string | undefined, filter: ScreeningMonitoringFilter): Observable<void> {
    return this.post$<void>(ScreeningEndpoints.MONITORING(searchId), filter);
  }

  public setMatchStatus$(searchId: string | undefined, filter: ScreeningMatchStatusFilter): Observable<void> {
    return this.post$<void>(ScreeningEndpoints.MATCH_STATUS(searchId), filter);
  }
}
