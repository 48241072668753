export class PublicHelpers {
  /**
   * Checks if the currently used browser is related to Internet Explorer or not
   */
  public static isIEBrowser(): boolean {
    // TODO: W2A-1957 - link to some reference that explains why this regex matcher works
    return /msie\s|trident\//i.test(window.navigator.userAgent);
  }

  public static extractRegionAndPostfixFromDomain(domain: string): string {
    if (!domain) {
      return '';
    }

    const regexRegionAndDomain = /[.][a-z]+-[0-9]+.[a-z]+[.][a-z]+/gm;
    const [regionAndDomain] = domain.match(regexRegionAndDomain) ?? [];

    return regionAndDomain ?? '';
  }
}
