<div class="form-group-wrapper" [ngClass]="classes">
  <ng-content select=".form-element"></ng-content>
  <div *ngIf="!hasError">
    <ng-content select=".note"></ng-content>
    <ng-content select="appError"></ng-content>
  </div>
  <div *ngIf="hasError">
    <div #appError type="error">
      <div class="alert-message">
        <ng-container *ngIf="errorsTemplate" [ngTemplateOutlet]="errorsTemplate"></ng-container>
        <ng-container *ngIf="!errorsTemplate">
          <ng-container *ngFor="let errorCode of formControlErrorCodes">
            {{ errorMessage(errorCode) }}
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>
