export enum AppType {
  CLIENT_UI = 'client-ui',
  CLIENT_FACADE = 'client-facade'
}

export interface IVersion {
  path: string;
  'client-ui'?: {
    version: string;
    commitHash: string;
  };
  'client-facade'?: {
    version: string;
    commitHash: string;
  };
}
