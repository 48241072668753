import {BaseAuthEndpoints} from 'public-shared/models/endpoints/base-auth-endpoint.constants';
import {BaseEndpoints} from 'public-shared/models/endpoints/base-endpoint.constants';

export class AuthEndpoints extends BaseAuthEndpoints {
  public static readonly TFA = 'tfa/';
}

export class CountryEndpoints {
  public static readonly COUNTRY_LIST = 'countries/';
  public static readonly SETTINGS = 'settings/';
  public static readonly SEARCH = CountryEndpoints.COUNTRY_LIST + 'search/';
  public static readonly BASE = CountryEndpoints.SETTINGS + CountryEndpoints.COUNTRY_LIST;

  public static readonly DETAILS = CountryEndpoints.BASE + 'details/';
  public static readonly INIT = CountryEndpoints.DETAILS + 'init/';

  public static readonly COUNTRY_DETAILS = (code: string): string => `${CountryEndpoints.DETAILS}${code}/`;
}

export class CredentialsEndpoints {
  public static readonly BASE = BaseEndpoints.SETTINGS + 'credentials/';
  public static readonly GET_API_TOKENS = 'api-tokens';
  public static readonly GET_SECRET = 'get-secret/';
  public static readonly GENERATE_SECRET = 'generate-secret/';
  public static readonly ACTIVATE_SECRET = 'activate-secret/';
  public static readonly SAVE_SECRET = 'save-secret/';
  public static readonly GET_CREDENTIALS = 'get-credentials/';
  public static readonly GENERATE_CREDENTIALS = 'generate-credentials/';
  public static readonly ACTIVATE_CREDENTIALS = 'activate-credentials/';
  public static readonly SAVE_CREDENTIALS = 'save-credentials/';
  public static readonly DEMO_APP_URL = 'demo-app-url/';
  public static readonly OFFLINE_TOKEN = 'offline-token/';
  public static readonly ACTIVATE_API_TOKEN = CredentialsEndpoints.GET_API_TOKENS + '/activate/';
  public static readonly DEACTIVATE_API_TOKEN = CredentialsEndpoints.GET_API_TOKENS + '/deactivate/';
  public static readonly CREATE_API_TOKEN = CredentialsEndpoints.GET_API_TOKENS + '/create/';
}

export class OAuthCredentialsEndpoints {
  public static readonly BASE = BaseEndpoints.SETTINGS + 'credentials/api-tokens/oauth';
  public static readonly ACTIVATE_API_TOKEN = '/activate/';
  public static readonly DEACTIVATE_API_TOKEN = '/deactivate/';
  public static readonly CREATE_API_TOKEN = '/create/';
}

export class CredentialsBmuiEndpoints extends CredentialsEndpoints {
  public static override readonly BASE = BaseEndpoints.SETTINGS + 'bmui/credentials/';
  public static readonly GET_BAM_CC_SCAN = 'get-bam-cc-scan/';
  public static readonly GENERATE_BAM_CC_SCAN = 'generate-bam-cc-scan/';
  public static readonly ACTIVATE_BAM_CC_SCAN = 'activate-bam-cc-scan/';
  public static readonly SAVE_BAM_CC_SCAN = 'save-bam-cc-scan/';
}

export class DocumentsEndpoints {
  public static readonly BASE = BaseEndpoints.V1 + 'documents/';
  public static readonly DOCUMENTS = '';
}

export class FraudEndpoints {
  public static readonly INIT = 'init/';
  public static readonly CSV = 'csv/';
  public static readonly UPDATE_CSV = FraudEndpoints.CSV + 'update/';
  public static readonly FRAUD = (id: string): string => `${id}/`;
  public static readonly MARK_FRAUD = (id: string): string => FraudEndpoints.FRAUD(id) + 'mark-fraud/';
  public static readonly MARK_APPROVED = (id: string): string => FraudEndpoints.FRAUD(id) + 'mark-approved/';
  public static readonly MARK_FUZZY_FRAUD = (id: string): string => FraudEndpoints.FRAUD(id) + 'fuzzy/mark-fraud/';
  public static readonly MARK_FUZZY_APPROVED = (id: string): string => FraudEndpoints.FRAUD(id) + 'fuzzy/mark-approved/';
  public static readonly EXCLUDE_SCAN = (id: string): string => FraudEndpoints.FRAUD(id) + 'fuzzy/exclude/';
  public static readonly EDIT = (id: string): string => FraudEndpoints.FRAUD(id) + 'edit/';
  public static readonly ADD = (id: string): string => FraudEndpoints.FRAUD(id) + 'add/';
  public static readonly DELETE = (scanId: string, id: string): string => scanId + '/' + FraudEndpoints.FRAUD(id);
}

export class FraudVerificationEndpoints {
  public static readonly BASE = BaseEndpoints.V1 + 'fraud/';
}

export class FraudWorkflowEndpoints {
  public static readonly BASE = BaseEndpoints.V1 + 'fraud/platform/';
}

export class RemediationEndpoints {
  public static readonly BASE = 'remediation/';
}

export class IdsEndpoints {
  public static readonly BASE = BaseEndpoints.V1 + 'ids/';
  public static readonly INIT = 'init/';
  public static readonly FILTER = 'filter/';
  public static readonly DOCUMENT = (id?: string): string => (id ? `${id}/` : '');
}

export class ImageEndpoints {
  public static readonly BASE = BaseEndpoints.V1 + 'images/';
  public static readonly DELETE = (id: string): string => `delete/${id}/`;
  public static readonly REQUEST_MASKING = (id: string): string => `requestmasking/${id}/`;
}

export class MaskingEndpoints {
  public static readonly BASE = BaseEndpoints.V1 + 'remasking/';
  public static readonly V2_MASKING = (scanId: string): string => `v2/${scanId}/`;
  public static readonly V3_MASKING = (workflowExecutionId: string): string => `v3/${workflowExecutionId}/`;
  public static readonly KYX_MASKING = (workflowExecutionId: string): string => `kyx/${workflowExecutionId}/`;
}

export class MailTemplatesEndpoints {
  public static readonly BASE = BaseEndpoints.V1 + 'mail-templates/';
  public static readonly INIT = 'init/';
  public static readonly FILTER = 'filter/';
  public static readonly MAIL_TEMPLATE = (id?: string): string => (id ? `${id}/` : '');
}

export class MerchantEndpoints {
  public static readonly BASE = BaseEndpoints.V1 + 'merchants/';
  public static readonly BASEV2 = BaseEndpoints.V2 + 'merchants/';
  public static readonly MERCHANT = '';
  public static readonly INIT = 'init/';
  public static readonly FILTER = 'filter/';
  public static readonly SEARCH = 'search/';
  public static readonly TYPES = 'types/';
  public static readonly ACCEPTED_IDS = 'acceptedids/';
  public static readonly ACCEPTED_IDS_COUNTRY = MerchantEndpoints.ACCEPTED_IDS + 'country/';
  public static readonly ACCEPTED_IDS_COUNTRY_DOWNLOAD = MerchantEndpoints.ACCEPTED_IDS_COUNTRY + 'download/';
  public static readonly ACCEPTED_IDS_REGION = MerchantEndpoints.ACCEPTED_IDS + 'region/';

  public static readonly GENERAL = 'general/';
  public static readonly REDIRECT = 'redirect/';

  public static readonly SLA_SETTINGS_INIT = 'slasettings/' + MerchantEndpoints.INIT;

  // Application Settings
  public static readonly APPLICATION_SETTINGS = 'applicationsettings/';

  public static readonly APPLICATION_SETTINGS_GENERAL = MerchantEndpoints.APPLICATION_SETTINGS + MerchantEndpoints.GENERAL;
  public static readonly APPLICATION_SETTINGS_GENERAL_INIT = MerchantEndpoints.APPLICATION_SETTINGS_GENERAL + MerchantEndpoints.INIT;

  public static readonly APPLICATION_SETTINGS_REDIRECT = MerchantEndpoints.APPLICATION_SETTINGS + MerchantEndpoints.REDIRECT;
  public static readonly APPLICATION_SETTINGS_REDIRECT_INIT = MerchantEndpoints.APPLICATION_SETTINGS_REDIRECT + MerchantEndpoints.INIT;

  // Customize Client
  public static readonly CUSTOMIZE_CLIENT = 'customizeclient/';

  public static readonly CUSTOMIZE_CLIENT_GENERAL = MerchantEndpoints.CUSTOMIZE_CLIENT + MerchantEndpoints.GENERAL;
  public static readonly CUSTOMIZE_CLIENT_GENERAL_INIT = MerchantEndpoints.CUSTOMIZE_CLIENT_GENERAL + MerchantEndpoints.INIT;

  public static readonly CUSTOMIZE_CLIENT_REDIRECT = MerchantEndpoints.CUSTOMIZE_CLIENT + MerchantEndpoints.REDIRECT;
  public static readonly CUSTOMIZE_CLIENT_REDIRECT_INIT = MerchantEndpoints.CUSTOMIZE_CLIENT_REDIRECT + MerchantEndpoints.INIT;

  // Multi documents
  public static readonly MULTI_DOCUMENTS = 'multidocuments/';
  public static readonly MULTI_DOCUMENTS_INIT = MerchantEndpoints.MULTI_DOCUMENTS + MerchantEndpoints.INIT;
  public static readonly MULTI_DOCUMENTS_CUSTOM = MerchantEndpoints.MULTI_DOCUMENTS + 'custom/';
  public static readonly MULTI_DOCUMENTS_SUPPORTED = MerchantEndpoints.MULTI_DOCUMENTS + 'supported/';

  // Data Settings
  public static readonly DATA_SETTINGS = 'datasettings/';
  public static readonly DATA_SETTINGS_MANDATORY = MerchantEndpoints.DATA_SETTINGS + 'mandatoryfields/';
  public static readonly DATA_SETTINGS_OPTIONAL = MerchantEndpoints.DATA_SETTINGS + 'optionalfields/';
  public static readonly DATA_SETTINGS_ENABLED = MerchantEndpoints.DATA_SETTINGS + 'enabledfields/';
  public static readonly DATA_SETTINGS_RETENTION = MerchantEndpoints.DATA_SETTINGS + 'dataretention/';
  public static readonly DATA_SETTINGS_SCREENING = MerchantEndpoints.DATA_SETTINGS + 'screening/';
  public static readonly DATA_SETTINGS_WATCHLIST_SCREENING = MerchantEndpoints.DATA_SETTINGS + 'watchlist-screening/';
  public static readonly DATA_SETTINGS_RETENTION_INIT = MerchantEndpoints.DATA_SETTINGS_RETENTION + MerchantEndpoints.INIT;

  // J4 customer migration
  public static readonly MIGRATE = 'migrate/';

  // Settings
  public static readonly SETTINGS = (id: string): string => `${id}/settings/`;
  public static readonly SETTINGS_TYPE = (id: string, type: string): string => `${MerchantEndpoints.SETTINGS(id)}${type}/`;
  public static readonly GENERIC_VALUE = (id: string, key: string): string => `${MerchantEndpoints.SETTINGS(id)}netverify/${key}`;

  // SLA Settings
  public static readonly SLA_SETTINGS = (id: string): string => `${id}/slasettings/`;

  // Multi documents
  public static readonly MERCHANT_MULTI_DOCUMENTS = (id: string): string => `${id}/multidocuments/`;
  public static readonly MULTI_DOCUMENT = (documentId: string): string => `${MerchantEndpoints.MULTI_DOCUMENTS}${documentId}/`;

  public static readonly OFFLINE_TOKEN_GENERATE = (id: string): string => `${id}/settings/offlinetoken/generate`;

  public static readonly MUI_PREFIX = (): string => '';
  public static readonly AUI_PREFIX = (id: string): string => `${id}/`;

  // Ip Whitelist
  public static readonly IP_WHITELIST = (merchantid: string): string => `ip-whitelist/${merchantid}`;

  public static readonly DUPLICATE = (id: string): string => `${id}/duplicate`;

  // Jumio Platform Upgrade
  public static readonly JUMIO_PLATFORM_UPGRADE_SETTINGS = (merchantId: string): string => `${merchantId}/settings/kyx/enablement`;
  public static readonly UPDATE_JUMIO_PLATFORM_UPGRADE_SETTINGS = (merchantId: string): string =>
    `${merchantId}/settings/kyx/enablement/update`;
}

export class MerchantIntegrationSettingsEndpoints {
  public static readonly BASE = BaseEndpoints.V1 + 'integration/';
  public static readonly SETTINGS = 'settings/';
  public static readonly MERCHANT_SETTINGS = (merchantid: string): string =>
    `${MerchantIntegrationSettingsEndpoints.SETTINGS}merchant/${merchantid}`;
}

export class MyProfileEndpoints {
  public static readonly BASE = BaseEndpoints.V1 + 'my-profile/';
  public static readonly ACCOUNT = 'account/';
  public static readonly PASSWORD = 'password/';
}

export class RoleEndpoints {
  public static readonly BASE = BaseEndpoints.V1 + 'roles/';
  public static readonly ROLES = '';
}

export class SettingsEndpoints {
  public static readonly AUTDIT_LOG = 'audit-log';

  public static readonly SETTINGS = 'settings';
  public static readonly SLA_FRAUD_CHECK = 'slafraudcheck/';
  public static readonly SLA_FRAUD_CHECK_INIT = SettingsEndpoints.SLA_FRAUD_CHECK + 'init/';
  public static readonly COUNTRIES = 'countries/';
  public static readonly COUNTRIES_SEARCH = SettingsEndpoints.COUNTRIES + 'search/';
  public static readonly COUNTRIES_CSV = SettingsEndpoints.COUNTRIES + 'csv/';
  public static readonly SUPPORTED_IDS = 'supportedids/';
  public static readonly SUPPORTED_IDS_CSV = SettingsEndpoints.SUPPORTED_IDS + 'csv/';

  public static readonly MOBILE = 'mobile';
  public static readonly MOBILE_UPDATE = `${SettingsEndpoints.MOBILE}/update`;
  public static readonly MOBILE_UPDATE_LIST = `${SettingsEndpoints.MOBILE}/update-list`;
  public static readonly MOBILE_CREATE = `${SettingsEndpoints.MOBILE}/create`;
  public static readonly MOBILE_DELETE = `${SettingsEndpoints.MOBILE}/delete`;

  public static readonly CONFIGURATION = `configuration`;
  public static readonly CONSENT = `consent`;
  public static readonly CUSTOMER_CONSENT = `customer-consent`;

  public static readonly CONSENT_INIT = `${SettingsEndpoints.CONSENT}/init`;
  public static readonly GLOBAL_CONSENT_UPDATE_LIST = `${SettingsEndpoints.CONSENT}/update`;
  public static readonly CUSTOMER_CONSENT_CONFIGURATION_INIT = (customerId: string): string =>
    `${SettingsEndpoints.CUSTOMER_CONSENT}/${SettingsEndpoints.CONFIGURATION}/${customerId}`;
  public static readonly CUSTOMER_CONSENT_CONFIGURATION_UPDATE = (customerId: string): string =>
    `${SettingsEndpoints.CUSTOMER_CONSENT}/${SettingsEndpoints.CONFIGURATION}/${customerId}`;
  public static readonly CUSTOMER_CONSENT_LIST = (customerId: string): string => `${SettingsEndpoints.CUSTOMER_CONSENT}/${customerId}`;
  public static readonly CUSTOMER_CONSENT_UPDATE_LIST = (customerId: string): string =>
    `${SettingsEndpoints.CUSTOMER_CONSENT}/${customerId}/update`;
}

export class TryNowEndpoints {
  public static readonly BASE = BaseEndpoints.V1 + 'try-now/';
  public static readonly OTHER = 'other-document-types/';
  public static readonly ID = 'id-verification-url/';
  public static readonly MULTI_DOCUMENT = 'multi-document-verification-url/';
  public static readonly DOCUMENT = 'document-verification-url/';
  public static readonly CREATE_ID_VERIFICATION = 'create-id-verification/';
  public static readonly INIT = TryNowEndpoints.CREATE_ID_VERIFICATION + 'init/';
}

export class TryNowPlatformEndpoints {
  public static readonly BASE = BaseEndpoints.V1 + 'try-now/platform/';
  public static readonly INIT = 'init/';
  public static readonly INIT_V3 = 'init-v3/';
  public static readonly INIT_KYX = 'init-kyx/';
  public static readonly CREATE_TRANSACTION = 'create-transaction/';
}

export class UserEndpoints {
  public static readonly BASE = BaseEndpoints.V1 + 'users/';
  public static readonly INIT = 'init/';
  public static readonly INIT_MERCHANTS = UserEndpoints.INIT + 'merchant/';
  public static readonly INIT_ADD_USER = UserEndpoints.INIT + 'add-user/';
  public static readonly FILTER = 'filter/';
  public static readonly MERCHANT_FILTER = 'merchant/filter/';
  public static readonly DOWNLOAD = 'download/';
  public static readonly BULK_ACTIVATE = 'bulk-activate/';
  public static readonly BULK_DEACTIVATE = 'bulk-deactivate/';
  public static readonly BULK_ADD = 'bulk-add/';

  public static readonly USER = (id?: string): string => (id ? `${id}/` : '');
  public static readonly PARSE_CSV = (action: string): string => `bulk/csv/${action}/parse/`;
  public static readonly BULK_CSV = (action: string): string => `bulk/${action}/`;

  public static readonly ACTIVATE = (id?: string): string => UserEndpoints.USER(id) + 'activate/';
  public static readonly DEACTIVATE = (id?: string): string => UserEndpoints.USER(id) + 'deactivate/';
  public static readonly RESEND = (id?: string): string => UserEndpoints.USER(id) + 'resend/';
  public static readonly UNLOCK = (id?: string): string => UserEndpoints.USER(id) + 'unlock/';
  public static readonly RESET_TFA = (id?: string): string => UserEndpoints.USER(id) + 'resettfa/';
}

export class VerificationEndpoints {
  public static readonly VERIFICATIONS = 'verifications';
  public static readonly BASE = BaseEndpoints.V1 + `${VerificationEndpoints.VERIFICATIONS}/`;
  public static readonly FILTER = 'filter/';
  public static readonly INIT = 'init/';
  public static readonly REJECT_REASON_DETAILS = 'reject-reasons-details/';
  public static readonly CREATE_ID = 'create-id-verification/';
  public static readonly CREATE_ID_INIT = VerificationEndpoints.CREATE_ID + 'init/';
  public static readonly CREATE_DOCUMENT = 'create-document-verification/';
  public static readonly CREATE_DOCUMENT_INIT = VerificationEndpoints.CREATE_DOCUMENT + 'init/';
  public static readonly CSV = 'csv/';
  public static readonly AGENT_SEARCH = 'agent-search/';
  public static readonly IMAGE = 'images';
  public static readonly RESCAN = (id: string): string => `${id}/reprocess`;

  public static readonly VERIFICATION = (id: string): string => `${id}`;
  public static readonly VERIFICATION_WITH_TRANSACTION = (id: string, transationReference: string): string =>
    `${id}/${transationReference}`;
  public static readonly DELETE = (id: string): string => `${id}/delete/`;
  public static readonly IMAGES = (id: string): string => `${id}/${VerificationEndpoints.IMAGE}/`;
  public static readonly IMAGE_WITH_CLASSIFIER = (id: string, classifier: string): string =>
    `${id}/${VerificationEndpoints.IMAGE}/${classifier}`;
  public static readonly AUDIT_LOG = (id: string): string => `${id}/auditlog/`;
  public static readonly EVENTS_LOG = (id: string): string => `${id}/eventslog/`;
  public static readonly IMAGE_HASH = (id: string): string => `imagehash/${id}/`;
  public static readonly IMAGE_HASH_APPROVE = (id: string): string => VerificationEndpoints.IMAGE_HASH(id) + 'approve/';
  public static readonly IMAGE_HASH_REJECT = (id: string): string => VerificationEndpoints.IMAGE_HASH(id) + 'reject/';
}

export class AuthenticationsEndpoints {
  public static readonly BASE = BaseEndpoints.V1 + 'authentications/';
  public static readonly AUTHENTICATIONS = (scanReference: string, transactionReference: string): string =>
    `${scanReference}/${transactionReference}`;
}

export class WorkflowEndpoints {
  public static readonly BASE = BaseEndpoints.V1 + 'workflow/';

  public static readonly ADL = (scanReference: string, wfid: string): string => `${scanReference}` + '/' + `${wfid}`;

  public static readonly USABILITY_RESULTS = (scanReference: string): string => `usability/${scanReference}`;
}

export class ExperimentEndpoints {
  public static readonly BASE_V1 = 'experiment-ui/';
  public static readonly BASE_V2 = 'v2/experiment-ui/';
  public static readonly BASE_V3 = 'v3/experiment-ui/';

  public static readonly EXPERIMENTS = 'experiments';
  public static readonly EXPERIMENTS_SEARCH = ExperimentEndpoints.EXPERIMENTS + '/search';
  public static readonly EXPERIMENTS_EXPORT = ExperimentEndpoints.EXPERIMENTS + '/export';
  public static readonly DERIVED = ExperimentEndpoints.EXPERIMENTS + '/derived';
  public static readonly REVIEW = ExperimentEndpoints.EXPERIMENTS + '/review';
  public static readonly EXPERIMENTS_BY_DATASETS = ExperimentEndpoints.EXPERIMENTS + '/by-dataset';
  public static readonly QUEUES = ExperimentEndpoints.EXPERIMENTS + '/queues';

  public static readonly DATASETS = 'datasets';
  public static readonly AVAILABLE_DATASETS = ExperimentEndpoints.DATASETS + '/available-for-experiment';
  public static readonly DATASETS_SEARCH = ExperimentEndpoints.DATASETS + '/search';
  public static readonly REGRESSION_EVENT_SCANS = ExperimentEndpoints.DATASETS + '/regression';
  public static readonly ADD_REGRESSION_EVENT_SCANS = ExperimentEndpoints.REGRESSION_EVENT_SCANS + '/add';
  public static readonly DELETE_REGRESSION_EVENT_SCANS = ExperimentEndpoints.REGRESSION_EVENT_SCANS + '/delete';
  public static readonly DIRECT_DATASETS = ExperimentEndpoints.DATASETS + '/direct';
  public static readonly CROSS_TABLE_DATASET_QUERY_PREVIEW = ExperimentEndpoints.DIRECT_DATASETS + '/preview';
  public static readonly INDIRECT_DATASETS = ExperimentEndpoints.DATASETS + '/derived';
  public static readonly PREVIEW_INDIRECT_DATASETS_ASYNC = ExperimentEndpoints.DATASETS + '/preview?async=true';
  public static readonly DATASET_UPLOAD = ExperimentEndpoints.DATASETS + '/upload';
  public static readonly QUERY_COUNT_PREVIEW = ExperimentEndpoints.DIRECT_DATASETS + '/count';
  public static readonly DATASET_COPY_IMAGES = ExperimentEndpoints.DATASETS + '/images/copy';

  // Legacy Tagging Templates
  public static readonly LEGACY_TAGGING_TEMPLATES = 'tagging-templates';
  public static readonly LEGACY_TEMPLATES = 'legacy-template';

  // Tagging Templates
  public static readonly TAGGING_TEMPLATES = 'templates';
  public static readonly TAGGING_TEMPLATES_SEARCH = ExperimentEndpoints.TAGGING_TEMPLATES + '/search';

  // Pries
  public static readonly CREATE_PRIES = 'pries-job/create-and-run-experiment';

  public static readonly DATASET = (id: string): string => `${ExperimentEndpoints.DATASETS}/${id}`;
  public static readonly PREVIEW_INDIRECT_DATASETS = (id: string): string => `${ExperimentEndpoints.DATASETS}/preview/${id}`;
  public static readonly DATASET_ARCHIVE = (id: string): string => `${ExperimentEndpoints.DATASETS}/${id}/archive`;
  public static readonly DATASET_UNARCHIVE = (id: string): string => `${ExperimentEndpoints.DATASETS}/${id}/unarchive`;
  public static readonly DATASET_CANCEL = (id: string): string => `${ExperimentEndpoints.DATASETS}/${id}/cancel`;

  public static readonly DATASET_EXPORT = (id: string): string => `${ExperimentEndpoints.DATASETS}/${id}/export`;
  public static readonly DATASET_RESET_EXPORT = (id: string): string => `${ExperimentEndpoints.DATASETS}/${id}/export/reset`;
  public static readonly DATASET_UPLOAD_FINALIZE = (id: string): string => `${ExperimentEndpoints.DATASET_UPLOAD}/${id}`;
  public static readonly DATASET_UPLOAD_RESULT = (id: string): string => `${ExperimentEndpoints.DATASET_UPLOAD}/${id}`;
  public static readonly DATASET_REMAINING_TIME = (id: string): string => `${ExperimentEndpoints.DATASETS}/${id}/remaining-time`;
  public static readonly DATASET_REMAINING_EXPORT_TIME = (id: string): string =>
    `${ExperimentEndpoints.DATASETS}/${id}/export/remaining-time`;

  public static readonly DATASET_EXPORT_DOWNLOAD = (id: string, datasetContentType: string): string =>
    `${ExperimentEndpoints.DATASETS}/${id}/export/${datasetContentType}/download`;

  public static readonly DATASET_EXPORT_INFO = (id: string): string => `${ExperimentEndpoints.DATASETS}/${id}/export/info`;

  public static readonly DATASET_INPUTS = (id: string): string => `${ExperimentEndpoints.DATASET(id)}/input`;
  public static readonly DATASET_INPUT_FILE = (id: string, filename: string): string =>
    `${ExperimentEndpoints.DATASET_INPUTS(id)}/${filename}`;

  public static readonly EXPERIMENT = (id: string): string => `${ExperimentEndpoints.EXPERIMENTS}/${id}`;
  public static readonly EXPERIMENT_STATISTICS = (id: string): string => `${ExperimentEndpoints.EXPERIMENTS}/${id}/statistics`;
  public static readonly EXPERIMENT_ARCHIVE = (id: string): string => `${ExperimentEndpoints.EXPERIMENTS}/${id}/archive`;
  public static readonly EXPERIMENT_UNARCHIVE = (id: string): string => `${ExperimentEndpoints.EXPERIMENTS}/${id}/unarchive`;
  public static readonly EXPERIMENT_ACTION = (id: string, action: string): string => `${ExperimentEndpoints.EXPERIMENT(id)}/${action}`;
  public static readonly EXPERIMENT_ACTION_START = (id: string, user: string): string =>
    `${ExperimentEndpoints.EXPERIMENT(id)}/start/by/${user}`;
  public static readonly UPDATE_BATCH_SETUP = (id: string): string => `${ExperimentEndpoints.EXPERIMENT(id)}/batch-setup`;
  public static readonly SUBMIT_REVIEW = (id: string): string => `${ExperimentEndpoints.EXPERIMENT(id)}/review`;
  public static readonly GENERATE_BULK_RESULT = (id: string): string => `${ExperimentEndpoints.EXPERIMENT(id)}/result/generate`;
  public static readonly DOWNLOAD_QUERY_RESULT = (id: string): string => `${ExperimentEndpoints.EXPERIMENTS}/${id}/query-result/download`;
  public static readonly REFRESH_BUCKET_IMAGES = (id: string): string => `${ExperimentEndpoints.EXPERIMENTS}/${id}/refresh-images`;
  public static readonly GENERATE_QUERY_RESULT = (id: string): string => `${ExperimentEndpoints.EXPERIMENT(id)}/query-result/generate`;

  public static readonly EXPERIMENTS_BY_DATASET = (datasetId: string): string =>
    `${ExperimentEndpoints.EXPERIMENTS_BY_DATASETS}/${datasetId}`;
  public static readonly EXPERIMENT_BY_TARGET_DATASET = (datasetId: string): string =>
    `${ExperimentEndpoints.EXPERIMENTS}/by-target-dataset/${datasetId}`;

  public static readonly EVENTS_LOG = (id: string): string => `${id}/eventslog/`;

  // Legacy Tagging Templates
  public static readonly LEGACY_TEMPLATE = (id: number): string => `${ExperimentEndpoints.LEGACY_TEMPLATES}/${id}`;
  public static readonly LEGACY_TAGGING_TEMPLATE = (id: number): string => `${ExperimentEndpoints.LEGACY_TAGGING_TEMPLATES}/${id}`;

  // Tagging Templates
  public static readonly TAGGING_TEMPLATE = (id: string): string => `${ExperimentEndpoints.TAGGING_TEMPLATES}/${id}`;
  public static readonly TAGGING_TEMPLATES_UPDATE = (id: string): string => `${ExperimentEndpoints.TAGGING_TEMPLATES}/${id}`;
  public static readonly TAGGING_TEMPLATES_ARCHIVE = (id: string): string => `${ExperimentEndpoints.TAGGING_TEMPLATES}/${id}/archive`;
  public static readonly TAGGING_TEMPLATES_UNARCHIVE = (id: string): string => `${ExperimentEndpoints.TAGGING_TEMPLATES}/${id}/unarchive`;
  public static readonly DOWNLOAD_TAGGING_RESULT = (id: string): string =>
    `${ExperimentEndpoints.EXPERIMENTS}/${id}/tagging-result/download`;
  public static readonly GENERATE_TAGGING_RESULT = (id: string): string => `${ExperimentEndpoints.EXPERIMENT(id)}/tagging-result/generate`;
}

export class ReportEndpoints {
  public static readonly BASE = BaseEndpoints.V1;
  public static readonly REPORTS = ReportEndpoints.BASE + 'reporting';
  public static readonly CONFIGURATION = `${ReportEndpoints.REPORTS}/configuration`;
  public static readonly REPORT_BULK_DELETE = `${ReportEndpoints.REPORTS}/bulk-delete`;
  public static readonly REPORT_DOWNLOAD = (reportGuid: string): string => ReportEndpoints.REPORTS + `/${reportGuid}/download`;
  public static readonly REPORT_DELETE = (reportGuid: string): string => ReportEndpoints.REPORTS + `/${reportGuid}`;
}

export class PlatformReportEndpoints {
  public static readonly BASE = BaseEndpoints.V1;
  public static readonly REPORTS = PlatformReportEndpoints.BASE + 'reporting/platform';
  public static readonly CONFIGURATION = `${PlatformReportEndpoints.REPORTS}/configuration`;
  public static readonly REPORT_BULK_DELETE = `${PlatformReportEndpoints.REPORTS}/bulk-delete`;
  public static readonly REPORT_DOWNLOAD = (reportGuid: string): string => PlatformReportEndpoints.REPORTS + `/${reportGuid}/download`;
  public static readonly REPORT_DELETE = (reportGuid: string): string => PlatformReportEndpoints.REPORTS + `/${reportGuid}`;
}

export class FeedbackEndpoints {
  public static readonly BASE = BaseEndpoints.V1;
  public static readonly FEEDBACK = ReportEndpoints.BASE + 'feedback';
}

export class RiskManagementEndpoints {
  public static readonly BASE = BaseEndpoints.V1;
  public static readonly RISK_MANAGEMENT = RiskManagementEndpoints.BASE + 'risk-management';

  public static readonly ADD_RISK_SCORE = (publicId: string): string => `${RiskManagementEndpoints.RISK_MANAGEMENT}/${publicId}/add`;
  public static readonly EDIT_RISK_SCORE = (publicId: string): string => `${RiskManagementEndpoints.RISK_MANAGEMENT}/${publicId}/edit`;
  public static readonly DELETE_RISK_SCORE = (publicId: string): string => `${RiskManagementEndpoints.RISK_MANAGEMENT}/${publicId}/delete`;
}

export class ScreeningEndpoints {
  public static readonly BASE = BaseEndpoints.V1;
  public static readonly SCREENING = ScreeningEndpoints.BASE + 'screening';
  public static readonly SCREENING_LIST = (scanReference?: string): string => ScreeningEndpoints.SCREENING + `/${scanReference}/`;
  public static readonly MONITORING = (searchId?: string): string => ScreeningEndpoints.SCREENING + `/monitor-status/${searchId}/`;
  public static readonly MATCH_STATUS = (searchId?: string): string => ScreeningEndpoints.SCREENING + `/match-status/${searchId}/`;
}

export class SubProcessorsEndpoints {
  public static readonly BASE = BaseEndpoints.V1;
  public static readonly SUB_PROCESSORS = ReportEndpoints.BASE + 'sub-processors';
}

export class CustomQueuesEndpoints {
  public static readonly BASE = BaseEndpoints.V1 + 'custom-queue/';
  public static readonly INIT = CustomQueuesEndpoints.BASE + 'init/';
  public static readonly CREATE = CustomQueuesEndpoints.BASE + 'create/';
  public static readonly UPDATE = CustomQueuesEndpoints.BASE + 'update/';
  public static readonly DELETE = (queueName: string): string => CustomQueuesEndpoints.BASE + `${queueName}/`;
}

export class DialEndpoints {
  public static readonly BASE = BaseEndpoints.V1 + 'dial';
}

export class WorkflowExecutionsEndpoints {
  public static readonly BASE = BaseEndpoints.V1 + 'platform/';
  public static readonly WORKFLOW_EXECUTIONS = 'workflow-executions/';
  public static readonly SEARCH = `${WorkflowExecutionsEndpoints.BASE}${WorkflowExecutionsEndpoints.WORKFLOW_EXECUTIONS}search/`;
  public static readonly INIT = WorkflowExecutionsEndpoints.BASE + 'init/';
  public static readonly FILTER = 'filter/';
  public static readonly RESCAN = (workflowId: string): string =>
    `${WorkflowExecutionsEndpoints.BASE}${WorkflowExecutionsEndpoints.WORKFLOW_EXECUTIONS}${workflowId}/reprocess/`;
  public static readonly DETAIL = (id: string): string =>
    `${WorkflowExecutionsEndpoints.BASE}${WorkflowExecutionsEndpoints.WORKFLOW_EXECUTIONS}${id}`;
  public static readonly EVENTS_LOG = (id: string): string =>
    `${WorkflowExecutionsEndpoints.BASE}${WorkflowExecutionsEndpoints.WORKFLOW_EXECUTIONS}${id}/eventslog/`;
  public static readonly AUDIT_LOG = (id: string): string =>
    `${WorkflowExecutionsEndpoints.BASE}${WorkflowExecutionsEndpoints.WORKFLOW_EXECUTIONS}${id}/auditlog`;
}

export class AccountsEndpoints {
  public static readonly BASE = BaseEndpoints.V1 + 'platform/accounts/';
  public static readonly WORKFLOWS = (id: string): string => `${id}`;
}

export class KYXTransactionsEndpoints {
  public static readonly BASE = BaseEndpoints.V1 + `kyx`;
  public static readonly TRANSACTION = '/transaction';
  public static readonly INIT = '/init';
  public static readonly SEARCH = '/search';
  public static readonly EVENTS_LOG = (id: string): string => `${KYXTransactionsEndpoints.TRANSACTION}/${id}/eventslog/`;
}

export class OrchestrationEndpoints {
  public static readonly BASE = `${BaseEndpoints.V1}kyx`;
  public static readonly MANAGER = '/manager';
  public static readonly MERCHANT = '/merchant';
  public static readonly WORKFLOW = '/workflow';
}

// Lightning Project
export class IntegrationEndpoints {
  public static readonly BASE = BaseEndpoints.V1 + `integration`;
  public static readonly LINK = '/link';
  public static readonly FOUR_STOP = `${IntegrationEndpoints.BASE}${IntegrationEndpoints.LINK}/4s`;
  public static readonly JTM = `${IntegrationEndpoints.BASE}${IntegrationEndpoints.LINK}/jtm`;
  public static readonly INIT_DASHBOARD = `${IntegrationEndpoints.BASE}/dashboard/init`;
}

export class J4CustomerEndpoints {
  public static readonly BASE = BaseEndpoints.V1 + 'j4/customer-management/';
  public static readonly CUSTOMER = `customers`;
  public static readonly INIT = 'init/';
  public static readonly FILTER = 'filter/';
  public static readonly SEARCH = 'search/';

  // Settings
  public static readonly MUI_PREFIX = (): string => '';
  public static readonly AUI_PREFIX = (id: string): string => `${id}/`;
}

export class J4TenantsEndpoints {
  public static readonly BASE = BaseEndpoints.V1 + 'j4/';
  public static readonly TENANTS_MGT = 'tenant-management/';
  public static readonly TENANTS = `${J4TenantsEndpoints.TENANTS_MGT}tenants/`;
  public static readonly INIT = `${J4TenantsEndpoints.TENANTS_MGT}init/`;
  public static readonly FILTER = `${J4TenantsEndpoints.TENANTS_MGT}filter/`;
  public static readonly SEARCH = `${J4TenantsEndpoints.TENANTS_MGT}search/`;
  public static readonly TENANTS_SETTINGS = `tenants/settings/`;
  public static readonly TENANTS_DEFAULT_SETTINGS = `${J4TenantsEndpoints.TENANTS_SETTINGS}defaultSettings/`;
  public static readonly TENANTS_BY_ID = (id: string): string => `${J4TenantsEndpoints.TENANTS}${id}/`;
  public static readonly GROUPS = (id: string): string => `${J4TenantsEndpoints.TENANTS}groups/${id}/`;
  public static readonly USERS = (id: string): string => `${J4TenantsEndpoints.TENANTS}users/${id}/`;
}

export class TenantGenericKeysEndpoints {
  public static readonly BASE = BaseEndpoints.V1;
  public static readonly GENERIC_KEYS = BaseEndpoints.V1 + 'generic-key-package/';
  public static readonly MULTIPLE_SETTINGS = BaseEndpoints.V1 + 'settings/generic/multipleSettings/';
}

export class J4UserEndpoints {
  public static readonly BASE = BaseEndpoints.V1 + 'j4/user-management/';
  public static readonly USER = 'user/';
  public static readonly GROUPS = 'groups';
  public static readonly SEARCH = J4UserEndpoints.USER + 'search';
  public static readonly INIT = 'init';
  public static readonly DOWNLOAD = 'csv';
  public static readonly STATE = 'state/';

  public static readonly TOGGLE_STATE = (state: string, userId: string): string =>
    `${J4UserEndpoints.USER}${userId}/${J4UserEndpoints.STATE}${state}`;
}

export class J4UsersAdminEndpoints {
  public static readonly BASE = BaseEndpoints.V1 + 'j4/user-management/';
  public static readonly USERS = 'users/';
  public static readonly FILTER = 'filter/';
  public static readonly MFA = 'mfa/';
}

export class J4GroupEndpoints {
  public static readonly BASE = BaseEndpoints.V1 + 'j4/group-management/';
  public static readonly GROUPS = 'groups/';
  public static readonly SEARCH = J4UserEndpoints.GROUPS + 'search$';
  public static readonly INIT = 'init';
  public static readonly DOWNLOAD = 'csv';
}

export class BannerEndpoints {
  public static readonly BASE = BaseEndpoints.V1 + 'banner';
  public static readonly BANNER = 'banner';
}

export class WorkflowUrls {
  public static readonly BASE = '/workflows/';
  public static readonly DETAILS = (id: string): string => `${WorkflowUrls.BASE}${id}/details`;
}

export const DSRRequestsAdminEndpoints = {
  BASE: `${BaseEndpoints.V1}data-report/`,
  DATA_REPORT: '',
  DATA_REPORT_RESOURCE_RESUlT: (id: string): string => `resource/${id}`,
  DATA_REPORT_RESOURCE_CRITERIA: (reportJobId: string): string => `data-report-resource/${reportJobId}_criteria.csv/`
} as const;

export const AuditLogAdminEndpointsBASE = 'v1/pci-log-query/audit-trail/';
export const AuditLogAdminEndpoints = {
  QUERY: 'query/',
  QUERY_BY_ID: (merchantId: string, id: string): string => `${AuditLogAdminEndpoints.QUERY}${merchantId}/${id}`
} as const;
