import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseEndpoints} from 'public-shared/models/endpoints/base-endpoint.constants';
import {BaseService} from 'public-shared/services/base-http/base.service';
import {Observable} from 'rxjs';
import {CountryEndpoints} from 'shared/services/endpoint-constants';
import {SearchService} from 'shared/services/search.service';
import {Country, CountrySettingsDetails, CountrySettingsInit} from './country.dto';

@Injectable()
export class CountryService extends BaseService implements SearchService {
  constructor(protected override http: HttpClient) {
    super(http);
    this.baseUrl = BaseEndpoints.V1;
  }

  public search$(query: string): Observable<Array<Country>> {
    return this.post$<Array<Country>>(CountryEndpoints.SEARCH, {query});
  }

  public initCountrySettings$(): Observable<CountrySettingsInit> {
    return this.get$<CountrySettingsInit>(CountryEndpoints.INIT);
  }

  public addCountry$(country: CountrySettingsDetails): Observable<void> {
    return this.post$<void>(CountryEndpoints.DETAILS, country);
  }

  public getCountrySettings$(countryCode: string): Observable<CountrySettingsDetails> {
    return this.get$<CountrySettingsDetails>(CountryEndpoints.COUNTRY_DETAILS(countryCode));
  }

  public modifyCountrySettings$(countryCode: string, country: CountrySettingsDetails): Observable<void> {
    return this.put$<void>(CountryEndpoints.COUNTRY_DETAILS(countryCode), country);
  }

  public getCountries$(): Observable<Array<Country>> {
    return this.get$<Array<Country>>(CountryEndpoints.COUNTRY_LIST);
  }
}
