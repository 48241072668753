import {v4 as UUID} from 'uuid';
import moment from 'moment';
import {ErrorDescriptor} from 'public-shared/models/error/error-descriptor';

export class LogRequest {
  public id: string | undefined;
  public timestamp: number | undefined;
  public message: string | undefined;

  public static error(error: ErrorDescriptor): LogRequest {
    const req = new LogRequest();
    req.id = error.id;
    req.timestamp = error.timestamp;
    req.message = error.toString();
    return req;
  }

  public static simple(message: string): LogRequest {
    const req = new LogRequest();
    req.id = UUID();
    req.timestamp = +moment().format('x');
    req.message = message;
    return req;
  }
}
