import {InjectionToken} from '@angular/core';

export interface IPortalConfig {
  apiType: PortalApiType;
  basePath: string;
}

export enum PortalApiType {
  AP = 'ap',
  CP = 'cp'
}

export const PORTAL_DATA = new InjectionToken<IPortalConfig>('Portal config data');
