import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseAuthEndpoints} from 'public-shared/models/endpoints/base-auth-endpoint.constants';
import {EmailVerification} from 'public-shared/services/auth/email-verification.dto';
import {BaseService} from 'public-shared/services/base-http/base.service';
import {PublicSecurityContextHolder} from 'public-shared/services/security/public-security-context-holder';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {LoginRequest} from './login-request.dto';
import {LoginResult, UserContext} from './login-result.dto';

@Injectable()
export class PublicAuthService extends BaseService {
  protected logoutCalled = false;
  public holdPassword: string | undefined;
  public holdEmail: string | undefined;

  constructor(protected securityContextHolder: PublicSecurityContextHolder, protected override http: HttpClient) {
    super(http);
    this.baseUrl = BaseAuthEndpoints.BASE;
  }

  public login$(loginRequest: LoginRequest): Observable<LoginResult> {
    this.logoutCalled = false;
    return this.post$<LoginResult>(BaseAuthEndpoints.ACCESS_TOKEN, loginRequest).pipe(
      tap((loginResult: LoginResult) => {
        this.validateContext(loginResult);
      })
    );
  }

  public checkEmailVerificationKey$(key: string): Observable<void> {
    return this.http.get<void>(BaseAuthEndpoints.EMAIL_VERIFICATION_KEY(key));
  }

  public sendEmailVerification$(emailVerification: EmailVerification): Observable<LoginResult> {
    return this.http.post<LoginResult>(BaseAuthEndpoints.EMAIL_VERIFICATION, emailVerification).pipe(
      tap((loginResult: LoginResult) => {
        this.validateContext(loginResult);
      })
    );
  }

  public checkEmailChangeVerificationKey$(key: string): Observable<void> {
    return this.http.post<void>(BaseAuthEndpoints.EMAIL_CHANGE_VERIFICATION, {key});
  }

  public validateContext(loginResult: LoginResult): void {
    const userContext = loginResult as UserContext;
    this.securityContextHolder.validate(userContext);
  }
}
