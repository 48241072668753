import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'toDashesIfEmpty',
  standalone: true
})
/**
 * A simple pipe that transforms an empty value into three dashes character.
 */
export class ToDashesIfEmptyPipe implements PipeTransform {
  public static readonly REPLACEMENT_STRING = '---';

  /**
   * Transforms the input value into the replacement string, if it's not given.
   * @param value
   * @returns {any}
   */
  public transform(value: any): any {
    return value && value !== '' ? value : ToDashesIfEmptyPipe.REPLACEMENT_STRING;
  }
}

@Pipe({
  name: 'toDashesIfNull',
  standalone: true
})
/**
 * A simple pipe that transforms an null value into three dashes character and stringify any other character.
 */
export class ToDashesIfNullPipe implements PipeTransform {
  private readonly REPLACEMENT_STRING = '---';

  public transform(value: any): string {
    if (value === null) {
      return this.REPLACEMENT_STRING;
    } else {
      return typeof value === 'object' ? JSON.stringify(value) : `${value}`;
    }
  }
}
