import {ChangeDetectionStrategy, Component} from '@angular/core';

@Component({
  selector: 'j4-service-unavailable',
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './service-unavailable.component.html',
  styleUrl: './service-unavailable.component.less'
})
export class ServiceUnavailableComponent {}
