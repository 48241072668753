import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EntityWithPassword} from 'public-shared/services/base-http/base.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {BaseCredentialsService} from 'shared/services/credentials/base-credentials.service';
import {QrCodeContainer} from 'shared/services/credentials/demo-app-url.dto';
import {OfflineToken} from 'shared/services/credentials/offline-token.dto';
import {TransactionAdministration} from 'shared/services/credentials/transaction-administration.dto';
import {TransactionCreation} from 'shared/services/credentials/transaction-creation.dto';
import {CredentialsEndpoints} from 'shared/services/endpoint-constants';
import {ApiTokenActivation, ApiTokenCreation, ApiTokenDeactivation, IApiTokens} from './api-tokens.dto';

@Injectable()
export class CredentialsService extends BaseCredentialsService {
  constructor(protected override http: HttpClient) {
    super(http);
    this.baseUrl = CredentialsEndpoints.BASE;
  }

  public getApiTokens$(): Observable<IApiTokens> {
    return this.get$(CredentialsEndpoints.GET_API_TOKENS);
  }

  public getSecret$(): Observable<TransactionCreation> {
    return this.get$(CredentialsEndpoints.GET_SECRET);
  }

  public generateSecret$(): Observable<TransactionCreation> {
    return this.get$(CredentialsEndpoints.GENERATE_SECRET);
  }

  public activateSecret$(secret: TransactionCreation): Observable<TransactionCreation> {
    return this.post$(CredentialsEndpoints.ACTIVATE_SECRET, secret);
  }

  public saveSecret$(secret: TransactionCreation, password: string, passcode?: string): Observable<TransactionCreation> {
    return this.post$(CredentialsEndpoints.SAVE_SECRET, new EntityWithPassword(secret, password, passcode));
  }

  public getCredentials$(): Observable<TransactionAdministration> {
    return this.get$(CredentialsEndpoints.GET_CREDENTIALS);
  }

  public generateCredentials$(): Observable<TransactionAdministration> {
    return this.get$<TransactionAdministration>(CredentialsEndpoints.GENERATE_CREDENTIALS);
  }

  public activateCredentials$(credentials: TransactionAdministration): Observable<TransactionAdministration> {
    return this.post$(CredentialsEndpoints.ACTIVATE_CREDENTIALS, credentials);
  }

  public saveCredentials$(
    credentials: TransactionAdministration,
    password: string,
    passcode?: string
  ): Observable<TransactionAdministration> {
    return this.post$<TransactionAdministration>(
      CredentialsEndpoints.SAVE_CREDENTIALS,
      new EntityWithPassword(credentials, password, passcode)
    );
  }

  public getDemoAppQrCode$(dataCenter: string): Observable<string> {
    //@ts-ignore
    return this.post$<QrCodeContainer>(CredentialsEndpoints.DEMO_APP_URL, {dataCenter}).pipe(map(res => res.qrCode));
  }

  public getOfflineToken$(): Observable<OfflineToken> {
    return this.get$(CredentialsEndpoints.OFFLINE_TOKEN);
  }

  public activateApiToken$(request: ApiTokenActivation, password: string, passcode?: string): Observable<any> {
    return this.post$<any>(CredentialsEndpoints.ACTIVATE_API_TOKEN, new EntityWithPassword(request, password, passcode));
  }

  public deactivateApiToken$(request: ApiTokenDeactivation, password: string, passcode?: string): Observable<any> {
    return this.post$<any>(CredentialsEndpoints.DEACTIVATE_API_TOKEN, new EntityWithPassword(request, password, passcode));
  }

  public createApiToken$(request: ApiTokenCreation, password: string, passcode?: string): Observable<any> {
    return this.post$<any>(CredentialsEndpoints.CREATE_API_TOKEN, new EntityWithPassword(request, password, passcode));
  }
}
