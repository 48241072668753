import {Injectable} from '@angular/core';
import {IVersion} from '@jumio/portals.force-reload';

/**
 * TODO: Separate ticket for whole refactor: W2A-2062
 *       Currently only containing logic for: W2A-2047
 *
 * Service to handle all the local storage events in one place
 */
@Injectable()
export class LocalStorageService {
  public setCookiePreference(cookiesAreAllowed: string): void {
    this.setItem(LocalStorageKeys.COOKIE_PREFERENCE_SET, 'true');
    this.setItem(LocalStorageKeys.COOKIES_ALLOWED, cookiesAreAllowed);
  }
  public areCookiesAllowed(): boolean {
    return !!this.getItem(LocalStorageKeys.COOKIE_PREFERENCE_SET) && this.getItem(LocalStorageKeys.COOKIES_ALLOWED) === 'true';
  }
  public isCookiePreferenceSet(): boolean {
    return !!this.getItem(LocalStorageKeys.COOKIE_PREFERENCE_SET);
  }
  public clearCookiePreferences(): void {
    this.removeItem(LocalStorageKeys.COOKIES_ALLOWED);
    this.removeItem(LocalStorageKeys.COOKIE_PREFERENCE_SET);
  }
  public setBannerHidden(): void {
    this.setItem(LocalStorageKeys.CP_BANNER_HIDDEN, BannerStorageValues.YES);
  }
  public clearBannerHidden(): void {
    this.removeItem(LocalStorageKeys.CP_BANNER_HIDDEN);
  }
  public isBannerHidden(): boolean {
    const hiddenValue = this.getItem(LocalStorageKeys.CP_BANNER_HIDDEN);
    return !!(hiddenValue && hiddenValue === BannerStorageValues.YES);
  }
  public setAppVersionList(appVersionList: IVersion[]): void {
    this.setItem(LocalStorageKeys.APP_VERSIONS, JSON.stringify(appVersionList));
  }
  public getAppVersionList(): IVersion[] {
    //@ts-ignore
    return JSON.parse(this.getItem(LocalStorageKeys.APP_VERSIONS));
  }

  /**
   * Custom getItem for error handling
   */
  private getItem(key: LocalStorageKeys): string | null | undefined {
    try {
      if (localStorage) {
        return localStorage.getItem(key);
      }
    } catch (e) {
      return null;
    }
    return;
  }

  /**
   * Custom setItem for error handling
   */
  private setItem(key: LocalStorageKeys, value: string): void {
    try {
      if (localStorage) {
        localStorage.setItem(key, value);
      }
    } catch (e) {
      // DO NOTHING
    }
  }

  /**
   * Custom removeItem for error handling
   */
  private removeItem(key: LocalStorageKeys): void {
    try {
      if (localStorage) {
        localStorage.removeItem(key);
      }
    } catch (e) {
      // DO NOTHING
    }
  }
}

export enum LocalStorageKeys {
  COOKIE_PREFERENCE_SET = 'CP_COOKIE_PREFERENCE_SET',
  COOKIES_ALLOWED = 'CP_COOKIES_ALLOWED',
  CP_BANNER_HIDDEN = 'CP_BANNER_HIDDEN',
  APP_VERSIONS = 'APP_VERSIONS'
}

export enum BannerStorageValues {
  NO = 'NO',
  YES = 'YES'
}
