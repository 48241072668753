import {NgModule} from '@angular/core';
import {J4AuthContainerComponent} from './components/auth-container/auth-container.component';
import {LoginLayoutComponent} from './components/layout/login-layout.component';
import {ErrorComponent} from './containers/error/error.component';
import {J4LoginComponent} from './containers/login/auth0/login.component';
import {NotFoundComponent} from './containers/not-found/not-found.component';
import {CustomerAppRootComponent} from './customer-app-root.component';

import {APP_BASE_HREF} from '@angular/common';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {JtmComponentsModule, JtmPipesModule} from '@jtm/jtm-components';
import {JtmAuthService, JtmSecurityContextHolder, JtmServicesModule, JtmServicesPipesModule} from '@jtm/jtm-services';
import {JumioDatadogRumModule, PortalDatadogIdentifier} from '@jumio/datadog-rum';
import {appVersion} from '@jumio/portals.app-version';
import {DatadogAppNames, IPortalConfig, PORTAL_DATA, PortalApiType} from '@jumio/portals.core';
import {J4LoaderComponent} from '@jumio/portals.core-ui';
import {ForceReloadModalComponent, ForceReloadModule, ProvideApiVersionModule} from '@jumio/portals.force-reload';
import {StoreModule} from '@ngrx/store';
import {AlertModule} from 'ngx-bootstrap/alert';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {SpinnerComponent} from 'public-shared/components/spinner/spinner.component';
import {PipesModule} from 'public-shared/pipes/pipes.module';
import {EnvironmentProvider} from 'public-shared/services/environment-provider';
import {LoggerService} from 'public-shared/services/logging/logger.service';
import {NotificationService} from 'public-shared/services/notification/notification.service';
import {SharedModule} from 'shared/shared.module';
import {AuthEnvironmentProvider} from '../environments/auth-environment-provider';
import {environment} from '../environments/environment.prod';
import {customerAuthRouting} from './customer-auth.routing';
import {J4AuthCustomHttpInterceptor} from './interceptors/http-interceptor';
import {AuthGlobalErrorHandler} from './services/auth-global-error-handler';
import {PublicAuthService} from './services/auth/public-auth.service';

const CONFIG: IPortalConfig = {
  apiType: PortalApiType.CP,
  basePath: '/login/'
};

@NgModule({
  declarations: [
    CustomerAppRootComponent,
    LoginLayoutComponent,
    J4AuthContainerComponent,
    J4LoginComponent,
    NotFoundComponent,
    ErrorComponent
  ],
  imports: [
    J4LoaderComponent,
    SpinnerComponent,
    SharedModule,
    ForceReloadModalComponent,
    ForceReloadModule,
    customerAuthRouting,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    BsDropdownModule.forRoot(),
    JtmComponentsModule,
    JtmPipesModule,
    PipesModule,
    AlertModule,
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
          strictStateSerializability: true,
          strictActionSerializability: true,
          strictActionWithinNgZone: true,
          strictActionTypeUniqueness: true
        }
      }
    ),
    JtmServicesModule.forRoot({environment, tenantName: '', skipPortalTokenCheck: true}),
    JtmServicesPipesModule,
    JtmServicesModule,
    ProvideApiVersionModule.forRoot(CONFIG.basePath),
    JumioDatadogRumModule.forRoot({
      portalIdentifier: PortalDatadogIdentifier.UNIFIED_PORTAL,
      service: DatadogAppNames.UP_AUTH,
      appVersion: appVersion
    })
  ],
  providers: [
    {
      provide: APP_BASE_HREF,
      useValue: CONFIG.basePath
    },
    JtmSecurityContextHolder,
    JtmAuthService,
    LoggerService,
    PublicAuthService,
    NotificationService,
    AuthGlobalErrorHandler,
    {
      provide: EnvironmentProvider,
      useClass: AuthEnvironmentProvider
    },
    {
      provide: PORTAL_DATA,
      useValue: CONFIG
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: J4AuthCustomHttpInterceptor,
      multi: true
    }
  ],
  exports: [],
  bootstrap: [CustomerAppRootComponent]
})
export class CustomerAuthModule {}
