import {Directive, ElementRef, EventEmitter, forwardRef, Input, Output} from '@angular/core';
import {AbstractControl, NG_VALIDATORS, ValidationErrors, Validator} from '@angular/forms';

@Directive({
  selector: '[appNaturalNumberValidator]',
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => NaturalNumberValidatorDirective),
      multi: true
    }
  ],
  standalone: true
})
/**
 * Validator class for checking if the input is a natural number or not.
 */
export class NaturalNumberValidatorDirective implements Validator {
  /**
   * Determines whether the current input is required or not.
   */
  @Input() public required: boolean | undefined;

  @Input() public min: number | undefined;
  @Input() public max: number | undefined;

  /**
   * Emits a new event when the validation has finished.
   * @type {EventEmitter<ValidationErrors | null>}
   */
  @Output() public onValidated = new EventEmitter<ValidationErrors | null>();

  private readonly NUMBER_REGEX = new RegExp('^[0-9]*$');

  constructor(private elementRef: ElementRef) {}

  /**
   * The method implementing the {@link Validator} interface's require method.
   * Checks the entered value and emits and returns the errors if there are any.
   * @param {AbstractControl} control
   * @returns {ValidationErrors | null}
   */
  public validate(control: AbstractControl): ValidationErrors | null {
    const errors = {};
    let hasError = false;

    const numberVal = parseInt(control.value, 10);

    if (this.required && !numberVal && numberVal !== 0) {
      //@ts-ignore
      errors['required'] = true;
      hasError = true;
    }
    if (this.max && numberVal > this.max) {
      //@ts-ignore
      errors['max'] = true;
      hasError = true;
    }
    if (this.min && numberVal < this.min) {
      //@ts-ignore
      errors['min'] = true;
      hasError = true;
    }
    if (numberVal < 0) {
      //@ts-ignore
      errors['negative'] = true;
      hasError = true;
    }
    if (
      (numberVal && !Number.isInteger(numberVal)) ||
      (control.value && Number.isNaN(numberVal)) ||
      (control.value && !this.NUMBER_REGEX.test(control.value)) ||
      (this.elementRef.nativeElement.validity && this.elementRef.nativeElement.validity.badInput)
    ) {
      //@ts-ignore
      errors['notInteger'] = true;
      hasError = true;
    }
    this.onValidated.emit(hasError ? errors : null);
    return hasError ? errors : null;
  }
}
