import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseService, EntityWithPassword} from 'public-shared/services/base-http/base.service';
import {Observable} from 'rxjs';
import {JumioPage} from 'shared/components/table/dto/jumio-page';
import {J4TenantsEndpoints, J4UsersAdminEndpoints} from 'shared/services/endpoint-constants';
import {FilterService} from 'shared/services/filter.service';
import {J4TenantsFilterInit} from 'shared/services/j4-tenants/j4-tenants-filter.dto';
import {J4UserFilter} from 'shared/services/j4-users/j4-users-filter.dto';
import {J4User} from 'shared/services/j4-users/j4-users.dto';
import {J4UserAddRequest} from 'shared/services/user/user.dto';

@Injectable()
export class J4UsersService extends BaseService implements FilterService {
  public selectedUser: J4User | undefined;

  /**
   * Set base url
   */
  constructor(protected override http: HttpClient) {
    super(http);
    this.baseUrl = J4UsersAdminEndpoints.BASE;
  }

  /**
   * Get filters
   */
  public initFilter$(): Observable<J4TenantsFilterInit> {
    return this.get$<J4TenantsFilterInit>(J4TenantsEndpoints.TENANTS);
  }

  public addUser$(user: J4UserAddRequest, password: string): Observable<void> {
    return this.post$<void>(J4UsersAdminEndpoints.USERS, new EntityWithPassword(user, password));
  }

  public filter$(filter: J4UserFilter): Observable<JumioPage<J4User>> {
    return this.post$<JumioPage<J4User>>(J4UsersAdminEndpoints.FILTER, filter);
  }

  public resetMFA$(userId: string, password: string): Observable<void> {
    return this.deleteWithBody$(J4UsersAdminEndpoints.MFA, new EntityWithPassword(userId, password));
  }

  public removeUser$(userId: string, password: string): Observable<void> {
    return this.deleteWithBody$(J4UsersAdminEndpoints.USERS, new EntityWithPassword(userId, password));
  }
}
