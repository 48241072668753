import {BaseSecurityContextHolder} from 'public-shared/services/security/base-security-context-holder';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';

export abstract class BaseCacheService {
  public cache: {[key: string]: any} = {};

  constructor(protected securityContextHolder: BaseSecurityContextHolder) {}

  public setItem(key: string, item: any): void {
    if (this.securityContextHolder.isLoggedIn) {
      this.cache[key] = item;
    }
  }

  public getItem(key: string): any {
    return this.cache[key];
  }

  public hasKey(key: string): boolean {
    return !!this.cache[key];
  }

  public removeItem(key: string): void {
    this.cache[key] = null;
  }

  public cacheFunction$<T>(key: string, fn$: Observable<T>): Observable<T> {
    if (this.hasKey(key)) {
      return of(this.getItem(key));
    } else {
      return fn$.pipe(
        tap((result: T) => {
          this.setItem(key, result);
        })
      );
    }
  }

  public purge(): void {
    this.cache = {};
  }
}
