import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseService} from 'public-shared/services/base-http/base.service';
import {EnvironmentProvider} from 'public-shared/services/environment-provider';
import {Observable} from 'rxjs/internal/Observable';
import {map} from 'rxjs/operators';
import {CustomerEnvironmentData} from '@jumio/portals.core';
import {CacheService} from 'shared/services/common/cache.service';
import {OrchestrationEndpoints} from 'shared/services/endpoint-constants';
import {OrchestrationWorkflowResponse} from 'shared/services/merchant/orchestration.dto';
import {SecurityContextHolder} from 'shared/services/security/security-context-holder';
import {BaseOrchestrationService} from './base-orchestration.service';

@Injectable()
export class OrchestrationCustomerSpecificService extends BaseOrchestrationService {
  constructor(
    protected override contextHolder: SecurityContextHolder,
    protected override http: HttpClient,
    protected override environmentProvider: EnvironmentProvider<CustomerEnvironmentData>,
    protected override cache: CacheService
  ) {
    super(contextHolder, http, environmentProvider, cache);
  }

  public getWorkflows$(merchantId: string): Observable<OrchestrationWorkflowResponse> {
    return this.get$<OrchestrationWorkflowResponse>(
      `${OrchestrationEndpoints.MANAGER}${OrchestrationEndpoints.MERCHANT}/${merchantId}${OrchestrationEndpoints.WORKFLOW}`
    );
  }

  public downloadWorkflow$(workflowId: string, merchantId: string): Observable<Blob> {
    return this.http
      .get(
        `${this.baseUrl}${OrchestrationEndpoints.MANAGER}${OrchestrationEndpoints.MERCHANT}/${merchantId}${OrchestrationEndpoints.WORKFLOW}/${workflowId}`,
        {
          responseType: 'text'
        }
      )
      .pipe(map(BaseService.mapToXML));
  }

  public uploadNewWorkflow$(formData: FormData, merchantId: string): Observable<void> {
    return this.post$<void>(
      `${OrchestrationEndpoints.MANAGER}${OrchestrationEndpoints.MERCHANT}/${merchantId}${OrchestrationEndpoints.WORKFLOW}`,
      formData
    );
  }

  public uploadUpdatedWorkflow$(workflowId: string, formData: FormData, merchantId: string): Observable<void> {
    return this.put$<void>(
      `${OrchestrationEndpoints.MANAGER}${OrchestrationEndpoints.MERCHANT}/${merchantId}${OrchestrationEndpoints.WORKFLOW}/${workflowId}`,
      formData
    );
  }
}
