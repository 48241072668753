import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseService, EntityWithPassword} from 'public-shared/services/base-http/base.service';
import {Observable} from 'rxjs';
import {TenantGenericKeysEndpoints} from 'shared/services/endpoint-constants';
import {IGenericKeyEntity, ITenantGenericKeys} from './j4-tenants-generic-keys.dto';

@Injectable()
export class J4TenantsGenericKeysService extends BaseService {
  /**
   * Set base url
   */
  constructor(protected override http: HttpClient) {
    super(http);
  }

  public addGenericKeys$(keys: IGenericKeyEntity, password: string): Observable<void> {
    return this.post$<void>(TenantGenericKeysEndpoints.MULTIPLE_SETTINGS, new EntityWithPassword(keys, password));
  }

  public getGenericKeys$(): Observable<Array<ITenantGenericKeys>> {
    return this.get$<Array<ITenantGenericKeys>>(TenantGenericKeysEndpoints.GENERIC_KEYS);
  }
}
