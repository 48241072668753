import {Injectable} from '@angular/core';
import {EnvironmentProvider} from 'public-shared/services/environment-provider';
import {CustomerEnvironmentData} from '@jumio/portals.core';
import {AuthService} from 'shared/services/auth/auth.service';

@Injectable()
export class IdleCheckService {
  public static readonly LOCAL_LAST_USED = 'jumioIdleTime';

  private timer: string | number | NodeJS.Timeout | undefined;
  private lastTimer: number | undefined;
  private readonly idleInterval: number;
  private readonly idleIntervalEnabled: boolean;

  constructor(private environmentProvider: EnvironmentProvider<CustomerEnvironmentData>, private authService: AuthService) {
    this.idleInterval = this.environmentProvider.environment.idleInterval;
    this.idleIntervalEnabled = this.environmentProvider.environment.idleIntervalEnabled;
  }

  /**
   * After the service is notified, the timer will be restarted if the service is enabled.
   */
  public notifyService(): void {
    if (this.idleIntervalEnabled) {
      this.restartTimer();
    }
  }

  private restartTimer(idleInterval?: number): void {
    this.clearTimer();
    if (this.authService.isLogoutCalled) {
      // on logout call, not need timer
      return;
    }
    if (!idleInterval) {
      // if idleInterval defined, than it is a restart, not need save time
      this.lastTimer = new Date().getTime();
      localStorage.setItem(IdleCheckService.LOCAL_LAST_USED, this.lastTimer.toString());
    }
    this.timer = setTimeout(() => {
      const lastUsed = +(localStorage.getItem(IdleCheckService.LOCAL_LAST_USED) ?? 0);
      if (lastUsed > (this.lastTimer ?? 0)) {
        // process found on other tab
        //@ts-ignore
        const diff = lastUsed - this.lastTimer + (this.idleInterval - (idleInterval || this.idleInterval));
        this.lastTimer = lastUsed;
        this.restartTimer(diff);
      } else {
        // idle interval ended, logout
        this.clearTimer();
        this.authService.logout();
      }
    }, idleInterval || this.idleInterval);
  }

  private clearTimer(): void {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }
}
