import {NgModule} from '@angular/core';
import {NgClass, NgForOf, NgIf, NgTemplateOutlet} from '@angular/common';
import {AppDropdownModule} from '../dropdown/dropdown.module';
import {DataTableColumnDirective} from './data-table-column.directive';
import {DataTableComponent} from './data-table.component';
import {HeaderComponent} from './header/header.component';
import {PaginatorComponent} from './paginator/paginator.component';
import {CardViewComponent} from './view/card-view/card-view.component';
import {ListViewComponent} from './view/list-view/list-view.component';
import {RepeatPipe} from 'public-shared/pipes/repeat.pipe';

const DATA_TABLE_MODULE_COMPONENTS = [
  DataTableComponent,
  ListViewComponent,
  CardViewComponent,
  PaginatorComponent,
  HeaderComponent,
  DataTableColumnDirective
] as const;

@NgModule({
  imports: [AppDropdownModule, RepeatPipe, NgIf, NgForOf, NgTemplateOutlet, NgClass],
  declarations: [...DATA_TABLE_MODULE_COMPONENTS],
  exports: [...DATA_TABLE_MODULE_COMPONENTS]
})
export class DataTableModule {}
