import {ModuleWithProviders} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ErrorUrls} from '@jumio/portals.core';
import {LoginLayoutComponent} from './components/layout/login-layout.component';
import {ErrorComponent} from './containers/error/error.component';
import {J4LoginComponent} from './containers/login/auth0/login.component';
import {NotFoundComponent} from './containers/not-found/not-found.component';
import {LoginUrls} from './services/route-url-constants';

export const CUSTOMER_AUTH_ROUTES: Routes = [
  {
    path: LoginUrls.BASE,
    component: LoginLayoutComponent,
    data: {title: 'Login'},
    children: [{path: LoginUrls.BASE, component: J4LoginComponent}]
  },
  {
    path: LoginUrls.BASE,
    component: LoginLayoutComponent,
    data: {title: 'Login'},
    children: [{path: LoginUrls.LOGIN, component: J4LoginComponent}]
  },
  {
    path: ErrorUrls.BASE,
    component: ErrorComponent,
    data: {title: 'Error'}
  },
  {
    path: ErrorUrls.ERROR_404,
    data: {title: 'Not found'},
    component: NotFoundComponent
  },
  {
    path: '**',
    data: {title: 'Not found'},
    component: NotFoundComponent
  }
];

export const customerAuthRouting: ModuleWithProviders<RouterModule> = RouterModule.forRoot(CUSTOMER_AUTH_ROUTES, {
  useHash: false,
  paramsInheritanceStrategy: 'always'
});
