import {Injectable} from '@angular/core';
import {BaseCacheService} from 'public-shared/services/cache/base-cache.service';
import {SecurityContextHolder} from 'shared/services/security/security-context-holder';

@Injectable()
export class CacheService extends BaseCacheService {
  constructor(protected override securityContextHolder: SecurityContextHolder) {
    super(securityContextHolder);
  }
}
