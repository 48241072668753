export class CodeLabel {
  public code: string | null | undefined;
  public label: string | undefined;
  public permission?: string | string[];

  constructor(code?: string, label?: string) {
    this.code = code;
    this.label = label;
  }

  private static compareByProperty(c1: CodeLabel, c2: CodeLabel, prop: string): number {
    //@ts-ignore
    if (!c1 || !c1[prop] || !c2 || !c2[prop]) {
      return 0;
    }

    //@ts-ignore
    if (c1[prop].toLowerCase() < c2[prop].toLowerCase()) {
      return -1;
      //@ts-ignore
    } else if (c1[prop].toLowerCase() > c2[prop].toLowerCase()) {
      return 1;
    } else {
      return 0;
    }
  }

  public static compare(c1: CodeLabel, c2: CodeLabel): boolean {
    return c1 && c2 ? c1.code === c2.code : c1 === c2;
  }

  public static compareByLabel(c1: CodeLabel, c2: CodeLabel): number {
    return CodeLabel.compareByProperty(c1, c2, 'label');
  }

  public static compareByCode(c1: CodeLabel, c2: CodeLabel): number {
    return CodeLabel.compareByProperty(c1, c2, 'code');
  }

  public static create(code: string, label: string): CodeLabel {
    const val = new CodeLabel();
    val.code = code;
    val.label = label;
    return val;
  }

  /**
   * Creates an object which holds the 'code': 'label' pairs of a given property of CodeLabel array.
   * @param labels The CodeLabel array values.
   * @returns {{}} The object containing the code-label pairs.
   */
  public static getCodeLabelMap(labels: Array<CodeLabel>): Record<string, string> {
    const result = {};
    if (labels && labels.length) {
      for (const cv of labels) {
        //@ts-ignore
        result[cv.code] = cv.label;
      }
    }
    return result;
  }
}

export class CodeLabelIcon extends CodeLabel {
  public icon: string;

  constructor(code: string, label: string, icon: string) {
    super();
    this.code = code;
    this.label = label;
    this.icon = icon;
  }
}

export class CodeLabelSelected extends CodeLabel {
  public selected: boolean;

  constructor(code: string, label: string, selected: boolean) {
    super();
    this.code = code;
    this.label = label;
    this.selected = selected;
  }
}

export class CodeLabelChecked extends CodeLabel {
  public checked: boolean;

  constructor(code: string, label: string, checked: boolean) {
    super();
    this.code = code;
    this.label = label;
    this.checked = checked;
  }
}
