import {HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpParams, HttpRequest} from '@angular/common/http';
import {Injectable, inject} from '@angular/core';
import {JtmSecurityContextHolder} from '@jtm/jtm-services';
import {Observable, throwError as _throw} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {BaseErrorCodes} from 'public-shared/models/error/base-error-codes';
import {AuthErrorDescriptor, ErrorItem} from '../models/auth-error-descriptor';

@Injectable()
/**
 * Intercepts all HTTP requests and adds base URL, Authorization header and handles error responses.
 */
export class J4AuthCustomHttpInterceptor implements HttpInterceptor {
  public static readonly AUTHORIZATION_TOKEN_TYPE = 'Bearer';
  public static readonly HEADER_AUTHORIZATION_KEY = 'Authorization';

  protected securityContextHolder = inject(JtmSecurityContextHolder);

  protected static toErrorDescriptor(errorResponse: JumioErrorResponse): AuthErrorDescriptor {
    let errorDescriptor: AuthErrorDescriptor;

    if (errorResponse.status === 0) {
      errorDescriptor = new AuthErrorDescriptor('Server is down', BaseErrorCodes.SERVER_IS_DOWN.toString(), 0);
    } else if (errorResponse.status === 404) {
      errorDescriptor = new AuthErrorDescriptor('Not found', errorResponse?.error?.errorCode, 404);
    } else {
      errorDescriptor = new AuthErrorDescriptor(
        errorResponse.error?.error ?? errorResponse.message,
        errorResponse.error?.errorCode || errorResponse.name,
        errorResponse.error?.status || errorResponse.status,
        errorResponse.error?.errors,
        errorResponse.error?.timestamp,
        errorResponse.url || undefined // url is string or null -  make sure undefined is passed istead null
      );
    }

    return errorDescriptor;
  }

  /**
   * The main method implementing the Interceptor interface.
   * Receives a HTTP request, and depending on the current environment, forwards it to either the mocked backend or the real backend.
   * After sending the events, catches error responses and converts them to AuthErrorDescriptor objects.
   * @param {HttpRequest<any>} req The current HTTP request that has been sent out from the client.
   * @param {HttpHandler} next The forwarding handler.
   * @returns {Observable<HttpEvent<any>>} An Observable which contains the response as an event stream.
   */
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      url: this.prepareUrl(req.url),
      headers: this.addAuthHeader(req.headers)
    });

    const event$: Observable<HttpEvent<any>> = next.handle(req);

    return event$.pipe(
      catchError((errorResponse: HttpErrorResponse) =>
        _throw(J4AuthCustomHttpInterceptor.toErrorDescriptor(errorResponse as JumioErrorResponse))
      )
    );
  }

  /**
   * Depending on the parameters of the request, prepares the request's new URL.
   * @param {string} url The current URL contained by the request.
   * @returns {string} The new URL where the request will be sent.
   */
  protected prepareUrl(url: string): string {
    return url;
  }

  /**
   * If available and not set yet, sets the Authorization header and returns a new headers object.
   * @param {HttpHeaders} headers The current headers of the request.
   * @returns {HttpHeaders} The new headers object that will be sent.
   */
  protected addAuthHeader(headers: HttpHeaders): HttpHeaders {
    if (this.getAuthToken()) {
      headers = headers.set(J4AuthCustomHttpInterceptor.HEADER_AUTHORIZATION_KEY, this.getAuthToken());
    }
    return headers;
  }

  /**
   * Creates the authorization token (formatted 'Bearer <authToken>').
   * @returns {string} The authorization token as a string.
   */
  protected getAuthToken(): string {
    let authToken = '';
    if (this.securityContextHolder.accessToken) {
      authToken = J4AuthCustomHttpInterceptor.AUTHORIZATION_TOKEN_TYPE + ' ' + this.securityContextHolder.accessToken;
    }
    return authToken;
  }
}

/**
 * A helper class for extending the original HttpParams class,
 * to allow setting some extra options within the request.
 */
export class InterceptorHttpParams extends HttpParams {
  public skipApiUrl?: boolean;
}

/**
 * A helper class for extending the original HttpErrorResponse class,
 * which contains the errorCode, timestamp and status properties sent by the backend.
 */
export class JumioErrorResponse extends HttpErrorResponse {
  public error:
    | {
        [key: string]: any;
        error: string;
        errorCode: string;
        timestamp: number;
        status: number;
        errors: Array<ErrorItem>;
      }
    | undefined;
}
