<input
  type="text"
  class="form-control"
  [placeholder]="placeholder"
  [class.has-addon]="hasAddon"
  [class.placeholder-hidden]="placeholderHidden"
  [min]="min"
  [max]="max"
  [maxlength]="maxLength"
  appNaturalNumberValidator
  [required]="required"
  [name]="$any(name)"
  [id]="name"
  [disabled]="disabled"
  [appIsEnabled]="appIsEnabled"
  (keydown)="onKeyDown($event)"
  (blur)="onBlur()"
  (onValidated)="onValidated($event)"
  (change)="onChange($event)"
  [(ngModel)]="value"
/>
<ng-content select=".form-group-input-label"></ng-content>
<div>
  <!--
  this empty div is required (so that the input is not the first and last element inside the
  app-natural-number-input tag) to trick bootstrap so it does not remove border radius
  from the input's left side in case it is used in an input group with a button attached
--></div>
<ng-template #errorsTemplate>
  <div *ngIf="errors && errors.required">Missing value or invalid format</div>
  <div *ngIf="errors && errors.negative">The value should be a non-negative number</div>
  <div *ngIf="errors && errors.notInteger">The value should be a number</div>
  <div *ngIf="errors && errors.min">Minimum allowed value is {{ this.min }}.</div>
  <div *ngIf="errors && errors.max">Maximum allowed value is {{ this.max }}.</div>
  <div *ngIf="errors && errors.maxlength">The value may only contain up to {{ this.maxLength }} digits.</div>
  <div *ngIf="backendValidationError">
    {{ backendValidationError }}
  </div>
</ng-template>
