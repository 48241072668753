import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseService} from 'public-shared/services/base-http/base.service';
import {Observable} from 'rxjs';
import {MyProfileEndpoints} from 'shared/services/endpoint-constants';
import {MyProfilePasswordChange} from './my-profile-password-change.dto';
import {MyProfile} from './my-profile.dto';

@Injectable()
export class MyProfileService extends BaseService {
  constructor(protected override http: HttpClient) {
    super(http);
    this.baseUrl = MyProfileEndpoints.BASE;
  }

  public getMyProfile$(): Observable<MyProfile> {
    return this.get$<MyProfile>(MyProfileEndpoints.ACCOUNT);
  }

  public modifyMyProfile$(myProfile: MyProfile): Observable<void> {
    // Role should not be part of the PUT request
    delete myProfile.role;
    return this.put$<void>(MyProfileEndpoints.ACCOUNT, myProfile);
  }

  public modifyPassword$(entity: MyProfilePasswordChange): Observable<void> {
    return this.put$<void>(MyProfileEndpoints.PASSWORD, entity);
  }
}
