import {HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpParams, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CustomerEnvironmentData} from '@jumio/portals.core';
import {BaseErrorCodes} from 'public-shared/models/error/base-error-codes';
import {ErrorDescriptor, ErrorItem} from 'public-shared/models/error/error-descriptor';
import {EnvironmentProvider} from 'public-shared/services/environment-provider';
import {PublicSecurityContextHolder} from 'public-shared/services/security/public-security-context-holder';
import {Observable, throwError as _throw} from 'rxjs';
import {catchError} from 'rxjs/operators';

@Injectable()
/**
 * Intercepts all HTTP requests and adds base URL, Authorization header and handles error responses.
 */
export class PublicCustomHttpInterceptor implements HttpInterceptor {
  public static readonly AUTHORIZATION_TOKEN_TYPE = 'Bearer';
  public static readonly HEADER_AUTHORIZATION_KEY = 'Authorization';

  protected static toErrorDescriptor(errorResponse: JumioErrorResponse): ErrorDescriptor {
    let errorDescriptor: ErrorDescriptor;

    if (errorResponse.status === 0) {
      errorDescriptor = new ErrorDescriptor('Server is down', BaseErrorCodes.SERVER_IS_DOWN.toString(), 0);
    } else if (errorResponse.status === 404) {
      //@ts-ignore
      errorDescriptor = new ErrorDescriptor('Not found', errorResponse?.error?.errorCode, 404);
    } else {
      errorDescriptor = new ErrorDescriptor(
        //@ts-ignore
        errorResponse.error?.error,
        errorResponse.error?.errorCode,
        errorResponse.error?.status,
        errorResponse.error?.errors,
        errorResponse.error?.timestamp
      );
    }
    return errorDescriptor;
  }

  constructor(
    protected securityContextHolder: PublicSecurityContextHolder,
    protected environmentProvider: EnvironmentProvider<CustomerEnvironmentData>
  ) {}

  /**
   * The main method implementing the Interceptor interface.
   * Receives a HTTP request, and depending on the current environment, forwards it to either the mocked backend or the real backend.
   * After sending the events, catches error responses and converts them to ErrorDescriptor objects.
   * @param {HttpRequest<any>} req The current HTTP request that has been sent out from the client.
   * @param {HttpHandler} next The forwarding handler.
   * @returns {Observable<HttpEvent<any>>} An Observable which contains the response as an event stream.
   */
  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    req = req.clone({
      url: this.prepareUrl(req.url, req.params),
      headers: this.addAuthHeader(req.headers)
    });

    const event$: Observable<HttpEvent<any>> = next.handle(req);

    return event$.pipe(
      catchError((errorResponse: HttpErrorResponse) =>
        _throw(PublicCustomHttpInterceptor.toErrorDescriptor(errorResponse as JumioErrorResponse))
      )
    );
  }

  /**
   * Depending on the parameters of the request, prepares the request's new URL.
   * @param {string} url The current URL contained by the request.
   * @param {HttpParams} params The params object of the request.
   * @returns {string} The new URL where the request will be sent.
   */
  protected prepareUrl(url: string, params: HttpParams): string {
    return params && (params as InterceptorHttpParams).skipApiUrl ? '' : this.environmentProvider.environment.apiUrl() + url;
  }

  /**
   * If available and not set yet, sets the Authorization header and returns a new headers object.
   * @param {HttpHeaders} headers The current headers of the request.
   * @returns {HttpHeaders} The new headers object that will be sent.
   */
  protected addAuthHeader(headers: HttpHeaders): HttpHeaders {
    if (this.getAuthToken()) {
      //@ts-ignore
      headers = headers.set(PublicCustomHttpInterceptor.HEADER_AUTHORIZATION_KEY, this.getAuthToken());
    }
    return headers;
  }

  /**
   * Creates the authorization token (formatted 'Bearer <authToken>').
   * @returns {string} The authorization token as a string.
   */
  protected getAuthToken(): string | null {
    let authToken: string | null = null;
    if (this.securityContextHolder.accessToken) {
      authToken = PublicCustomHttpInterceptor.AUTHORIZATION_TOKEN_TYPE + ' ' + this.securityContextHolder.accessToken;
    }
    return authToken;
  }
}

/**
 * A helper class for extending the original HttpParams class,
 * to allow setting some extra options within the request.
 */
export class InterceptorHttpParams extends HttpParams {
  public skipApiUrl?: boolean;
}

/**
 * A helper class for extending the original HttpErrorResponse class,
 * which contains the errorCode, timestamp and status properties sent by the backend.
 */
export class JumioErrorResponse extends HttpErrorResponse {
  public override error:
    | {
        [key: string]: any;
        error: string;
        errorCode: string;
        timestamp: number;
        status: number;
        errors: Array<ErrorItem>;
      }
    | undefined;
}
