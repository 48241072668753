import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {InterceptorHttpParams} from 'public-shared/interceptors/http-interceptor/public-custom-http.interceptor';
import {BaseService} from 'public-shared/services/base-http/base.service';
import {Observable} from 'rxjs';

@Injectable()
export class PdfService extends BaseService {
  constructor(protected override http: HttpClient) {
    super(http);
  }

  public getAssetSkipUrl$(path: string): Observable<Blob> {
    const params = new InterceptorHttpParams();
    params.skipApiUrl = true;

    return this.http.get(path, {params, responseType: 'blob'});
  }
}
