export class BaseEndpoints {
  public static readonly V1 = 'v1/';
  public static readonly V2 = 'v2/';
}

export class ResolveAuthEndpoints {
  public static readonly BASE = `${BaseEndpoints.V1}`;
  public static readonly LOGIN = `login`;
}

export class LoggerEndpoints {
  public static readonly BASE = BaseEndpoints.V1 + 'log/';
  public static readonly ERROR = 'error/';
  public static readonly INFO = 'info/';
  public static readonly WARN = 'warn/';
}
