import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ForgotPasswordEndpoints} from 'public-shared/models/endpoints/forget-password-endpoint.constants';
import {BaseService} from 'public-shared/services/base-http/base.service';
import {Observable} from 'rxjs';
import {ForgotNewPassword} from './forgot-new-password.dto';
import {ForgotPassword} from './forgot-password.dto';

@Injectable()
export class ForgotPasswordService extends BaseService {
  constructor(protected override http: HttpClient) {
    super(http);
    this.baseUrl = ForgotPasswordEndpoints.BASE;
  }

  public resetPassword$(entity: ForgotPassword): Observable<void> {
    return this.post$<void>(ForgotPasswordEndpoints.RESET, entity);
  }

  public newPassword$(entity: ForgotNewPassword): Observable<void> {
    return this.post$<void>(ForgotPasswordEndpoints.NEW, entity);
  }
}
