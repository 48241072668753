import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';
import {MomentInput} from 'moment';

@Pipe({
  name: 'utcDateFormat'
})
export class UtcDateFormatPipe implements PipeTransform {
  public transform(value: MomentInput, format = 'YYYY-MM-DD HH:mm:ss'): string | null {
    return value ? moment.utc(value).format(format) : null;
  }
}
