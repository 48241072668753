import {Injectable} from '@angular/core';
import {Router, RouterStateSnapshot} from '@angular/router';

@Injectable()
export class RedirectService {
  constructor(private router: Router) {}

  redirectToLogin(state?: RouterStateSnapshot): void {
    if (state?.url) {
      this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}}).then(() => false);
    } else {
      this.router.navigate(['/login']).then(() => false);
    }
  }
}
