import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {JavaDateParserPipe} from 'public-shared/pipes/utc-date-format/java-date-parser.pipe';
import {UtcDateFormatPipe} from 'public-shared/pipes/utc-date-format/utc-date-format.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [UtcDateFormatPipe, JavaDateParserPipe],
  // this providers change is needed, because the ScreeningResultBlockComponent is injecting this pipe to access the transform function
  providers: [UtcDateFormatPipe],
  exports: [UtcDateFormatPipe, JavaDateParserPipe]
})
export class PipesModule {}
