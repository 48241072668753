import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseService} from 'public-shared/services/base-http/base.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {JumioPage} from 'shared/components/table/dto/jumio-page';
import {DSRRequestDTO, DSRRequestFilterDTO, DSRResponseDTO} from 'shared/services/dsr-requests/dsr-requests.dto';
import {DSRRequestsAdminEndpoints} from 'shared/services/endpoint-constants';
import {FilterService} from 'shared/services/filter.service';

@Injectable()
export class DsrRequestsService extends BaseService implements FilterService {
  constructor(protected override http: HttpClient) {
    super(http);
    this.baseUrl = DSRRequestsAdminEndpoints.BASE;
  }

  public filter$(filter: DSRRequestFilterDTO): Observable<JumioPage<DSRRequestDTO>> {
    let {offset, pageSize, reportType, direction} = filter;
    //@ts-ignore
    offset = offset / 10;
    const url = `${DSRRequestsAdminEndpoints.DATA_REPORT}?offset=${offset}&reportType=${reportType}&direction=${direction}&pageSize=${pageSize}`;

    return this.get$<DSRResponseDTO>(url).pipe(
      map((result: DSRResponseDTO) => ({
        list: result.items,
        total: result.pageMeta?.totalElements
      }))
    );
  }

  public downloadJobResults$(id: string): Observable<Blob> {
    return this.getCsv$(DSRRequestsAdminEndpoints.DATA_REPORT_RESOURCE_RESUlT(id)).pipe(map(text => new Blob([text], {type: 'text/csv'})));
  }

  public uploadDSR$(formData: FormData): Observable<void> {
    return this.post$<void>(DSRRequestsAdminEndpoints.DATA_REPORT, formData);
  }
}
