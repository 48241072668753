import {CodeLabel} from 'public-shared/models/code-label/code-label.dto';
import {BaseNameDTO} from 'shared/services/base.dto';
import {WithCustomerName} from './j4-with-customer-name';

export class J4Customer extends BaseNameDTO {
  public publicId?: string;
  public createdAt?: Date;
  public enabled?: boolean;

  public static toRequestDto(customer: J4Customer, withEnabled = true): J4Customer {
    const dto = new J4Customer();
    if (withEnabled) {
      dto.enabled = customer.enabled;
    }
    dto.name = customer.name;
    return dto;
  }
}

export class CustomerTypes {
  public types: CodeLabel[] | undefined;
}

export class J4CustomerDetail implements WithCustomerName {
  public name: string | undefined;
}
