import {words} from 'lodash-es';

/**
 * Humanizes a string
 * From https://stackoverflow.com/a/28339742
 * and https://stackoverflow.com/a/17572933
 * and https://stackoverflow.com/a/21149072
 * @returns {string} Humanized string
 */
export const humanize = (text: string): string => {
  // Replace all Caps words to all lower
  const str = text.replace(/^[^a-z]*$/, m => m.toLowerCase());

  return words(str)
    .join(' ')
    .replace(/^[\s_]+|[\s_]+$/g, ' ')
    .replace(/[_\s]+/g, ' ')
    .replace(/^[a-zA-Z]/, m => m.toUpperCase())
    .replace(/\B[a-zA-Z].*/, m => m.toLowerCase());
};
