<div class="container-fluid">
  <div class="row">
    <div class="service-alert" *ngIf="serviceError">Service is currently unavailable. Please try again later.</div>
    <div class="visible-lg">
      <div class="col-lg-7 left-side">
        <div class="marketing-text">
          <h1>Welcome to the Jumio Portal</h1>
          <p>
            The KYX Platform helps you know and trust your users, players, patients — whoever the “X” is in your business — online. From
            onboarding to ongoing monitoring, the KYX Platform provides the tools you need to help fight fraud and financial crime while
            ensuring a fast and easy experience for your legitimate customers.
          </p>
        </div>
      </div>
    </div>
    <div class="col-lg-5 col-md-12 right-side">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
