export class UserDetailsConstants {
  public static readonly UPDATE_LABEL = 'Update';
  public static readonly CREATE_LABEL = 'Update';
  public static readonly ACTIVATE_LABEL = 'Activate';
  public static readonly DEACTIVATE_LABEL = 'Deactivate';
  public static readonly RESEND_LABEL = 'Resend';
  public static readonly UNLOCK_LABEL = 'Unlock';
  public static readonly RESET_TFA_LABEL = 'Reset TFA';

  public static readonly UPDATE_CONFIRM = '';
  public static readonly CREATE_CONFIRM = 'Please confirm users creation with your password!';
  public static readonly ACTIVATE_CONFIRM = 'Please confirm user/s activation with your password!';
  public static readonly DEACTIVATE_CONFIRM = 'Please confirm user/s deactivation with your password!';
  public static readonly RESEND_CONFIRM = 'Please confirm email resending with your password!';
  public static readonly UNLOCK_CONFRIM = 'Please confirm unlock with your password!';
  public static readonly RESET_TFA_CONFIRM = 'Please confirm resetting TFA with your password!';

  public static readonly UDPATE_SUCCESS = 'Update successful';
  public static readonly CREATE_SUCCESS = 'Creation successful';
  public static readonly ACTIVATE_SUCCESS = 'Activation successful';
  public static readonly DEACTIVATE_SUCCESS = 'Deactivation successful';
  public static readonly RESEND_SUCCESS = 'Email sent';
  public static readonly UNLOCK_SUCCESS = 'Unlock successful';
  public static readonly RESET_TFA_SUCCESS = 'TFA reset successful';

  public static readonly ACTIVATED_STATUS_LABEL = 'Activated';
  public static readonly DEACTIVATED_STATUS_LABEL = 'Deactivated';

  public static readonly ACTIVATED_STATUS_CODE = 'ACTIVATED';
  public static readonly DEACTIVATED_STATUS_CODE = 'DEACTIVATED';
  public static readonly EXPIRED_STATUS_CODE = 'EXPIRED';

  public static readonly UPDATE_OPERATION_CODE = 'UPDATE';
  public static readonly ACTIVATE_OPERATION_CODE = 'ACTIVATE';
  public static readonly DEACTIVATE_OPERATION_CODE = 'DEACTIVATE';
  public static readonly RESEND_OPERATION_CODE = 'RESEND';
  public static readonly UNLOCK_OPERATION_CODE = 'UNLOCK';
  public static readonly RESET_TFA_OPERATION_CODE = 'RESETTFA';

  public static readonly VERIFIED_LABEL = 'Verified';
  public static readonly NOT_VERIFIED_LABEL = 'Not verified';

  public static readonly CONFIRMED_LABEL = 'Confirmed';
  public static readonly NOT_CONFIRMED_LABEL = 'Not confirmed';
}
