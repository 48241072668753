import {ErrorHandler, Injectable, inject} from '@angular/core';
import {UntilDestroy} from '@jumio/portals.core';
import {BaseErrorCodes} from 'public-shared/models/error/base-error-codes';
import {ErrorDescriptor} from 'public-shared/models/error/error-descriptor';
import {NotificationService} from 'public-shared/services/notification/notification.service';
import {Subject, Subscription} from 'rxjs';

@UntilDestroy()
@Injectable()
/**
 * Generic error handler service.
 */
export class AuthGlobalErrorHandler implements ErrorHandler {
  /**
   * Converts generic error to ErrorDescriptor type which can be handled.
   * @param error The error input that can be of any type.
   * @returns {any} Returns the error converted into an ErrorDescriptor object.
   */
  public static convertToErrorDescriptor(error: ErrorDescriptor): ErrorDescriptor {
    if (error instanceof ErrorDescriptor) {
      return error;
    } else {
      return ErrorDescriptor.unknownError(error);
    }
  }

  /**
   * unavailable service subject
   */
  public unavailableServiceSubject: Subject<ErrorDescriptor> | undefined;

  /**
   * The last error that occured in the app.
   */
  public error: ErrorDescriptor | undefined;

  public readonly subscription = new Subscription();
  private notificationService = inject(NotificationService);

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  /**
   * Error handling function. Converts the error to to an ErrorDescriptor, then navigates the user to the appropriate page.
   * @param error The current error input.
   */
  public handleError(error: any): void {
    this.error = AuthGlobalErrorHandler.convertToErrorDescriptor(error);

    if (this.error.status === 404) {
      this.notificationService.warn('Not found. The requested page might have been removed or moved to another destination.');
    } else if (this.error.status === 403) {
      this.notificationService.warn("Unauthorized. You don't have the required permission to view this page.");
    } else if (this.error.errorCode === BaseErrorCodes.SERVICE_UNAVAILABLE) {
      this.unavailableServiceSubject?.next(this.error);
    } else {
      this.notificationService.error(this.error);
    }
  }
}
