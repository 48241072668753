import {RiskScoreThresholds} from 'shared/services/kyx-transactions/kyx-risk-score-thresholds';
import {MerchantAddressHandlingOptions} from './merchant-address-options.enum';
import {MerchantIdentityVerificationOptions} from './merchant-identity-verification.enum';
import {WithMerchantName} from './with-merchant-name';

export class MerchantSettingsBase {
  public defaultSettings: MerchantDefaultSettings | undefined;
  public extractionSettings: MerchantExtractionSettings | undefined;
  public webUiSettings: MerchantWebUiSettings | undefined;
  public identityVerificationSettings: MerchantIdentityVerification | undefined;
  public kyxSettings: MerchantKyxSettings | undefined;
}

export class MerchantSettings extends MerchantSettingsBase implements WithMerchantName {
  public merchantName: string | undefined;
  public offlineTokenSettings: MerchantOfflineTokenSettings | undefined;
  public identityTechnologies: MerchantIdentityVerificationOptions[] | undefined;
}

export class MerchantSettingsRequest extends MerchantSettingsBase {
  public offlineToken: string | undefined;
  public productType: string | undefined;

  public static fromMerchantSettings(settings: MerchantSettings): MerchantSettingsRequest {
    const request = new MerchantSettingsRequest();
    request.defaultSettings = settings.defaultSettings;
    if (settings.extractionSettings) {
      request.extractionSettings = settings.extractionSettings;
    }
    if (settings.webUiSettings) {
      request.webUiSettings = MerchantWebUiSettings.toRequestWebUiSettings(settings.webUiSettings);
    }
    if (settings.identityVerificationSettings) {
      request.identityVerificationSettings = settings.identityVerificationSettings;
    }
    if (settings.kyxSettings) {
      request.kyxSettings = settings.kyxSettings;
    }
    return request;
  }
}

export class MerchantDefaultSettings {
  public pciCompliant: boolean | undefined;
  public apiCallsLogging: boolean | undefined;
  public twoFactorAuthEnabled: boolean | undefined;
  public verificationEnabled: boolean | undefined;
  public analyticsEnabled: boolean | undefined;
  public additionalDataPointsSdk: boolean | undefined;
  public webAnalyticsEnabled: boolean | undefined;
  public unpreparedRedirectEnabled: boolean | undefined;
  public revertFaceSplit: boolean | undefined;
  public keepUnmanipulatedImage: boolean | undefined;
  public blackAndWhiteAllowed: boolean | undefined;
  public apiReadableCallbackEnabled: boolean | undefined;
  public authenticationsEnabled: boolean | undefined;
  public machineLearningConsentEnabled: boolean | undefined;
  public machineLearningEvaluationConsentEnabled: boolean | undefined;
  public dataProcessingConsentEnabled: boolean | undefined;
  public remediationActive: boolean | undefined;
  public allowDownloadOfFacemap: boolean | undefined;
  public addressVerificationEnabled: boolean | undefined;
  public addressValidationEnabled: boolean | undefined;
  public addressEnabled: boolean | undefined;
  public cpfValidationEnabled: boolean | undefined;
  public deviceCheckEnabled: boolean | undefined;
  public eKycEnabled: boolean | undefined;
  public aamvaEnabled: boolean | undefined;
  public singaporeDbEnabled: boolean | undefined;
  public digitalIdentityEnabled: boolean | undefined;
  public argentinaIdCheckEnabled: boolean | undefined;
  public biometricsCheckEnabled: boolean | undefined;

  public dataTransferEnabled: boolean | undefined;
  public highResImgEnabled: boolean | undefined;
  public brandingEnabled: boolean | undefined;

  public automationOptions: string | undefined;
  public addressExtractionMode: MerchantAddressHandlingOptions | undefined;
  public dial: number | undefined;
  public riskScoreThresholds: RiskScoreThresholds | undefined;

  public kyxDocumentVerificationEnabled: boolean | undefined;
}

export class MerchantKyxSettings {
  public riskScoreCustomizationRulesetId: string | undefined;
  public decisionLabelCustomizationRulesetId: string | undefined;
}

export class MerchantExtractionSettings {
  public activateLDLExtraction: boolean | undefined;
  public activatePermissionDLExtraction: boolean | undefined;
  public activateCURPExtraction: boolean | undefined;
  public activatePESELExtraction: boolean | undefined;
  public chineseNameExtraction: boolean | undefined;
  public issuingDateExtraction: boolean | undefined;
  public visaAdditionalExtraction: boolean | undefined;
  public mdxExtractionActive: boolean | undefined;
  public dlBackNeeded: boolean | undefined;
  public idBackNeeded: boolean | undefined;
  public maskingRequired: boolean | undefined;
  public creditCardExtraction: boolean | undefined;
  public interimExtractionOptions: string | undefined;
  public dlDateExtraction: string | undefined;
  public maskingCountries: string | undefined;
  public hrzBarcodeCheck: string | undefined;
  public addressExtractionCountries: string | undefined;
  public genericKey: string | undefined;
  public genericValue: string | undefined;
  public cpfExtractionEnabled: boolean | undefined;
}

export class MerchantWebUiSettings {
  public enabledCameraResolutionList: number[] | undefined;
  public minimumCameraResolution: number | undefined;
  public enableVerificationCreationForNVW4: boolean | undefined;
  public genericIconsAllowed: boolean | undefined;
  public uiUpdateAllowed: boolean | undefined;

  public static toRequestWebUiSettings(merchantWebUiSettings: MerchantWebUiSettings): MerchantWebUiSettings {
    const settings = new MerchantWebUiSettings();
    settings.minimumCameraResolution = merchantWebUiSettings.minimumCameraResolution;
    settings.enableVerificationCreationForNVW4 = merchantWebUiSettings.enableVerificationCreationForNVW4;
    settings.genericIconsAllowed = merchantWebUiSettings.genericIconsAllowed;
    settings.uiUpdateAllowed = merchantWebUiSettings.uiUpdateAllowed;
    return settings;
  }
}

export class MerchantOfflineTokenSettings {
  public androidCertificateFingerprint: string | undefined;
  public iosBundleIdentifier: string | undefined;
  public expirationDate: Date | number | undefined;
  public brandingShown: boolean | undefined;
  public androidBarcode: string | undefined;
  public iosBarcode: string | undefined;
  public product: string | undefined;
}

export class OfflineTokenResult {
  public offlineToken: string | undefined;
}

export class MerchantIdentityVerification {
  public forceCameraForIdentityV4: boolean | undefined;
  public faceMatchEnabled: boolean | undefined;
  public customPhrase: string | undefined;
  public identityTechnology: MerchantIdentityVerificationOptions | undefined;
}

export interface GenericValue {
  value: string | undefined;
}

export interface MerchantIpRanges {
  ipRanges: string[] | undefined;
}

export interface IntegrationSettings {
  code: string | undefined;
  displayName?: string;
  type?: SettingFieldType;
  value: string;
  note?: string;
}

type SettingFieldType = 'BOOLEAN' | 'TEXT' | 'PASSWORD';

export const MODE = {
  SilentDualKyxMode: 'silentDualKyxMode',
  WrapperMode: 'wrapperMode'
} as const;

export type Mode = keyof typeof MODE;

export type ModeValues = (typeof MODE)[keyof typeof MODE];

export const SILENT_DUAL_KYX_MODE = {
  DUAL_ORCHESTRATION: 'DUAL_ORCHESTRATION',
  DUMMY: 'DUMMY'
} as const;

export type SilentDualKyxMode = keyof typeof SILENT_DUAL_KYX_MODE;

export type SilentDualKyxModeValues = (typeof SILENT_DUAL_KYX_MODE)[keyof typeof SILENT_DUAL_KYX_MODE];

export const WRAPPER_MODE = {
  FULL_V2: 'FULL_V2',
  KYX_ACQUISITION: 'KYX_ACQUISITION',
  DUMMY: 'DUMMY'
} as const;

export type WrapperMode = keyof typeof WRAPPER_MODE;

export type WrapperModeValues = (typeof WRAPPER_MODE)[keyof typeof WRAPPER_MODE];

export interface JumioPlatformUpgradeSettings {
  silentDualKyxModeEnabled: boolean;
  silentDualKyxModeValue: SilentDualKyxModeValues | null;
  idvWrapperModeEnabled: boolean;
  docVWrapperModeEnabled: boolean;
  idvWrapperModeValue: WrapperModeValues | null;
  docVWrapperModeValue: WrapperModeValues | null;
  idvRetrievalFromStorageEnabled: boolean;
}

export interface UpdateJumioPlatformUpgradeSettingsResponse {
  message: string;
  errormessage: string;
}

export const WRAPPER_MODE_DROPDOWN_VALUES = Object.keys(WRAPPER_MODE).map(value => ({label: value, code: value}));
