import {Component, HostBinding, OnDestroy} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {ErrorDescriptor} from 'public-shared/models/error/error-descriptor';
import {EnvironmentProvider} from 'public-shared/services/environment-provider';
import {BehaviorSubject, Subscription} from 'rxjs';
import {filter} from 'rxjs/operators';
import {IJ4AuthEnvironmentData} from '../../../environments/environment.prod';

@Component({
  selector: 'app-login-layout',
  templateUrl: './login-layout.component.html',
  styleUrls: ['./login-layout.component.less']
})
/**
 * The main component for MUI app.
 */
export class LoginLayoutComponent implements OnDestroy {
  @HostBinding('@.disabled')
  /**
   * If true, all animations will be disabled in the application.
   */
  public animationsDisabled: boolean;
  public serviceError?: ErrorDescriptor | null;

  public readonly TITLE_POSTFIX = ' - Jumio Portal';
  public readonly subscription = new Subscription();

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    title: Title,
    protected environmentProvider: EnvironmentProvider<IJ4AuthEnvironmentData>
  ) {
    this.subscription.add(
      // @ts-ignore
      router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((navEnd: NavigationEnd) => {
        let firstRoute = this.activatedRoute;
        while (firstRoute.firstChild) {
          firstRoute = firstRoute.firstChild;
        }
        const titleStr = (firstRoute.data as BehaviorSubject<any>).value['title'] || navEnd.url.substr(1);

        title.setTitle(titleStr + this.TITLE_POSTFIX);
        if (this.serviceError) {
          this.serviceError = null;
        }
      })
    );
    this.animationsDisabled = !environmentProvider.environment.animationEnabled;
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
