import {Subject} from 'rxjs';
import {LoginResult, UserContext} from '../auth/login-result.dto';

/**
 * This service holds the user's context values that are needed during the life cycle of the application.
 */
export abstract class BaseSecurityContextHolder {
  public static readonly LOCAL_STORAGE_CURRENT_USER = 'currentUser';

  /**
   * The context values of the user are held in this object.
   */
  protected _user?: UserContext;

  /**
   * A Subject that holds the value of the current login.
   * @type {Subject<boolean>}
   * @protected
   */
  protected _loginSubscriber = new Subject<boolean>();

  /**
   * Gets the current user context from the browser's localstorage.
   */
  constructor() {
    const val = localStorage.getItem(BaseSecurityContextHolder.LOCAL_STORAGE_CURRENT_USER);
    this._user = val && val.length > 0 ? JSON.parse(val) : null;
  }

  /**
   * Deletes the user context from both the service and the localstorage.
   */
  public invalidate(): void {
    delete this._user;
    localStorage.setItem(BaseSecurityContextHolder.LOCAL_STORAGE_CURRENT_USER, '');
    this._loginSubscriber.next(false);
  }

  /**
   * Sets the user context for both the service and localstorage.
   * @param {UserContext} userContext The context of the currently logged in user.
   */
  public validate(userContext: UserContext): void {
    this._user = userContext;
    localStorage.setItem(BaseSecurityContextHolder.LOCAL_STORAGE_CURRENT_USER, JSON.stringify(userContext));
    this._loginSubscriber.next(true);
  }

  /**
   * Checks whether the current user is logged in.
   * @returns {boolean} True, if user context is given, and also if TFA is enabled on the current MUI set, it's set.
   */
  public get isLoggedIn(): boolean {
    return !!this._user;
  }

  /**
   * Returns the login status Subject as an Observable.
   * @returns {Observable<boolean>} The loginStatus Subject as an Observable.
   */
  public get loginSubscriber$(): Subject<boolean> {
    return this._loginSubscriber;
  }

  /**
   * The current user's context object.
   * @returns {LoginResult}
   */
  public get user(): LoginResult {
    //@ts-ignore
    return this._user;
  }

  /**
   * The current user's accesstoken, if it exists.
   * @returns {string}
   */
  public get accessToken(): string | null | undefined {
    return this._user ? this._user.accessToken : null;
  }
}
