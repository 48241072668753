import {JumioPageFilter} from 'shared/components/table/dto/jumio-page-filter';
import {JumioSort} from 'shared/components/table/dto/jumio-sort';
import {SimpleDataset} from './datasets.dto';

export class TaggingTemplate extends SimpleDataset {
  public displayId: number | undefined;
  public active: boolean | undefined;
  public components: TaggingTemplateComponentData[] | undefined;
  public createdAt: string | undefined;
  public deleted: boolean | undefined;
  public description: string | undefined;
  public updatedAt: string | undefined;
  public attached: boolean | undefined;

  public static TaggingTemplateComponentPopulator(components: TaggingTemplateComponentData[]): TaggingTemplateComponentData[] {
    components.forEach((component, index) => {
      component.uniqueId = index;
      if (component.type === TaggingTemplateComponentType.SHAPE) {
        component.options = component.images;
        component.images = undefined;
      }
    });
    return components;
  }
}

export class TaggingTemplateFilter extends JumioPageFilter {
  public displayId?: number;
  public id?: string;
  public components: string[] | undefined;
  public createdAtFrom: number | undefined;
  public createdAtTo: number | undefined;
  public deleted: boolean;
  public description: string | undefined;
  public name: string | undefined;
  public active: boolean;
  public createdBy: string | undefined;

  constructor() {
    super();
    this.deleted = false;
    this.active = true;
    this.sort = new JumioSort(JumioSort.CREATED_AT, false);
  }
}

export class TaggingTemplateComponentData {
  public name: string | undefined;
  public type: string | TaggingTemplateComponentType | undefined;
  public label: string | undefined;
  public value: string | undefined;
  public prefillColumn: string | undefined;
  public images: string[] | undefined;
  public options: string[] | undefined;
  public mandatory: boolean | undefined;
  public annotations: boolean | undefined;
  public prefill: boolean | undefined;
  public replicable: boolean | undefined;
  public replicableColumn: string | undefined;
  public order: number | undefined;
  public uniqueId: number | undefined;

  constructor() {
    this.options = [] as string[];
  }
}

export enum TaggingTemplateComponentType {
  VIDEO = 'video',
  IMAGE = 'image',
  SINGLE = 'singleChoice',
  MULTI = 'multipleChoice',
  SHAPE = 'shape',
  FREETEXT = 'string',
  FREETEXT_MULTI = 'text',
  SPECIAL = 'specialCharString'
}

export class TaggingTemplateWrapper {
  public name: string | undefined;
  public description: string | undefined;
  public components: TaggingTemplateComponentData[] | undefined;
  public active?: boolean;
  public attached?: boolean;
  public id: string | undefined;
  public displayId: number | undefined;
  public createdBy: string | undefined;

  /**
   * Populates the Wrapper with the data from the backend
   * Needs some transformation to be compatible with the UI
   * @param name
   * @param description
   * @param components
   */
  public populate(
    id: string,
    displayId: number,
    name: string,
    description: string,
    active: boolean,
    components: TaggingTemplateComponentData[]
  ): void {
    this.id = id;
    this.displayId = displayId;
    this.name = name;
    this.description = description;
    this.active = active;
    this.components = components;
    this.components = TaggingTemplate.TaggingTemplateComponentPopulator(this.components);
  }
}
