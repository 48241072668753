import {Annotation} from '../common/annotation.dto';

export interface Workflow {
  accountId: string;
  completedAt: string;
  credentialSummaries: Array<WorkflowCredentialSummary>;
  initiatedAt: string;
  merchantGuid: string;
  startedAt: string;
  workflowDefinitionKey: string;
  workflowExecutionId: string;
  workflowStatus: WorkflowStatus;
}

export interface WorkflowCredentialSummary {
  id: string;
  category: CredentialCategory;
  decisionSummary: WorkflowDecisionSummary;
  channel: string;
}

export interface WorkflowDecisionSummary {
  capability: CapabilityCategory;
  type: DecisionType;
  detail: WorkflowDecisionDetail;
  data: Array<WorkflowDecisionDetail>;
}

export interface WorkflowDecisionDetail {
  label: string;
  value?: string;
}

export interface WorkflowDetail {
  workflowExecutionId: string;
  workflowStatus: WorkflowStatus;
  workflowDefinitionKey: string;
  workflowName: string;
  accountId: string;
  completedAt: string;
  initiatedAt: string;
  startedAt: string;
  name?: string; // Account name
  wipedOutAt?: string;
  originalDefinitionKey: string;
  redirectedWorkflow: boolean;
  isFraudAvailable: boolean;
  customerInternalReference?: string;
  userReference?: string;
  reportingCriteria?: string;
  tokenExpiresAt?: string;

  credentials: Credential[];
}

export interface IMerchant {
  id: string;
  name: string;
  publicGuid?: string;
}

export interface WorkflowDetailAP extends WorkflowDetail {
  merchantSettings: {[key: string]: string};
  merchant?: IMerchant;
}

export interface MerchantTransaction {
  id: string;
  name: string;
  publicGuid: string;
}

export interface KYXAPTransactionDetail extends WorkflowDetailAP {
  childWorkflowExecutionIds: string[];
  parentWorkflowExecutionId: string;
  silentWorkflow: boolean;
  riskScore: number;
  finalDecision: string;
  stepDecisions: {[key: string]: string};
  parts: {CredentialCategory: Part};
  merchant?: MerchantTransaction;
}

export interface CapabilityMapped {
  label: CapabilityCategory;
  data: CapabilityData[];
  primaryCredentialName?: string; // AP Detail Page
  decision: {
    type: DecisionType;
    detail: {
      id: string;
      label: string;
    };
    additionalInformation?: string;
  };
}

export interface CapabilityData {
  label: string;
  value: string;
  type?: WorkflowCapabilityType;
}

export interface Part {
  classifier: PartClassifier;
  uploadedAt?: string;
  wipedOutAt?: string;
  href: string;
  parentKey?: string; // Where is the part originally coming from
  imageAnnotations?: Annotation[];
  stepId?: string;
  credentialId?: string;
}

export interface Credential {
  id: string;
  category: CredentialCategory;
  parts?: Part[];
  capabilities: CapabilityMapped[];
  clientIP?: string;

  groupedCapabilities?: {[key: string]: CapabilityMapped[]}; // AP Detail Page - grouped Capability by primaryCredentialName

  decision?: {
    type: DecisionType;
    detail: {
      id?: string;
      label: string;
    };
  };
}

export enum CredentialCategory {
  ID = 'ID',
  ID_IV_Verification = 'ID and Identity Verification',
  IV = 'Identity Verification',
  ID_V = 'ID Verification',
  DOC_V = 'Document Verification',
  FACEMAP = 'FACEMAP',
  SELFIE = 'SELFIE',
  DOCUMENT = 'DOCUMENT',
  SCREENING = 'SCREENING'
}

export enum CapabilityCategory {
  USABILITY = 'USABILITY',
  STORAGE = 'STORAGE',
  EXTRACTION = 'EXTRACTION',
  DATA_CHECKS = 'DATA_CHECKS',
  IMAGE_CHECKS = 'IMAGE_CHECKS',
  LIVENESS = 'LIVENESS',
  SIMILARITY = 'SIMILARITY',
  LOOKUPS = 'LOOKUPS',
  AUTHENTICATION = 'AUTHENTICATION',
  SCREENING = 'WATCHLIST_SCREENING'
}

export enum DecisionType {
  PASSED = 'PASSED',
  WARNING = 'WARNING',
  REJECTED = 'REJECTED',
  NOT_EXECUTED = 'NOT_EXECUTED'
}

export enum PartClassifier {
  FRONT = 'FRONT',
  BACK = 'BACK',
  FACE = 'FACE',
  LIVENESS = 'LIVENESS',
  FACEMAP = 'FACEMAP',
  ORIGIN = 'ORIGIN' // Document original pdf file
}

export enum WorkflowCapabilityType {
  URL = 'URL',
  DATE = 'DATE',
  STRING_LIST = 'STRING_LIST'
}

export type WorkflowStatus = 'INITIATED' | 'TOKEN_EXPIRED' | 'SESSION_EXPIRED' | 'ACQUIRED' | 'PROCESSED' | 'PROCESSING' | 'EXPIRED';

export enum WorkflowStatusName {
  INITIATED = 'INITIATED',
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  SESSION_EXPIRED = 'SESSION_EXPIRED',
  ACQUIRED = 'ACQUIRED',
  PROCESSED = 'PROCESSED',
  PROCESSING = 'PROCESSING'
}
