import {HttpClient} from '@angular/common/http';
import {BaseService} from 'public-shared/services/base-http/base.service';
import {Observable} from 'rxjs';
import {TryNowEndpoints} from 'shared/services/endpoint-constants';

export abstract class BaseTryNowService extends BaseService {
  constructor(protected override http: HttpClient) {
    super(http);
    this.baseUrl = TryNowEndpoints.BASE;
  }

  public abstract getCreateInit$(): Observable<any>;

  public abstract create$(_entity: any): Observable<any>;
}
