import {HttpClient} from '@angular/common/http';
import {Injectable, NgZone} from '@angular/core';
import {Router} from '@angular/router';
import {PublicAuthService} from 'public-shared/services/auth/public-auth.service';
import {Observable, Subscription} from 'rxjs';
import {tap} from 'rxjs/operators';
import {TfaRequest} from 'shared/services/auth/tfa-request.dto';
import {CacheService} from 'shared/services/common/cache.service';
import {AuthEndpoints} from 'shared/services/endpoint-constants';
import {SecurityContextHolder} from 'shared/services/security/security-context-holder';
import {RedirectService} from '../redirect/redirect-service.service';
import {UntilDestroy} from '@jumio/portals.core';

@UntilDestroy()
@Injectable()
export class AuthService extends PublicAuthService {
  readonly subscription = new Subscription();

  constructor(
    protected override securityContextHolder: SecurityContextHolder,
    protected override http: HttpClient,
    private router: Router,
    private cache: CacheService,
    private zone: NgZone,
    private redirectService: RedirectService
  ) {
    super(securityContextHolder, http);
  }

  public logout(): void {
    this.logoutCalled = true;
    if (this.securityContextHolder.isLoggedIn) {
      this.subscription.add(
        this.delete$<void>(AuthEndpoints.ACCESS_TOKEN).subscribe(
          () => this.doLogout(),
          () => this.doLogout()
        )
      );
    } else {
      this.doLogout();
    }
  }

  public get isLogoutCalled(): boolean {
    return this.logoutCalled;
  }

  /**
   * Sends the security code confirmation to the backend.
   * @param {string} passCode The security code as a string.
   * @returns {Observable<void>} The result as an Observable.
   */
  public setTfapassCode$(passCode: string): Observable<void> {
    return this.post$<void>(AuthEndpoints.TFA, new TfaRequest(this.securityContextHolder.accessToken, passCode)).pipe(
      tap(() => {
        // Set 'IsFirstSetupTfaQrCode' to null
        this.securityContextHolder.setIsFirstSetupTfaQrCode(null);
      })
    );
  }

  private doLogout(): void {
    this.zone.run(() => {
      this.cache.purge();
      this.securityContextHolder.invalidate();
      this.redirectService.redirectToLogin();
    });
  }
}
