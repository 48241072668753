import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseService} from 'public-shared/services/base-http/base.service';
import {Observable} from 'rxjs';
import {CacheService} from 'shared/services/common/cache.service';
import {WorkflowEndpoints} from 'shared/services/endpoint-constants';

@Injectable()
export class WorkflowService extends BaseService {
  constructor(protected override http: HttpClient, private cache: CacheService) {
    super(http);
    this.baseUrl = WorkflowEndpoints.BASE;
  }

  public getAutomationFacadeDebugLog$(scanReference: string, resultKey: string): Observable<any> {
    return this.get$<any>(WorkflowEndpoints.ADL(scanReference, resultKey));
  }

  public getAutomationFacadeUsabilityResults$(scanReference: string): Observable<any> {
    return this.get$<any>(WorkflowEndpoints.USABILITY_RESULTS(scanReference));
  }
}
