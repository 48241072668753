import {Component, EventEmitter, Input, Output} from '@angular/core';
import {ModalComponent} from '../modal.component';

@Component({
  selector: 'app-modal-confirm',
  templateUrl: './modal-confirm.component.html',
  styleUrls: ['./modal-confirm.component.less'],
  standalone: true,
  imports: [ModalComponent]
})
/**
 * The base component for holding confirmation dialogs, which will be displayed as modal windows.
 */
// eslint-disable @angular-eslint/no-output-on-prefix
export class ModalConfirmComponent extends ModalComponent {
  /**
   * Optional input, replaces the default cancel text with a given string.
   * @type {string}
   */
  @Input() public cancelText: string = 'Cancel';

  /**
   * Optional input, replaces the default confirmation text with a given string.
   * @type {string}
   */
  @Input() public confirmText: string = 'OK';

  /**
   * Optional input, if the confirm button is tied to an enable logic, it can be
   * given here.
   */
  @Input() public confirmDisabled = false;

  /**
   * Emits a new event whenever the user pressed the "confirm" button.
   * @type {EventEmitter<any>}
   */
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onConfirm: EventEmitter<any> = new EventEmitter<any>();

  /**
   * Emits a new event whenever the user pressed the "cancel" button.
   * @type {EventEmitter<any>}
   */
  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() public onCancel = new EventEmitter<any>();

  /**
   * Handles confirmation events. Hides the modal window and emits a new event to the outer component.
   * @param event
   */
  public onConfirmClicked(event: any): void {
    this.modal?.hide();
    this.onConfirm.emit(event);
  }

  /**
   * Handles cancellation events. Hides the modal window and emits a new event to the outer component.
   * @param event
   */
  public onCancelClicked(event: any): void {
    this.modal?.hide();
    this.onCancel.emit(event);
  }
}
