import {CommonModule} from '@angular/common';
import {ChangeDetectionStrategy, Component, computed, input} from '@angular/core';

@Component({
  selector: 'j4-alert',
  templateUrl: './j4-alert.component.html',
  styleUrls: ['./j4-alert.component.less'],
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class J4AlertComponent {
  // If is used bellow an input field in a form
  public isFormAddon = input(false);
  public type = input<'info' | 'warning' | 'success' | 'error'>('info');

  public url = input<string>();
  public urlText = input<string>('See the Article');
  public text = input<string>();

  protected readonly iconText = computed(() => {
    const defaultText = 'i';
    const text = {
      warning: '!',
      error: '!',
      success: '✓',
      info: 'i'
    };

    return text[this.type()] ?? defaultText;
  });
}
