import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {IPortalConfig, PORTAL_DATA} from '@jumio/portals.core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CacheService} from 'shared/services/common/cache.service';
import {MerchantEndpoints} from 'shared/services/endpoint-constants';
import {Merchant} from 'shared/services/merchant/merchant.dto';
import {MerchantSearchQuery, MerchantService} from 'shared/services/merchant/merchant.service';
import {SecurityContextHolder} from 'shared/services/security/security-context-holder';

@Injectable()
export class RiskManagementMerchantService extends MerchantService {
  constructor(
    protected override contextHolder: SecurityContextHolder,
    protected override http: HttpClient,
    protected override cache: CacheService,
    @Inject(PORTAL_DATA) protected override portalConfig: IPortalConfig
  ) {
    super(contextHolder, http, cache, portalConfig);
  }

  /**
   * Overridden search function for the proper autocomplete component population
   * @param query
   */
  public override search$(query: string): Observable<Array<Merchant>> {
    // For this specific autocomplete, the UI needs both the Name and Id to be shown inside the autocomplete component
    return this.post$<Array<Merchant>>(MerchantEndpoints.SEARCH, new MerchantSearchQuery(query)).pipe(
      map(merchants =>
        merchants.map(merchant => ({
          ...merchant,
          name: merchant.name + ` (${merchant.id}) `,
          id: merchant.id
        }))
      )
    );
  }
}
