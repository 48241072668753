import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CodeLabel} from 'public-shared/models/code-label/code-label.dto';
import {BaseService, EntityWithPassword} from 'public-shared/services/base-http/base.service';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {JumioPage} from 'shared/components/table/dto/jumio-page';
import {CacheService} from 'shared/services/common/cache.service';
import {J4UserEndpoints, UserEndpoints} from 'shared/services/endpoint-constants';
import {FilterService} from 'shared/services/filter.service';
import {MerchantUserDetail, MerchantUserFilter} from './merchant-user.dto';
import {UserBulkRequest, UserCsv, UserCsvResult, UserCsvResultItem} from './user-csv.dto';
import {AddUserFields, UserFields} from './user-fields.dto';
import {UserFilter} from './user-filter.dto';
import {
  J4UserAddRequest,
  J4UserDetail,
  J4UserInitResponse,
  J4UserUpdateGroupRequest,
  UserAddRequest,
  UserBulkOperationRequest,
  UserDetail,
  UserEditDetail,
  UserTenantState,
  UserUpdateRequest
} from './user.dto';

@Injectable()
export class UserService extends BaseService implements FilterService {
  private USER_FIELDS_CACHE_KEY = 'USER_FIELDS';
  private MERCHANT_FIELDS_CACHE_KEY = 'MERCHANT_FIELDS';

  constructor(protected override http: HttpClient, private cache: CacheService) {
    super(http);
    this.baseUrl = UserEndpoints.BASE;
  }

  /**
   * Receives the filter form fields from backend.
   */
  public getSearchFilterFields$(): Observable<UserFields> {
    const fn$ = this.get$<UserFields>(UserEndpoints.INIT);
    return this.cache.cacheFunction$<UserFields>(this.USER_FIELDS_CACHE_KEY, fn$);
  }

  /**
   * Receives the merchant-user filter form fields from backend.
   */
  public getMerchantSearchFilterFields$(): Observable<UserFields> {
    const fn$ = this.get$<UserFields>(UserEndpoints.INIT_MERCHANTS);
    return this.cache.cacheFunction$<UserFields>(this.MERCHANT_FIELDS_CACHE_KEY, fn$);
  }

  /**
   * Submits the filter form to backend.
   * After receives results, creates a JumioPage instance with the correct icons set.
   * @param filter The filter form.
   */
  public filter$(filter: Partial<UserFilter>): Observable<JumioPage<UserDetail>> {
    return this.post$<JumioPage<UserDetail>>(UserEndpoints.FILTER, filter).pipe(
      tap(result => {
        if (result.list) {
          const userFields = this.cache.hasKey(this.USER_FIELDS_CACHE_KEY) ? this.cache.getItem(this.USER_FIELDS_CACHE_KEY) : {};
          const statuses = CodeLabel.getCodeLabelMap(userFields.status);

          for (const item of result.list) {
            //@ts-ignore
            item.status = statuses[item.status] || item.status;
            item.registrationDate = item.registrationDate ? new Date(item.registrationDate) : undefined;
          }
        }
        return result;
      })
    );
  }

  public filterMerchantUsers$(filter: MerchantUserFilter): Observable<JumioPage<MerchantUserDetail>> {
    return this.post$<JumioPage<MerchantUserDetail>>(UserEndpoints.MERCHANT_FILTER, filter).pipe(
      tap(result => {
        if (result.list) {
          const merchantSearchFields = this.cache.hasKey(this.MERCHANT_FIELDS_CACHE_KEY)
            ? this.cache.getItem(this.MERCHANT_FIELDS_CACHE_KEY)
            : {};
          const statuses = CodeLabel.getCodeLabelMap(merchantSearchFields.status);

          for (const item of result.list) {
            //@ts-ignore
            item.status = statuses[item.status] || item.status;
          }
        }
        return result;
      })
    );
  }

  public initAddUser$(): Observable<AddUserFields> {
    return this.get$<AddUserFields>(UserEndpoints.INIT_ADD_USER);
  }

  public getUserDetails$(id: string): Observable<UserEditDetail> {
    return this.get$<UserEditDetail>(UserEndpoints.USER(id));
  }

  public modifyUserDetails$(
    id: string | undefined,
    updateRequest: UserUpdateRequest,
    password: string | undefined,
    passcode?: string
  ): Observable<void> {
    return this.put$<void>(UserEndpoints.USER(id), new EntityWithPassword(updateRequest, password, passcode));
  }

  public activateUser$(id: string | undefined, password: string | undefined, passcode?: string): Observable<void> {
    return this.post$<void>(UserEndpoints.ACTIVATE(id), new EntityWithPassword(null, password, passcode));
  }

  public deactivateUser$(id: string | undefined, password: string | undefined, passcode?: string): Observable<void> {
    return this.post$<void>(UserEndpoints.DEACTIVATE(id), new EntityWithPassword(null, password, passcode));
  }

  public resendEmail$(id: string | undefined, password: string | undefined, passcode?: string): Observable<void> {
    return this.post$<void>(UserEndpoints.RESEND(id), new EntityWithPassword(null, password, passcode));
  }

  public unlock$(id: string | undefined, password: string | undefined, passcode?: string): Observable<void> {
    return this.post$<void>(UserEndpoints.UNLOCK(id), new EntityWithPassword(null, password, passcode));
  }

  public resetTfa$(id: string | undefined, password: string | undefined, passcode?: string): Observable<void> {
    return this.post$<void>(UserEndpoints.RESET_TFA(id), new EntityWithPassword(null, password, passcode));
  }

  public addUser$(entity: UserAddRequest, password: string, passCode?: string): Observable<void> {
    return this.post$<void>(UserEndpoints.USER(), new EntityWithPassword(entity, password, passCode));
  }

  public bulkAddUser$(entity: UserAddRequest[] | undefined, password: string | undefined, passCode?: string): Observable<void> {
    return this.post$<void>(UserEndpoints.BULK_ADD, new EntityWithPassword(entity, password, passCode));
  }

  public parseCsv$(formData: FormData, action: string): Observable<UserCsv[]> {
    return this.post$<UserCsv[]>(UserEndpoints.PARSE_CSV(action), formData);
  }

  public performCsvUpdate$(entity: UserBulkRequest, action: string): Observable<UserCsvResultItem[]> {
    return this.post$<UserCsvResult>(UserEndpoints.BULK_CSV(action), entity).pipe(map(result => result.items || []));
  }

  public bulkDownload$(filter: Partial<UserFilter>): Observable<Blob> {
    return this.postCsv$(UserEndpoints.DOWNLOAD, filter);
  }

  public bulkActivate$(entity: UserBulkOperationRequest, password: string | undefined, passCode?: string): Observable<void> {
    return this.post$<void>(UserEndpoints.BULK_ACTIVATE, new EntityWithPassword(entity, password, passCode));
  }

  public bulkDeactivate$(entity: UserBulkOperationRequest, password: string | undefined, passCode?: string): Observable<void> {
    return this.post$<void>(UserEndpoints.BULK_DEACTIVATE, new EntityWithPassword(entity, password, passCode));
  }

  /*********************************************************************
   ************* J4 user management service functions ******************
   *********************************************************************/

  /**
   * Submits the filter form to J4 user list backend endpoint
   * @param filter
   */
  public filterJ4$(filter: Partial<UserFilter>): Observable<JumioPage<J4UserDetail>> {
    this.baseUrl = J4UserEndpoints.BASE;
    return this.post$<JumioPage<J4UserDetail>>(J4UserEndpoints.SEARCH, filter);
  }

  /**
   * Get init data
   */
  public initAddJ4User$(): Observable<J4UserInitResponse> {
    this.baseUrl = J4UserEndpoints.BASE;
    return this.get$(J4UserEndpoints.INIT);
  }

  /**
   *
   * @param entity
   */
  public bulkAddJ4User$(entity: J4UserAddRequest): Observable<void> {
    this.baseUrl = J4UserEndpoints.BASE;
    return this.post$<void>(J4UserEndpoints.USER, entity);
  }

  /**
   *
   * @param entity
   */
  public updateJ4UserGroup$(entity: J4UserUpdateGroupRequest): Observable<void> {
    this.baseUrl = J4UserEndpoints.BASE;
    return this.put$<void>(J4UserEndpoints.GROUPS, entity);
  }

  /**
   * Download user list in csv
   * @param filter
   */
  public bulkDownloadJ4User$(): Observable<Blob> {
    return this.postCsv$(J4UserEndpoints.DOWNLOAD, {});
  }

  /**
   * Enable/Disable User
   * @param enabled boolean
   * @param userId string
   */
  public upToggleEnable$(enabled: boolean, userId: string): Observable<void> {
    const state = enabled ? UserTenantState.ENABLED : UserTenantState.DISABLED;
    return this.post$<void>(J4UserEndpoints.TOGGLE_STATE(state, userId), null);
  }

  /**
   * Remove User
   * @param userId string
   */
  public upRemoveUser$(userId: string): Observable<void> {
    return this.post$<void>(J4UserEndpoints.TOGGLE_STATE(UserTenantState.REMOVED, userId), null);
  }
}
