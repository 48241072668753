<div class="auth-container">
  <div class="content-container">
    <div class="content-box">
      <ng-content></ng-content>
    </div>
  </div>
  <div class="footer-container">
    <div class="logo-row">
      <img class="logo" [src]="logoUrl" alt="Jumio Logo" />
    </div>
    <div class="legal-container">
      <div class="copyright-row" id="current-year">Copyright {{ currentYear }} Jumio, Inc. All rights reserved.</div>
      <div class="copyright-row">
        <div class="legal-container">
          <a
            class="text-link"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.jumio.com/legal-information/privacy-policy/jumio-corp-privacy-policy-for-online-services/"
            >Privacy Policy</a
          >
          and
          <a
            class="text-link"
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.jumio.com/legal-information/privacy-policy/data-protection-notice/"
            >Data Protection Notice</a
          >.
        </div>
      </div>
    </div>
  </div>
</div>
