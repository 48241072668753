import {AfterContentInit, Component, ContentChild, Input, Optional, TemplateRef} from '@angular/core';
import {AbstractControl, NgForm, NgModel, NgModelGroup, ValidationErrors} from '@angular/forms';
import {FormGroupConstants} from 'public-shared/components/abstract-form/form-group.constants';
import {numberSizeValidator} from 'public-shared/components/validators/number-size-validator';

@Component({
  template: ''
})
export abstract class AbstractFormGroupComponent implements AfterContentInit {
  @Input() public cssClasses = [];

  /**
   * The label string which will appear in the form group.
   */
  @Input() public label = '';

  /**
   * Customizable error messages input that will be displayed instead of the default error messages.
   */
  @Input() public errorMessages: {[key: string]: string} | undefined;

  /**
   * If true, the form group will appear as a filter form group.
   */
  @Input() public filter: boolean = false;

  @Input() public resetButtonVisible = true;

  @Input() public errorId: string | undefined;

  /**
   * True, if the form group has a model which is required.
   */

  /**
   * If the form sets a default value for this model, this value will hold it.
   */
  @Input() public defaultValue: any;

  /**
   * Does this input requires a custom validation flow
   */
  @Input() public customValidation: boolean = false;

  /**
   * List of custom validators
   */
  @Input() public customValidators: FormGroupCustomValidatorsInput | undefined;

  /**
   * The Angular Model of this current form group.
   */
  @ContentChild(NgModel) public model: NgModel | undefined;

  /**
   * If provided in a subcomponent, this template will be used instead to display the form group errors instead of the default one.
   */
  @ContentChild('errorsTemplate') public errorsTemplate: TemplateRef<any> | undefined;
  private readonly defaultRequiredErrorLabel = 'This field';
  public required: boolean | undefined;

  protected constructor(public form: NgForm, @Optional() public modelGroup: NgModelGroup) {}
  /**
   * Determines whether an NgModel has a required validator or not.
   * This helper function is necessary since Angular does not support reading the validators of FormControls.
   */
  private static isRequired(model: NgModel): boolean {
    if (model.validator) {
      const validator = model.validator({} as AbstractControl);
      //@ts-ignore
      return Boolean(validator) && Boolean(validator['required']);
    }
    return false;
  }

  /**
   * Default constructor. Injects the parent form using the {@link NgForm} provider.
   * @param {NgForm} form The default Angular provider for building forms.
   * @param modelGroup
   */

  public get formControlTouched(): boolean {
    //@ts-ignore
    return Boolean(this.model) && Boolean(this.form) && this.form.getControl(this.model) ? this.form.getControl(this.model).touched : false;
  }

  public get formControlDirty(): boolean {
    //@ts-ignore
    return Boolean(this.model) && Boolean(this.form) && this.form.getControl(this.model) ? this.form.getControl(this.model).dirty : false;
  }

  /**
   * Returns the errors of the current form control's errors.
   * @returns {ValidationErrors} The object holding the form control's errors.
   */
  public get formControlErrors(): ValidationErrors | null {
    if (!this.model) {
      return null;
    }
    return Boolean(this.model) && Boolean(this.form) && this.form.getControl(this.model)
      ? this.form.getControl(this.model).errors
      : this.model.errors;
  }

  public get modelGroupErrors(): ValidationErrors | null {
    return Boolean(this.model) &&
      Boolean(this.modelGroup) &&
      Boolean(this.modelGroup.control) &&
      //@ts-ignore
      this.modelGroup.control.controls[this.model.name]
      ? //@ts-ignore
        this.modelGroup.control.controls[this.model.name].errors
      : null;
  }

  public get formControlErrorCodes(): Array<string> {
    return this.formControlErrors ? Object.keys(this.formControlErrors) : [];
  }

  /**
   * Indicates whether the form group has any kind of errors,
   * and the form group has been touched by the user or the parent form has been submitted.
   * @returns {boolean} True, if the form group has any kind of errors.
   */
  public get hasError(): boolean | null {
    // TODO fix condition for modelGroupErrors. In that case form control doesn't need to be touched.
    // in order to get error messages we need to amend this.formControlErrorCodes to support modelGroupErrors as well.
    // see the ticket TI-559

    if (!this.model) {
      return false;
    }

    return (
      ((Boolean(this.formControlErrors) || Boolean(this.modelGroupErrors)) &&
        (this.formControlTouched || this.formControlDirty || this.form.submitted)) ||
      (!this.model.valid && this.model.touched)
    );
  }

  /**
   * Depending on the type of the form group (normal/filter), returns the parent CSS class.
   * @returns {string}
   */
  public get parentCssClass(): string {
    return `${this.cssClasses.join(' ')} ${this.filter ? 'input-component' : 'form-group detail'}`;
  }

  /**
   * Depending on the type of the form group (normal/filter), returns the input div's CSS class.
   * @returns {string}
   */
  public get inputCssClass(): string {
    return this.filter ? 'input-fields' : 'input-field';
  }

  public get errorMessageId(): string {
    return this.errorId ? this.errorId + '-error' : '';
  }

  /**
   * Executes after the content given in the <ng-content> tag has been initialized.
   * Runs an async microtask to make for a verification loop.
   * Then, copies the validators of the current model to the form's controls.
   * This is necessary for notifying the parent component about an invalid child control.
   */
  ngAfterContentInit(): void {
    Promise.resolve(null).then(() => {
      if (this.model) {
        const validators = [this.model.validator];

        // If the form has any kind of custom validation logic while having 'custom-validation' type
        if (this.customValidation) {
          // Adding the custom validation logic one by one
          //@ts-ignore
          Object.keys(this.customValidators).forEach(validationKey => {
            switch (validationKey) {
              case 'numberSize':
                //@ts-ignore
                validators.push(numberSizeValidator(this.customValidators[validationKey].min, this.customValidators[validationKey].max));
                break;
            }
          });
        }

        if (this.form.getControl(this.model) && this.model.validator) {
          //@ts-ignore
          this.form.getControl(this.model).setValidators(validators);
          this.required = AbstractFormGroupComponent.isRequired(this.model);
        }

        if (this.modelGroup && this.modelGroup.control.controls[this.model.name] && this.model.validator) {
          //@ts-ignore
          this.modelGroup.control.controls[this.model.name].setValidators(validators);
          this.required = AbstractFormGroupComponent.isRequired(this.model);
        }
      }
    });
  }

  /**
   * If the model had a default value, reset to that.
   * Otherwise, reset to null.
   */
  public resetModelValue(): void {
    //@ts-ignore
    this.form.getControl(this.model).setValue(this.defaultValue || null);
  }

  /**
   * Returns the error message given a specific error code.
   * If there is a custom error messages input defined, it will be used instead of the default error messages.
   * @param {string} errorCode The current error's code.
   * @returns {string} The message that will be displayed in the default error template.
   */
  public errorMessage(errorCode: string): string {
    // If the error is not boolean type, it means that a custom message was given for this specific error
    // In this case, display this error message (for example, when 400 bad request error happens)
    //@ts-ignore
    if (this.formControlErrorCodes && typeof this.formControlErrorCodes[errorCode] === 'string') {
      //@ts-ignore
      return this.formControlErrorCodes[errorCode];
    }

    if (this.errorMessages && this.errorMessages[errorCode]) {
      return this.errorMessages[errorCode];
    }
    switch (errorCode) {
      case 'required':
        return FormGroupConstants.REQUIRED_ERROR(this.label || this.defaultRequiredErrorLabel);
      case 'email':
        return FormGroupConstants.EMAIL_ERROR;
      case 'pattern':
        return FormGroupConstants.PATTERN_ERROR;
      case FormGroupConstants.BACKEND_VALIDATION_ERROR:
        return this.formControlErrors ? this.formControlErrors[errorCode] : FormGroupConstants.OTHER_BACKEND_ERROR;
      default:
        return FormGroupConstants.OTHER_ERROR;
    }
  }
}

export interface INumberSizeValidator {
  min: number;
  max: number;
}

export type FormGroupCustomValidatorsInput = Record<string, Record<string, unknown>> & {numberSize: INumberSizeValidator};
