import {ComponentRef, Injectable, ViewContainerRef} from '@angular/core';
import {ServiceUnavailableComponent} from './service-unavailable.component';

@Injectable({providedIn: 'root'})
export class ServiceUnavailableService {
  private anchor: ViewContainerRef | null = null;
  private component: ComponentRef<ServiceUnavailableComponent> | null = null;
  public register(viewContainerRef: ViewContainerRef | undefined): void {
    if (!viewContainerRef) {
      return;
    }
    this.anchor = viewContainerRef;
  }

  public show(): void {
    if (this.anchor) {
      this.component = this.anchor.createComponent(ServiceUnavailableComponent);
    } else {
      throw new Error('You have to register an anchor to use the ServiceUnavailableService');
    }
  }

  public hide(): void {
    this.component?.destroy();
    this.component = null;
  }
}
