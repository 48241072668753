import {HttpBackend, HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable, inject} from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {BaseEndpoints} from '../../constants/cp-facade-endpoints.constants';
import {DD_BASE_API_URL, PortalDatadogIdentifier} from '../../constants/datadog-init.constants';
import {IInternalDataDogConfig} from '../../interfaces/config.interface';

@Injectable()
export class ConfigService {
  private baseUrl: string = inject(DD_BASE_API_URL);

  // Bypass external HttpInterceptor, keeping the url logic coupled in Datadog lib
  protected handler = inject(HttpBackend);
  private http = new HttpClient(this.handler);

  public datadogConfig$(portalIdentifier: PortalDatadogIdentifier): Observable<Partial<IInternalDataDogConfig>> {
    return this.get$<Partial<IInternalDataDogConfig>>(BaseEndpoints.INIT_DATADOG_CONFIG, undefined, {version: portalIdentifier}).pipe(
      catchError(() => of({enabled: false}))
    );
  }

  private get$<T>(url: string, headers?: HttpHeaders, params?: HttpParams | {[param: string]: string | string[]}): Observable<T> {
    return this.http.get<T>(this.baseUrl + url, {headers, params});
  }
}
