import {NgModule} from '@angular/core';
import {SafeJsonPipe} from './json.pipe';
import {PrettyJsonComponent} from './prettyjson.component';
import {PrettyJsonPipe} from './prettyjson.pipe';
import {TextToJsonPipe} from './text-to-json.pipe';

/*
 * This is a fork of the module https://www.npmjs.com/package/angular2-prettyjson
 * Was migrated as an ui component to this project to solve angular upgrades issues
 * */

@NgModule({
  declarations: [PrettyJsonComponent, PrettyJsonPipe, SafeJsonPipe, TextToJsonPipe],
  exports: [PrettyJsonComponent, PrettyJsonPipe, SafeJsonPipe, TextToJsonPipe]
})
export class PrettyJsonModule {}
