<app-modal [title]="title" (onClose)="onClose.emit()" #modal>
  <ng-content></ng-content>
  <div class="modal-footer">
    <button
      data-cy="modal-confirmation-cancel-btn"
      type="button"
      class="button btn btn-cancel pull-left"
      aria-label="Close"
      (click)="onCancelClicked($event)"
    >
      {{ cancelText }}
    </button>
    <button
      data-cy="modal-confirmation-confirm-btn"
      type="button"
      class="btn btn-primary button button-primary pull-right"
      appDebounceClick
      [disabled]="confirmDisabled"
      aria-label="Confirm"
      (click)="onConfirmClicked($event)"
    >
      {{ confirmText }}
    </button>
  </div>
</app-modal>
