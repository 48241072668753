<div class="cookie-consent-bar" *ngIf="showCookieConsentPopup">
  <div class="cookie-alert-wrapper">
    <div class="cookies-alert">
      <div class="cookies-alert-consent">
        <div class="consent-text">
          May we use cookies to track your activities? We take your privacy very seriously. Please see our
          <a [href]="privacyPolicyUrl" target="_blank">privacy policy</a> for details and any questions.
        </div>
        <div class="button-bar">
          <button data-testid="cookies-consent-button-no" class="button" (click)="decline()">No</button>
          <button data-testid="cookies-consent-button-yes" class="button mgr" (click)="accept()">Yes</button>
        </div>
      </div>
    </div>
  </div>
</div>
