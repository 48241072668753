import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CodeLabel} from 'public-shared/models/code-label/code-label.dto';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {TryNowEndpoints} from 'shared/services/endpoint-constants';
import {IdVerification, IdVerificationFields} from 'shared/services/verification/create-verification.dto';
import {BaseTryNowService} from './base-try-now.service';

@Injectable()
export class TryNowService extends BaseTryNowService {
  constructor(protected override http: HttpClient) {
    super(http);
    this.baseUrl = TryNowEndpoints.BASE;
  }

  public getOtherDocumentTypes$(): Observable<CodeLabel[]> {
    return this.get$<{documentTypes: CodeLabel[]}>(TryNowEndpoints.OTHER).pipe(map(res => res.documentTypes));
  }

  public getIdVerificationUrl$(): Observable<string> {
    //@ts-ignore
    return this.get$<RedirectUrl>(TryNowEndpoints.ID).pipe(map(res => res.redirectUrl));
  }

  public getMultiDocumentUrl$(documentType: string): Observable<string> {
    //@ts-ignore
    return this.post$<RedirectUrl>(TryNowEndpoints.MULTI_DOCUMENT, {
      documentType
    }).pipe(map(res => res.redirectUrl));
  }

  public getDocumentVerificationUrl$(countryCode: string, documentType: string): Observable<string> {
    //@ts-ignore
    return this.post$<RedirectUrl>(TryNowEndpoints.DOCUMENT, {
      country: countryCode,
      documentType
    }).pipe(map(res => res.redirectUrl));
  }

  // Extended Methods

  public getCreateInit$(): Observable<IdVerificationFields> {
    return this.get$<IdVerificationFields>(TryNowEndpoints.INIT);
  }

  public create$(entity: IdVerification): Observable<any> {
    return this.post$<any>(TryNowEndpoints.CREATE_ID_VERIFICATION, entity);
  }
}

class RedirectUrl {
  public redirectUrl: string | undefined;
}
