import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseService} from 'public-shared/services/base-http/base.service';
import {Observable} from 'rxjs';
import {RiskManagementEndpoints} from 'shared/services/endpoint-constants';
import {RiskScore, RiskScoreFilter, RiskScoreUpdateRequest} from '../../interfaces/risk-score.dto';

@Injectable()
export class RiskManagementService extends BaseService {
  constructor(protected override http: HttpClient) {
    super(http);
  }

  public getRiskScoreList$(): Observable<Array<RiskScore>> {
    return this.get$<Array<RiskScore>>(RiskManagementEndpoints.RISK_MANAGEMENT);
  }

  public addRiskScore$(publicId: string, riskScore: RiskScoreFilter): Observable<Array<RiskScore>> {
    return this.post$<Array<RiskScore>>(RiskManagementEndpoints.ADD_RISK_SCORE(publicId), riskScore);
  }

  public editRiskScores$(publicId: string, body: RiskScoreUpdateRequest): Observable<Array<RiskScore>> {
    return this.post$<Array<RiskScore>>(RiskManagementEndpoints.EDIT_RISK_SCORE(publicId), body);
  }

  public deleteRiskScore$(publicId: string, riskScore: RiskScoreFilter): Observable<Array<RiskScore>> {
    return this.post$<Array<RiskScore>>(RiskManagementEndpoints.DELETE_RISK_SCORE(publicId), riskScore);
  }
}
