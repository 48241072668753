import {CustomerEnvironmentData} from '@jumio/portals.core';
/**
 * Production environment configuration.
 */

export const environment: IJ4AuthEnvironmentData = {
  production: true,
  apiUrl: () => window.location.origin.concat('/api/'),
  mainUrl: '/login/',
  deployUrl: '/static/login/',
  idleInterval: 15 * 60 * 1000,
  idleIntervalEnabled: true,
  infoComponentDuration: 8000,
  animationEnabled: true,
  scrollToTopEnabled: true,
  handleUnavailableServiceError: true,
  // CustomerEnvironmentData items:
  menuItems: [],
  isJ4CustomerPortalEnabled: true,
  loginUrl: ''
};

export interface IJ4AuthEnvironmentData extends CustomerEnvironmentData {
  mainUrl: string;
}
