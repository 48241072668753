export enum DatadogAppNames {
  // AP
  ADMIN_PORTAL = 'admin-ui-client',

  // CP
  CUSTOMER_PORTAL = 'customer-ui-client',
  CUSTOMER_AUTH_PORTAL = 'customer-auth-ui-client',

  // Unified Portal
  UP = 'j4-unified-portal',
  UP_AUTH = 'j4-portal-auth',
  UP_WELCOME = 'j4-portal-welcome',
  UP_EXPLORER = 'j4-portal-explorer',
  UP_RULES = 'j4-portal-rules',

  // UP app name
  UP_GRAPH = 'j4-portal-graph',

  UP_GROUPS = 'j4-portal-groups',
  UP_USERS = 'j4-portal-users',
  UP_IDP = 'j4-portal-idp',
  UP_REPORTS = 'j4-portal-reports',
  UP_SETTINGS = 'j4-portal-settings',
  UP_SUBPROCESSORS = 'j4-portal-subprocessors',
  UP_VERIFICATIONS = 'j4-portal-verifications',
  UP_VERIFICATION_REPORTS = 'j4-portal-verification-reports',
  UP_DASHBOARD = 'j4-portal-dashboard',

  // Shared components
  SHARED = 'portal-shared-lib'
}
