import {TOKEN_CLAIMS} from '../../constants/shared.constants';

type ParsedTokenType = Record<string, string>;
export type CookiesClaimType = 'true' | 'false';

/**
 * Returns a JS object representation of a Javascript Web Token from its common encoded
 * string form.
 *
 * @template T the expected shape of the parsed token
 * @param {string} token a Javascript Web Token in base64 encoded, `.` separated form
 * @returns {(T | undefined)} an object-representation of the token
 * or undefined if parsing failed
 */
export const getParsedJwt = (token: string): ParsedTokenType | undefined => JSON.parse(atob(token.split('.')[1]));

export const getCookiesClaimFromToken = (token: string): CookiesClaimType | undefined => {
  const parsedToken = getParsedJwt(token) || {};
  return parsedToken[TOKEN_CLAIMS.COOKIES] as CookiesClaimType | undefined;
};
