import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LoggerEndpoints} from 'public-shared/models/endpoints/logger-endpoint.constants';
import {ErrorDescriptor} from 'public-shared/models/error/error-descriptor';
import {Observable} from 'rxjs';
import {BaseService} from '../base-http/base.service';
import {LogRequest} from './log-request.dto';

/**
 * The service responsible for sending logs to the server.
 */
@Injectable()
export class LoggerService extends BaseService {
  constructor(protected override http: HttpClient) {
    super(http);
    this.baseUrl = LoggerEndpoints.BASE;
  }

  /**
   * Add new error
   * @returns void.
   * @param error
   */
  public addError$(error: ErrorDescriptor): Observable<void> {
    return this.post$<void>(LoggerEndpoints.ERROR, LogRequest.error(error));
  }

  /**
   * Add new info
   * @returns void.
   * @param info
   */
  public addInfo$(info: string): Observable<void> {
    return this.post$<void>(LoggerEndpoints.INFO, LogRequest.simple(info));
  }

  /**
   * Add new warn
   * @returns void.
   * @param warning
   */
  public addWarn$(warning: string): Observable<void> {
    return this.post$<void>(LoggerEndpoints.WARN, LogRequest.simple(warning));
  }
}
