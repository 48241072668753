import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseService} from 'public-shared/services/base-http/base.service';
import {EMPTY, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {JumioPage} from 'shared/components/table/dto/jumio-page';
import {FilterService} from 'shared/services/filter.service';
import {
  KYXTransaction,
  KYXTransactionEventLog,
  KYXTransactionFilter,
  KYXTransactionFilterInit,
  KYXTransactionSearchResponse
} from 'shared/services/kyx-transactions/kyx-transactions.dto';
import {EventLog} from 'shared/services/verification/details-dto/events-log.dto';
import {KYXTransactionsEndpoints} from '../endpoint-constants';

@Injectable()
export class KYXTransactionsService extends BaseService implements FilterService {
  public FILTER_CACHE_KEY = 'KYX_TRANSACTION_FILTER';

  constructor(protected override http: HttpClient) {
    super(http);
    this.baseUrl = KYXTransactionsEndpoints.BASE;
  }

  public getDetails$(id: string): Observable<KYXTransaction> {
    return this.get$<KYXTransaction>(`${KYXTransactionsEndpoints.TRANSACTION}/${id}`);
  }

  public deleteTransaction$(id: string): Observable<void> {
    return this.delete$<void>(`${KYXTransactionsEndpoints.TRANSACTION}/${id}`);
  }

  public getDetailImage$(href: string): Observable<any> {
    return this.get$<any>(href);
  }

  public initFilter$(): Observable<KYXTransactionFilterInit> {
    return this.get$<KYXTransactionFilterInit>(KYXTransactionsEndpoints.INIT);
  }

  /**
   * Required for IFilterService
   * Sending the filter values to the backend, receiving the filtered results
   * @param filter
   */
  public filter$(filter: Partial<KYXTransactionFilter>): Observable<JumioPage<KYXTransactionSearchResponse>> {
    return this.post$<JumioPage<KYXTransactionSearchResponse>>(
      KYXTransactionsEndpoints.TRANSACTION + KYXTransactionsEndpoints.SEARCH,
      filter
    ).pipe(map(result => ({list: result.list, total: result.total} as any)));
  }

  public search$(): Observable<never> {
    return EMPTY;
  }

  /**
   * Receives the array of events log details of a given transaction from the server.
   * @param {string} The ID of the given transaction.
   * @returns {Observable<EventLog[]>} An Observable of the array of event log elements.
   */
  public getEventsLog$(transactionId: string): Observable<KYXTransactionEventLog[]> {
    return this.get$<EventLog[]>(KYXTransactionsEndpoints.EVENTS_LOG(transactionId));
  }
}
