import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseService, EntityWithPassword} from 'public-shared/services/base-http/base.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {QrCodeContainer} from 'shared/services/credentials/demo-app-url.dto';
import {TransactionCreation} from 'shared/services/credentials/transaction-creation.dto';
import {CredentialsBmuiEndpoints} from 'shared/services/endpoint-constants';

@Injectable()
export class CredentialsBmuiService extends BaseService {
  constructor(protected override http: HttpClient) {
    super(http);
    this.baseUrl = CredentialsBmuiEndpoints.BASE;
  }

  public getSecret$(): Observable<TransactionCreation> {
    return this.get$(CredentialsBmuiEndpoints.GET_SECRET);
  }

  public generateSecret$(): Observable<TransactionCreation> {
    return this.get$(CredentialsBmuiEndpoints.GENERATE_SECRET);
  }

  public activateSecret$(secret: TransactionCreation): Observable<TransactionCreation> {
    return this.post$(CredentialsBmuiEndpoints.ACTIVATE_SECRET, secret);
  }

  public saveSecret$(secret: TransactionCreation, password: string): Observable<TransactionCreation> {
    return this.post$(CredentialsBmuiEndpoints.SAVE_SECRET, new EntityWithPassword(secret, password));
  }

  public getDemoAppQrCode$(dataCenter: string): Observable<string> {
    //@ts-ignore
    return this.post$<QrCodeContainer>(CredentialsBmuiEndpoints.DEMO_APP_URL, {dataCenter}).pipe(map(res => res.qrCode));
  }
}
