import {HttpClient} from '@angular/common/http';
import {BaseService} from 'public-shared/services/base-http/base.service';
import {Observable} from 'rxjs';
import {ApiTokenActivation, ApiTokenCreation, ApiTokenDeactivation, IApiTokens} from './api-tokens.dto';

export abstract class BaseCredentialsService extends BaseService {
  constructor(protected override http: HttpClient) {
    super(http);
    // this.baseUrl needs to be set on child service
  }

  public abstract getApiTokens$(): Observable<IApiTokens>;

  public abstract activateApiToken$(_request: ApiTokenActivation, _password: string, _passcode?: string): Observable<any>;

  public abstract deactivateApiToken$(_request: ApiTokenDeactivation, _password: string, _passcode?: string): Observable<any>;

  public abstract createApiToken$(_request: ApiTokenCreation, _password: string, _passcode?: string): Observable<any>;
}
