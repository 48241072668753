import {CommonModule} from '@angular/common';
import {AfterViewInit, Component, forwardRef, Input, Optional, TemplateRef, ViewChild} from '@angular/core';
import {ControlValueAccessor, NgForm, NgModel, NgModelGroup, NG_VALUE_ACCESSOR, ValidationErrors, FormsModule} from '@angular/forms';
import {FormGroupConstants} from 'public-shared/components/abstract-form/form-group.constants';
import {NaturalNumberValidatorDirective} from './natural-number-validator.directive';
import {IsEnabledDirective} from '../security/is-enabled.directive';

@Component({
  selector: 'app-natural-number-input',
  templateUrl: './natural-number-input.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NaturalNumberInputComponent),
      multi: true
    }
  ],
  standalone: true,
  imports: [FormsModule, CommonModule, NaturalNumberValidatorDirective, IsEnabledDirective]
})
/**
 * A form input component that accepts only natural number inputs.
 * If the pasted number is incorrect, sets a form validation error.
 */
export class NaturalNumberInputComponent implements ControlValueAccessor, AfterViewInit {
  @Input() public appIsEnabled: string | undefined;
  @Input() public name: string | undefined;
  @Input() public placeholder: string | undefined;
  @Input() public disabled: boolean = false;
  @Input() public required = false;
  @Input() public max: number | undefined;
  @Input() public min = 0;
  @Input() public maxLength: number | null = null;
  @Input() public hasAddon: any;
  @Input() public placeholderHidden: any;
  @ViewChild('errorsTemplate') public errorsTemplate: TemplateRef<any> | undefined;
  @ViewChild(NgModel) public model: NgModel | undefined;

  private readonly NUMBER_REGEX = new RegExp('^[0-9]*$');
  private viewInitialized: boolean | undefined;
  public errors: ValidationErrors | null | undefined;
  public value: any;

  constructor(@Optional() public ngForm: NgForm, @Optional() private ngModelGroup: NgModelGroup) {}

  public propagateChange: any = () => {};
  public propagateTouch: any = () => {};

  /**
   * Runs when the input view has been initialized.
   */
  ngAfterViewInit(): void {
    this.viewInitialized = true;
  }

  /**
   * After validation has finished, receives the errors from the Validator (if there was any).
   * If the view has already initialized, propagates the value to the parent component and sets the errors of the form control.
   * @param event
   */
  public onValidated(event: any): void {
    this.errors = event;
    if (this.viewInitialized) {
      this.propagateChange(parseInt(this.value, 10));
      this.setErrors();
    }
  }

  /**
   * Propagates the value to the parent component and emits an "change" event.
   * @param event
   */
  public onChange(_event: any): void {
    this.propagateChange(parseInt(this.value, 10));
  }

  /**
   * Tests the currently pressed keyboard event if should be allowed or not.
   * @param {KeyboardEvent} event
   */
  public onKeyDown(event: KeyboardEvent): void {
    // Allow: delete, backspace, home, end, left, right
    if (
      ['Delete', 'Backspace', 'Home', 'End', 'ArrowLeft', 'ArrowRight', 'Enter', 'Tab'].indexOf(event.code) !== -1 ||
      // Allow: Ctrl+A
      (event.code === 'KeyA' && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+C
      (event.code === 'KeyC' && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+V
      (event.code === 'KeyV' && (event.ctrlKey || event.metaKey)) ||
      // Allow: Ctrl+X
      (event.code === 'KeyX' && (event.ctrlKey || event.metaKey))
    ) {
      // let it happen, don't do anything
      return;
    }
    if (this.NUMBER_REGEX.test(event.key)) {
      return;
    } else {
      event.preventDefault();
    }
  }

  /**
   * On blur event, propagates value to the parent component and sets the errors in the containing form.
   */
  public onBlur(): void {
    this.propagateTouch(parseInt(this.value, 10));
    this.setErrors();
  }

  /**
   * Receives a new value and writes it to the component's model value.
   */
  public writeValue(newValue: number): void {
    if (newValue !== undefined && !isNaN(newValue)) {
      this.value = newValue;
    }
  }

  /**
   * Registers for the onChange hook for this component.
   */
  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /**
   * Registers for the onTouche hook for this component.
   * @param fn
   */
  public registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  /**
   * Sets the input's disabled state from the parent component.
   * @param {boolean} disabled
   */
  public setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  public setErrors(): void {
    if (this.ngForm && this.model && this.ngForm.getControl(this.model)) {
      //@ts-ignore
      this.ngForm.getControl(this.model).setErrors(this.errors);
    } else if (this.ngModelGroup && this.model && this.ngModelGroup.control.controls[this.model.name]) {
      //@ts-ignore
      this.ngModelGroup.control.controls[this.model.name].setErrors(this.errors);
    }
  }

  /**
   * Reads backend validation error from parent form, and returns it if it exists.
   * This is necessary since this component uses a custom error template instead of the
   * default one described in FormGroupComponent.
   */
  public get backendValidationError(): string {
    return this.ngForm &&
      this.model &&
      this.ngForm.getControl(this.model) &&
      this.ngForm.getControl(this.model).errors &&
      //@ts-ignore
      this.ngForm.getControl(this.model).errors[FormGroupConstants.BACKEND_VALIDATION_ERROR]
      ? //@ts-ignore
        this.ngForm.getControl(this.model).errors[FormGroupConstants.BACKEND_VALIDATION_ERROR]
      : null;
  }
}
