import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import * as moment from 'moment';
import {ClipboardService} from 'ngx-clipboard';
import {InfoService} from 'public-shared/services/info/info.service';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {SmartCanvasBaseImage} from 'shared/components/smart-canvas/smart-canvas-base-image';
import {humanize} from 'shared/helpers/text.helper';
import {CapabilityMapped, Credential, CredentialCategory, Part, PartClassifier, WorkflowDetail} from './workflow-executions.interfaces';

export class SmartCanvasBaseImageWorkflow extends SmartCanvasBaseImage {
  public href: string | undefined;
  public override uniqueId: any;
  public parentKey?: string;
}

@Injectable()
export class WorkflowExecutionsHelperService {
  constructor(
    private clipboardService: ClipboardService,
    private infoService: InfoService,
    private sanitizer: DomSanitizer,
    private http: HttpClient
  ) {}

  private static getImageNote(part: Part): string {
    if (!part.uploadedAt && !part.wipedOutAt) {
      return '';
    }
    const action = part.uploadedAt ? 'Uploaded ' : 'Wiped out ';
    return `${action} ${moment.utc(part.uploadedAt || part.wipedOutAt).format('yyyy-MM-DD HH:mm:ss')}`;
  }

  private static capabilityWeight(capability: CapabilityMapped): number {
    return !capability.data || !capability.data?.length ? 0 : 1;
  }

  private static credentialWeight(credential: Credential): number {
    const weights = {
      [CredentialCategory.ID]: 4,
      [CredentialCategory.SELFIE]: 3,
      [CredentialCategory.FACEMAP]: 2,
      [CredentialCategory.DOCUMENT]: 1
    };
    return weights[credential.category as keyof typeof weights] || 0;
  }

  public static getArrayFromStringList(stringList: string): Array<string> {
    return stringList.split(',');
  }

  public copyToClipboard(event: MouseEvent, id: string): void {
    event.stopPropagation();
    this.clipboardService.copyFromContent(id);
    this.infoService.show(`Id copied to clipboard!`);
  }

  // Sort capabilities showing first the ones with no data
  public sortCapabilities(capabilities: CapabilityMapped[] = []): CapabilityMapped[] {
    return capabilities.sort((firstEl, secondEl) => {
      const firstElWeight = WorkflowExecutionsHelperService.capabilityWeight(firstEl);
      const secondElWeigth = WorkflowExecutionsHelperService.capabilityWeight(secondEl);

      return firstElWeight - secondElWeigth;
    });
  }

  // Sort credentials showing first the ones with bigger weight
  public sortCredentials(credentials: Credential[] = []): Credential[] {
    return credentials.sort((firstEl, secondEl) => {
      const firstElWeight = WorkflowExecutionsHelperService.credentialWeight(firstEl);
      const secondElWeigth = WorkflowExecutionsHelperService.credentialWeight(secondEl);

      return secondElWeigth - firstElWeight;
    });
  }

  public imageSources(cred: Credential, images: SmartCanvasBaseImageWorkflow[]): SmartCanvasBaseImageWorkflow[] {
    const cleanedParts = this.filterParts(cred.parts);

    // Select only first liveness to show on Facemap Credential Section
    const parts = cred.category === CredentialCategory.FACEMAP ? cleanedParts.slice(0, 1) : cleanedParts;
    const partsHref = parts.map(p => p.href);

    //@ts-ignore
    return images.filter(i => partsHref.includes(i.href));
  }

  public calculateSelectedIndex(imageIndex: number, credentialId: string, workflow: WorkflowDetail): number {
    const credentialsWithImages = workflow.credentials.filter(c => c.parts?.length);
    const currentCredentialIndex = credentialsWithImages.findIndex(c => c.id === credentialId);
    // Calculate which modal page to show
    return currentCredentialIndex + imageIndex;
  }

  public mapPartsToSmartImages$(parts: Part[] = []): Observable<SmartCanvasBaseImageWorkflow | undefined>[] {
    const filteredParts = this.filterParts(parts);

    return filteredParts.map(p =>
      this.http.get<{binary: string; mimeType: string}>(p.href.replace('/api/', '')).pipe(
        // Make sure observable returns something in case of error
        catchError(() => of(null)),
        map(resp => {
          if (resp && resp.binary) {
            const base64 = this.getSafeUrl(`data:${resp.mimeType};base64,${resp.binary}`);
            const image = new SmartCanvasBaseImageWorkflow(base64, humanize(p.classifier));
            image.href = p.href;
            image.stepId = p.stepId;
            image.note = WorkflowExecutionsHelperService.getImageNote(p);
            // Since multiple rows would start from the same uniqueId index, label is the reliable unique id in this case
            image.uniqueId = image.label;
            image.parentKey = p.parentKey;
            image.annotations = p.imageAnnotations;
            return image;
          }
          throw new Error(`No binary data in the response from ${p.href.replace('/api/', '')}`);
        })
      )
    );
  }

  public fetchImages$(parts: Part[] = []): Observable<SafeUrl>[] {
    const filteredParts = this.filterParts(parts);

    return filteredParts.map(p =>
      this.http.get<{binary: string; mimeType: string}>(p.href.replace('/api/', '')).pipe(
        // Make sure observable returns something in case of error
        catchError(() => of(null)),
        map(resp => {
          if (resp && resp.binary) {
            const base64 = this.getSafeUrl(`data:${resp.mimeType};base64,${resp.binary}`);
            //@ts-ignore
            return base64['changingThisBreaksApplicationSecurity'];
          }
        })
      )
    );
  }

  // Filter out wipedOut images, FACEMAP images AND DV Pdf ORIGIN file
  public filterParts(parts: Part[] = []): Part[] {
    return parts.filter(p => !p.wipedOutAt && p.classifier !== PartClassifier.FACEMAP && p.classifier !== PartClassifier.ORIGIN);
  }

  private getSafeUrl(url: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
