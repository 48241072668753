import {BaseEndpoints} from 'public-shared/models/endpoints/base-endpoint.constants';

export class BaseAuthEndpoints {
  public static readonly BASE = 'external/ac/user/';
  public static readonly ACCESS_TOKEN = 'accesstoken/';
  public static readonly EMAIL_VERIFICATION = BaseEndpoints.V1 + 'email-verification';
  public static readonly EMAIL_CHANGE_VERIFICATION = BaseAuthEndpoints.EMAIL_VERIFICATION + '/emailChanged';
  public static readonly EMAIL_VERIFICATION_KEY = (key: string): string => BaseAuthEndpoints.EMAIL_VERIFICATION + '/' + key;
}

export class TokenValidationEndpoints {
  public static readonly BASE = 'external/ac/';
  public static readonly TOKEN_VALIDATION = 'verifyAccessToken/';
}
