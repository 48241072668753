import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {IPortalConfig, PORTAL_DATA, PortalApiType} from '@jumio/portals.core';
import {BaseEndpoints} from 'public-shared/models/endpoints/base-endpoint.constants';
import {BaseService, EntityWithPassword} from 'public-shared/services/base-http/base.service';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {JumioPage} from 'shared/components/table/dto/jumio-page';
import {CacheService} from 'shared/services/common/cache.service';
import {J4CustomerEndpoints} from 'shared/services/endpoint-constants';
import {FilterService} from 'shared/services/filter.service';
import {SearchService} from 'shared/services/search.service';
import {J4CustomerFilterInit, J4SearchCustomerFilter} from './j4-customer-filter.dto';
import {J4Customer, J4CustomerDetail} from './j4-customer.dto';

/**
 * The service responsible for sending and receiving Customer data between the client and server.
 */
@Injectable()
export class J4CustomerService extends BaseService implements SearchService, FilterService {
  private CUSTOMER_FILTER_INIT_CACHE_KEY = 'J4_CUSTOMER_FILTER_INIT';

  // service is used on (UP and CP) OR AP
  public isAdminPortal: boolean;

  constructor(
    protected override http: HttpClient,
    protected cache: CacheService,
    @Inject(PORTAL_DATA) private portalConfig: IPortalConfig
  ) {
    super(http);
    this.isAdminPortal = this.portalConfig.apiType === PortalApiType.AP;
    this.baseUrl = this.isAdminPortal ? J4CustomerEndpoints.BASE : BaseEndpoints.SETTINGS;
    this.prefix = this.isAdminPortal ? J4CustomerEndpoints.AUI_PREFIX : J4CustomerEndpoints.MUI_PREFIX;
  }

  /**
   * Adds an appropriate prefix for the endpoint URL depending on the given app (MUI or AUI).
   */
  public prefix: any = () => {};

  /**
   * Sends a new customer registration request.
   * @param customer CustomerDetail.
   * @param password The password of the current user.
   * @returns {Observable<void>} Empty if the request was successful, error if it wasn't.
   */
  public addCustomer$(customer: J4CustomerDetail, password: string): Observable<void> {
    return this.post$<void>(J4CustomerEndpoints.CUSTOMER, new EntityWithPassword(customer, password));
  }

  /**
   * Modify customer details.
   * @param customer the new object representing a Customer.
   * @param password The password of the current user.
   * @param withEnabled Determines whether the "enabled" field should be included in the request or not.
   * @returns {Observable<void>}
   */
  public modifyDetail$(customer: J4Customer, password: string, withEnabled = true): Observable<void> {
    //@ts-ignore
    return this.put$<void>(customer.publicId, new EntityWithPassword(J4Customer.toRequestDto(customer, withEnabled), password));
  }

  /**
   * Initialize the customer filter.
   */
  public initCustomerFilter$(): Observable<J4CustomerFilterInit> {
    const fn$ = this.get$<J4CustomerFilterInit>(J4CustomerEndpoints.INIT);
    return this.cache.cacheFunction$<J4CustomerFilterInit>(this.CUSTOMER_FILTER_INIT_CACHE_KEY, fn$);
  }

  /**
   * Filter customers.
   */
  public filter$(filter: J4SearchCustomerFilter): Observable<JumioPage<J4Customer>> {
    return this.post$<JumioPage<J4Customer>>(J4CustomerEndpoints.FILTER, filter);
  }

  /**
   * Sends a search query to the backend for receiving a list of customers.
   * @param query The query string we send towards the backend,
   * @returns {Observable<Array<J4Customer>>} An Observable of customers that met the search query.
   */
  public search$(query: string): Observable<Array<J4Customer>> {
    return this.get$<Array<J4Customer>>(J4CustomerEndpoints.CUSTOMER).pipe(
      map((result: Array<J4Customer>) => result.filter(item => item.name?.includes(query)))
    );
  }

  /**
   * Returns Customer by publicId
   * @param id The public id
   * @returns {Observable<J4Customer>} Returns the selected customer by id.
   */
  public getDetail$(id: string): Observable<J4Customer> {
    return this.get$<J4Customer>(this.prefix(id)).pipe(
      tap(customer => {
        //@ts-ignore
        customer.createdAt = new Date(customer.createdAt);
        return customer;
      })
    );
  }
}

/**
 * A helper class to contain the search query field.
 */
export class J4CustomerSearchQuery {
  constructor(public query: string) {}
}
