import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {BaseService} from 'public-shared/services/base-http/base.service';
import {ResolveAuthEndpoints} from '../endpoint-constants';
import {EnvironmentProvider} from 'public-shared/services/environment-provider';
import {Auth0Url, AuthenticationPayload, AuthenticationResponse} from './login-request.dto';
import {IJ4AuthEnvironmentData} from '../../../environments/environment.prod';
@Injectable()
export class PublicAuthService extends BaseService {
  /**
   * Inject services
   * @param http
   * @param env
   */
  constructor(protected http: HttpClient, protected env: EnvironmentProvider<IJ4AuthEnvironmentData>) {
    super(http);
    this.baseUrl = this.env.environment.apiUrl();
  }

  /**
   * Get Auth0 uri
   */
  public getAuth0Uri$(): Observable<Auth0Url> {
    return this.get$<Auth0Url>(`${ResolveAuthEndpoints.BASE}${ResolveAuthEndpoints.LOGIN}`);
  }

  /**
   * Authenticate user
   * Returns new token and tenants list
   * @param data - auth0 code string
   */
  public login$(data: AuthenticationPayload): Observable<AuthenticationResponse> {
    return this.post$<AuthenticationResponse>(`${ResolveAuthEndpoints.BASE}${ResolveAuthEndpoints.LOGIN}`, data);
  }
}
