import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {appendCookiesQueryParamToUrl} from '@jumio/datadog-rum';
import {CodeLabel} from 'public-shared/models/code-label/code-label.dto';
import {ErrorDescriptor} from 'public-shared/models/error/error-descriptor';
import {Subscription} from 'rxjs';
import {AuthGlobalErrorHandler} from '../../../services/auth-global-error-handler';
import {Auth0Url, AuthenticationResponse, Tenants} from '../../../services/auth/login-request.dto';
import {PublicAuthService} from '../../../services/auth/public-auth.service';
import {AbstractLoginComponent} from '../abstract.login.component';

@Component({
  selector: 'app-login',
  templateUrl: '../abstract.login.component.html',
  styleUrls: ['../abstract.login.component.less']
})

/**
 * The component for showing and controlling the Login page.
 */
export class J4LoginComponent extends AbstractLoginComponent implements OnInit, OnDestroy {
  public readonly subscription = new Subscription();
  /**
   * Login request payload object
   */
  public payload: any = {};
  /**
   * Type of the login
   */
  public typeOfLogin = 'Auth0 Sign-On';
  /**
   * Indicates if the Go Back To Login button should be shown
   */
  public showGoBackToLoginButton = false;

  /**
   * Inject services
   */
  constructor(
    protected authService: PublicAuthService,
    router: Router,
    route: ActivatedRoute,
    protected _formBuilder: UntypedFormBuilder,
    protected errorHandler: AuthGlobalErrorHandler
  ) {
    super(router, route, _formBuilder);
  }

  /**
   * On component load
   */
  ngOnInit(): void {
    super.ngOnInit();

    const error = this.route.snapshot.queryParams.error;

    if (!!error) {
      const errorMessage = this.route.snapshot.queryParams.message || this.route.snapshot.queryParams.error_description;
      const errorDescriptor = new ErrorDescriptor(errorMessage, this.route.snapshot.queryParams.errorUrl || error, error);
      this.errorHandler.handleError(errorDescriptor);
      // when user gets an error from auth0 side, should see this button
      this.showGoBackToLoginButton = true;

      return;
    }

    const code = this.route.snapshot.queryParams.code;

    if (!code) {
      this.subscription.add(
        this.authService.getAuth0Uri$().subscribe((response: Auth0Url) => {
          if (response.uri) {
            window.open(response.uri, '_self');
          }
        })
      );
    } else {
      this.callMapping();
    }
  }

  /**
   * On component unmount
   */
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public callMapping(): void {
    this.isBusy = true;
    this.payload.code = this.route.snapshot.queryParams.code;

    this.subscription.add(
      this.authService
        .login$(this.payload)
        .subscribe({
          next: (response: AuthenticationResponse) => {
            this.handleSuccessLoginResponse(response);
          },
          error: error => {
            this.resetLoginForm();
            this.errorHandler.handleError(error);
            this.isBusy = false;
          }
        })
        // When the tenant mapping call is finished (error or success), user should see this button
        .add(() => {
          this.showGoBackToLoginButton = true;
        })
    );
  }

  /**
   * Reset form in case of any error
   */
  public resetLoginForm(): void {
    this.resetSelectedTenantValidators();
    this.resetForm();
  }

  /**
   * Set selected tenant
   */
  public onOptionSelected(option: CodeLabel): void {
    this.form?.controls['selectedTenant'].setValue(option);
    this.selectedTenant = option;
  }

  /**
   * Navigate user to Auth0 login page
   */
  public directToAuth0Login(): void {
    // Selected tenants comed from Form, 'code' is actually the 'redirectUrl'
    const redirectUrl = appendCookiesQueryParamToUrl(this.selectedTenant.code);
    window.open(redirectUrl, '_self');
  }

  /**
   * Logout User and Navigate to Auth0 login page
   */
  public goToLoginPage(): void {
    if (this.logoutUrl) {
      // Trigger Auth0 logout
      window.open(this.logoutUrl, '_self');
    } else {
      // Redirect to login page
      this.router.navigate(['/login']).then(() => false);
    }
  }

  protected handleSuccessLoginResponse(response: AuthenticationResponse): void {
    this.email = response.email;
    this.logoutUrl = response.logoutUrl;

    // If there is only one tenant, redirect to it, don't show the tenant selection screen - keep loader screen
    if (response.tenants.length === 1) {
      const redirectUrl = appendCookiesQueryParamToUrl(response.tenants[0].redirectUrl);
      window.open(redirectUrl, '_self');
      return;
    }

    this.tenants = response.tenants?.map((v: Tenants) => new CodeLabel(v.redirectUrl, v.tenantName));
    //@ts-ignore
    this.tenants.sort((a, b) => a.label.localeCompare(b.label)); // Sort tenants by label

    if (!response.tenants.length) {
      this.resetLoginForm();
    }

    this.isBusy = false;
  }
}
