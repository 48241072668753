/**
 * This class holds the Font-awesome icon classes of the corresponding statuses, types etc.
 */
export class IconConstants {
  /**
   * The icon classes of the statuses.
   */
  public static readonly STATUS_ICON_MAP = {
    FAILED: 'fas fa-close',
    DONE: 'fas fa-check-square',
    PENDING: 'fas fa-refresh'
  };

  /**
   * All icon classes
   */
  public static readonly ICONS = {
    // The icon classes of the verification types.
    GLOBE: 'fas fa-globe',
    ID_CARD: 'far fa-id-card',
    CAR: 'fas fa-car',
    CREDIT_CARD: 'far fa-credit-card',
    BANK: 'fas fa-university',
    RECTANGLE: 'fas fa-times-square',
    LIST: 'fas fa-list',
    NEWSPAPER: 'far fa-newspaper',
    FILES: 'fas fa-files',

    // General Icons
    ANGLE_DOWN: 'fas fa-angle-down',
    ANGLE_LEFT: 'fas fa-angle-left',
    ANGLE_RIGHT: 'fas fa-angle-right',
    CHEVRON_DOWN: 'fas fa-chevron-down',
    CHEVRON_LEFT: 'fas fa-chevron-left',
    CHEVRON_RIGHT: 'fas fa-chevron-right',
    ASTERISK: 'fas fa-asterisk',
    MINUS: 'fas fa-minus',
    CHECK: 'fas fa-check',
    CHECK_CIRCLE: 'fas fa-check-circle',
    MINUS_CIRCLE: 'fas fa-minus-circle',
    UPLOAD: 'fas fa-upload',
    TIMES: 'fas fa-times',
    TIMES_CIRCLE: 'fas fa-times-circle',
    PLUS: 'fas fa-plus',
    QUESTION: 'fas fa-question',
    QUESTION_CIRCLE: 'fas fa-question-circle',
    USER: 'fas fa-user',
    USER_TIMES: 'fas fa-user-times',
    USER_PLUS: 'fas fa-user-plus',
    POWER_OFF: 'fas fa-power-off',
    UNDO: 'fas fa-undo',
    TRASH: 'fas fa-trash-alt',
    DOWNLOAD: 'fas fa-download',
    PLAY: 'fas fa-play',
    PAUSE: 'fas fa-pause',
    STOP: 'fas fa-stop',
    SEARCH: 'fas fa-search',
    SEARCH_PLUS: 'fas fa-search-plus',
    EDIT: 'fas fa-edit',
    SLIDERS: 'fas fa-sliders-h',
    IMAGE: 'fas fa-image',
    COG: 'fas fa-cog',
    USERS: 'fas fa-users',
    FILE_DOWNLOAD: 'fas fa-file-download',
    USER_SLASH: 'fas fa-user-slash',
    EXTERNAL_LINK: 'fas fa-external-link-alt',
    LIST_ALT: 'fas fa-list-alt',
    ADDRESS_CARD: 'fas fa-address-card',
    CLIPBOARD_LIST: 'fas fa-clipboard-list',
    INFO_CIRCLE: 'fas fa-info-circle',
    LINK: 'fas fa-link',
    COPY: 'fas fa-copy',
    ARROW_UP: 'fas fa-arrow-up',
    ARROW_DOWN: 'fas fa-arrow-down',
    TOOLS: 'fas fa-tools',
    FRIENDS: 'fas fa-user-friends'
  };

  public static readonly DOCUMENT_TYPE_ICON_MAP = {
    CC: IconConstants.ICONS.CREDIT_CARD,
    IC: IconConstants.ICONS.NEWSPAPER,
    CAAP: IconConstants.ICONS.NEWSPAPER,
    CRC: IconConstants.ICONS.NEWSPAPER,
    CCS: IconConstants.ICONS.NEWSPAPER,
    LAG: IconConstants.ICONS.NEWSPAPER,
    LOAP: IconConstants.ICONS.NEWSPAPER,
    MOAP: IconConstants.ICONS.NEWSPAPER,
    TR: IconConstants.ICONS.NEWSPAPER,
    VT: IconConstants.ICONS.NEWSPAPER,
    VC: IconConstants.ICONS.NEWSPAPER,
    STUC: IconConstants.ICONS.LIST,
    HCC: IconConstants.ICONS.LIST,
    CB: IconConstants.ICONS.LIST,
    SENC: IconConstants.ICONS.LIST,
    MEDC: IconConstants.ICONS.LIST,
    BC: IconConstants.ICONS.LIST,
    WWCC: IconConstants.ICONS.LIST,
    SS: IconConstants.ICONS.LIST,
    TAC: IconConstants.ICONS.LIST,
    SEL: IconConstants.ICONS.LIST,
    PB: IconConstants.ICONS.LIST,
    USSS: IconConstants.ICONS.LIST,
    SSC: IconConstants.ICONS.LIST,
    CUSTOM: IconConstants.ICONS.LIST,
    BS: IconConstants.ICONS.BANK,
    UB: IconConstants.ICONS.LIST,
    VISA: IconConstants.ICONS.FILES,
    UNSUPPORTED: IconConstants.ICONS.RECTANGLE,
    DRIVING_LICENSE: IconConstants.ICONS.CAR,
    ID_CARD: IconConstants.ICONS.ID_CARD,
    PASSPORT: IconConstants.ICONS.GLOBE
  };

  /**
   * Default icon.
   */
  public static readonly DEF_ICON = 'fas fa-question';

  /**
   * Using the DOCUMENT_TYPE_ICON_MAP mapping, converts a code into an icon code.
   * @param {string} code The code as a string.
   * @returns {String} The icon CSS class as a string.
   */
  public static codeToIcon(code: keyof typeof IconConstants.DOCUMENT_TYPE_ICON_MAP): string {
    return IconConstants.DOCUMENT_TYPE_ICON_MAP[code];
  }
}
