import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {CodeLabel} from 'public-shared/models/code-label/code-label.dto';
import {BaseEndpoints} from 'public-shared/models/endpoints/base-endpoint.constants';
import {BaseService, EntityWithPassword} from 'public-shared/services/base-http/base.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ConsentSettingsInit} from 'shared/interfaces/consent-init.dto';
import {SupportedId} from 'shared/services/country/supported-id.dto';
import {BannerEndpoints, RemediationEndpoints, SettingsEndpoints} from 'shared/services/endpoint-constants';

import {Consent, ConsentCollectionSettings} from 'shared/interfaces/consent.dto';
import {BannerInterface} from 'shared/services/merchant/banner.dto';
import {Remediation} from '../../interfaces/remediation.dto';
import {CountrySettings} from '../country/country.dto';
import {MobileSDKDto} from './mobile-sdk.dto';
import {SlaFraudCheck} from './sla-fraud-check.dto';

@Injectable()
export class SettingsService extends BaseService {
  constructor(protected override http: HttpClient) {
    super(http);
    this.baseUrl = BaseEndpoints.SETTINGS;
  }

  public initDocumentCheckLevels$(): Observable<CodeLabel[]> {
    return this.get$<{documentCheckLevels: CodeLabel[]}>(SettingsEndpoints.SLA_FRAUD_CHECK_INIT).pipe(
      map(response => response.documentCheckLevels)
    );
  }

  public getSlaFraudCheckSettings$(): Observable<SlaFraudCheck> {
    return this.get$<SlaFraudCheck>(SettingsEndpoints.SLA_FRAUD_CHECK);
  }

  public modifySlaFraudCheckSettings$(settings: SlaFraudCheck, password: string): Observable<void> {
    return this.put$<void>(SettingsEndpoints.SLA_FRAUD_CHECK, new EntityWithPassword(settings, password));
  }

  public filterCountries$(countryName: string): Observable<Array<CountrySettings>> {
    return this.post$<Array<CountrySettings>>(SettingsEndpoints.COUNTRIES_SEARCH, {query: countryName || ''});
  }

  public downloadCsv$(): Observable<Blob> {
    return this.getCsv$(SettingsEndpoints.COUNTRIES_CSV);
  }

  public getSupportedIds$(countryName: string): Observable<SupportedId[]> {
    return this.post$<Array<SupportedId>>(SettingsEndpoints.SUPPORTED_IDS, {query: countryName});
  }

  public downloadSupportedIdsCsv$(countryCode: string | undefined): Observable<Blob> {
    return this.http
      .post(this.baseUrl + SettingsEndpoints.SUPPORTED_IDS_CSV, {query: countryCode}, {responseType: 'text'})
      .pipe(map(BaseService.mapToCsv));
  }

  public getRemediationSettings$(): Observable<Remediation> {
    return this.get$<Remediation>(RemediationEndpoints.BASE);
  }

  public updateRemediationSettings$(settings: Remediation, password: string): Observable<any> {
    return this.put$<any>(RemediationEndpoints.BASE, new EntityWithPassword(settings, password));
  }

  public getBannerSettings$(): Observable<BannerInterface> {
    return this.get$<BannerInterface>(BannerEndpoints.BANNER).pipe(
      map(item => {
        item.startDate = new Date(item.startDate);
        item.endDate = new Date(item.endDate);
        return item;
      })
    );
  }

  public updateBannerSettings$(settings: BannerInterface, password: string): Observable<BannerInterface> {
    const settingsToUpdate: BannerInterface = {
      showcaseCustomerPortal: settings.showcaseCustomerPortal,
      showcaseUnifiedPortal: settings.showcaseUnifiedPortal,
      input: settings.input,
      startDate: (settings.startDate as Date).getTime(),
      endDate: (settings.endDate as Date).getTime(),
      url: settings.url
    };
    return this.put$<BannerInterface>(BannerEndpoints.BANNER, new EntityWithPassword(settingsToUpdate, password));
  }

  public getMobileSdkSettings$(): Observable<any> {
    return this.get$<any>(SettingsEndpoints.MOBILE);
  }

  public putMobileSdkSettings$(row: MobileSDKDto, password: string): Observable<any> {
    return this.put$<any>(SettingsEndpoints.MOBILE_UPDATE, new EntityWithPassword(row, password));
  }

  public putMultipleMobileSdkSettings$(rows: MobileSDKDto[], password: string): Observable<any> {
    return this.put$<any>(SettingsEndpoints.MOBILE_UPDATE_LIST, new EntityWithPassword(rows, password));
  }

  public createMobileSdkSettings$(row: MobileSDKDto, password: string): Observable<any> {
    return this.put$<any>(SettingsEndpoints.MOBILE_CREATE, new EntityWithPassword(row, password));
  }

  public deleteMobileSdkSettings$(row: MobileSDKDto, password: string): Observable<any> {
    return this.put$<any>(SettingsEndpoints.MOBILE_DELETE, new EntityWithPassword(row, password));
  }

  // Consent Settings
  public initConsentSettings$(): Observable<ConsentSettingsInit> {
    return this.get$(SettingsEndpoints.CONSENT_INIT);
  }

  public globalConsentListSettings$(): Observable<Consent[]> {
    return this.get$(SettingsEndpoints.CONSENT);
  }

  // Customer Consent
  public initConsentCollectionSettings$(customerId: string): Observable<ConsentCollectionSettings> {
    return this.get$(SettingsEndpoints.CUSTOMER_CONSENT_CONFIGURATION_INIT(customerId));
  }

  public updateConsentCollectionSettings$(
    consentCollection: ConsentCollectionSettings,
    customerId: string,
    password: string
  ): Observable<void> {
    return this.put$(
      SettingsEndpoints.CUSTOMER_CONSENT_CONFIGURATION_UPDATE(customerId),
      new EntityWithPassword(consentCollection, password)
    );
  }

  public customerConsentListSettings$(customerId: string): Observable<Consent[]> {
    return this.get$(SettingsEndpoints.CUSTOMER_CONSENT_LIST(customerId));
  }

  public updateGlobalConsentListSettings$(consentList: Consent[], password: string): Observable<void> {
    return this.put$(SettingsEndpoints.GLOBAL_CONSENT_UPDATE_LIST, new EntityWithPassword(consentList, password));
  }

  public updateCustomerConsentListSettings$(consentList: Consent[], customerId: string, password: string): Observable<Consent[]> {
    return this.put$(SettingsEndpoints.CUSTOMER_CONSENT_UPDATE_LIST(customerId), new EntityWithPassword(consentList, password));
  }
}
