/**
 * Humanizes a string
 * From https://stackoverflow.com/a/28339742
 * and https://stackoverflow.com/a/17572933
 * and https://stackoverflow.com/a/21149072
 * @returns {string} Humanized string
 */
export const humanize = (text: string | undefined): string => {
  if (!text) {
    return '';
  }

  return camelCaseToWords(text);
};

export const camelCaseToWords = (text: string): string => {
  if (!text) {
    return '';
  }

  // Use a regular expression to match words, including parentheses
  const matches = text.match(/([A-Z]?[a-z]+|[A-Z]+|\(|\)|\d+)/g);

  if (!matches) {
    return '';
  }

  const words = matches
    .filter(match => match !== null && match !== undefined)
    .map(word => {
      if (word.length <= 1) {
        return word;
      }

      return (
        word[0].toUpperCase() +
        (word[1] === word[1].toLocaleLowerCase() ? word.substring(1).toLowerCase() : word.substring(1).toUpperCase())
      );
    });

  if (!words.length) {
    return '';
  }

  return words.join(' ');
};
