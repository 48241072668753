import {Injectable} from '@angular/core';
import {PublicSecurityContextHolder} from 'public-shared/services/security/public-security-context-holder';
import {BaseCacheService} from './base-cache.service';

@Injectable()
export class PublicCacheService extends BaseCacheService {
  constructor(protected override securityContextHolder: PublicSecurityContextHolder) {
    super(securityContextHolder);
  }
}
