import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseService} from 'public-shared/services/base-http/base.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {JumioPage} from 'shared/components/table/dto/jumio-page';
import {CacheService} from 'shared/services/common/cache.service';
import {MailTemplatesEndpoints} from 'shared/services/endpoint-constants';
import {MailTemplate, MailTemplatesFields, MailTemplatesFilter} from 'shared/services/mail-templates/mail-templates.dto';
import {MailTemplateResult} from './mail-template-result.dto';

@Injectable()
export class MailTemplatesService extends BaseService {
  public mailTemplatesChanged = new BehaviorSubject(null);

  private MAIL_TEMPLATE_FIELDS_CACHE_KEY = 'MAIL_TEMPLATE_FIELDS';

  constructor(protected override http: HttpClient, private cache: CacheService) {
    super(http);
    this.baseUrl = MailTemplatesEndpoints.BASE;
  }

  public getFields$(): Observable<MailTemplatesFields> {
    const fn$ = this.get$<MailTemplatesFields>(MailTemplatesEndpoints.INIT);
    return this.cache.cacheFunction$<MailTemplatesFields>(this.MAIL_TEMPLATE_FIELDS_CACHE_KEY, fn$);
  }

  public getMailTemplate$(id: string): Observable<MailTemplate> {
    return this.get$<MailTemplate>(MailTemplatesEndpoints.MAIL_TEMPLATE(id));
  }

  public addMailTemplate$(entity: MailTemplate): Observable<void> {
    return this.post$<void>(MailTemplatesEndpoints.MAIL_TEMPLATE(), entity);
  }

  public updateMailTemplate$(entity: MailTemplate): Observable<void> {
    return this.put$<void>(MailTemplatesEndpoints.MAIL_TEMPLATE(entity.id), entity);
  }

  public filter$(filter: MailTemplatesFilter): Observable<JumioPage<MailTemplateResult>> {
    return this.post$<JumioPage<MailTemplateResult>>(MailTemplatesEndpoints.FILTER, filter);
  }
}
