import {ChangeDetectorRef, Component, OnInit, ViewChild, inject} from '@angular/core';
import {Subscription} from 'rxjs';
import {ForceReloadService} from '../../services/force-reload/force-reload-modal.service';
import {tap} from 'rxjs/operators';
import {ModalComponent} from '@jumio/portals.core-ui';
import {UntilDestroy} from '@jumio/portals.core';

@UntilDestroy()
@Component({
  selector: 'jumio-reload-modal',
  templateUrl: './reload-modal.component.html',
  standalone: true,
  imports: [ModalComponent]
})
export class ForceReloadModalComponent implements OnInit {
  @ViewChild('modal') public modal: ModalComponent | undefined;

  private forceReloadService = inject(ForceReloadService);
  private cd = inject(ChangeDetectorRef);

  public readonly subscription = new Subscription();

  ngOnInit(): void {
    this.cd.detach();

    this.subscription.add(
      this.forceReloadService.reloadTriggered$
        .pipe(
          tap(() => {
            this.cd.reattach();
            this.cd.detectChanges();

            this.modal?.show();
          })
        )
        .subscribe()
    );
  }
}
