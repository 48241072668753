export enum PlatformNames {
  CP = 'CUSTOMER_PORTAL',
  JTM = 'JTM',
  FOUR_STOP = '4_STOP'
}

export const platformReturnUrls = {
  [PlatformNames.CP]: 'verifications',
  [PlatformNames.JTM]: 'jtm-redirect',
  [PlatformNames.FOUR_STOP]: '4stop-redirect'
};

export class ErrorUrls {
  public static readonly BASE = 'error';
  public static readonly ERROR_404 = '404';
  public static readonly ERROR_403 = '403';
}

export class PasswordUrls {
  public static readonly RESET_PASSWORD = 'reset-password';
  public static readonly TFA_SETUP = 'tfa-setup';
  public static readonly CREATE_NEW_PASSWORD = 'create-new-password';
}

export class ProfileUrls {
  public static readonly MY_PROFILE = 'my-profile';
  public static readonly ACCOUNT = 'account';
  public static readonly PASSWORD = 'password';
}

export class FeedbackUrls {
  public static readonly SEND_FEEDBACK = 'feedback';
}

export class ReportsUrls {
  public static readonly BASE = 'reports';
  public static readonly LIST = 'list';
  public static readonly CREATE = 'create';
  public static readonly CREATE_REPORT = `${ReportsUrls.BASE}/${ReportsUrls.CREATE}`;

  public static readonly V2 = `verification`;
  public static readonly BASE_V2 = `${ReportsUrls.V2}-${ReportsUrls.BASE}`;
  public static readonly KYX = `transactions`;
  public static readonly V3 = `workflows`;

  public static readonly V2_LIST = `${ReportsUrls.V2}/${ReportsUrls.LIST}`;
  public static readonly KYX_LIST = `${ReportsUrls.KYX}/${ReportsUrls.LIST}`;
  public static readonly V3_LIST = `${ReportsUrls.V3}/${ReportsUrls.LIST}`;

  public static readonly V2_CREATE = `verifications/${ReportsUrls.CREATE_REPORT}`;
  public static readonly KYX_CREATE = `transactions/${ReportsUrls.CREATE_REPORT}`;
  public static readonly V3_CREATE = `workflows/${ReportsUrls.CREATE_REPORT}`;
}

export class SettingsUrls {
  public static readonly MENU_BASE = 'settings';
  public static readonly BASE = 'identity-verification';
  public static readonly USERS = 'users';
  public static readonly GROUPS = 'groups';
  public static readonly IDP = 'idp';
  public static readonly AUDIT_LOG = 'audit-log';

  // URL Parts
  public static readonly MULTI_DOCUMENTS = 'multi-documents';
  public static readonly CUSTOM_MULTI_DOCUMENTS = `${SettingsUrls.MULTI_DOCUMENTS}/custom`;
  public static readonly ADD = 'add';
  public static readonly ADD_CUSTOM_MULTI_DOCUMENTS = `${SettingsUrls.CUSTOM_MULTI_DOCUMENTS}/${SettingsUrls.ADD}`;
  public static readonly CUSTOM_MULTI_DOCUMENTS_DETAILS = `${SettingsUrls.CUSTOM_MULTI_DOCUMENTS}/document`;
  public static readonly ACCEPTED_IDS = 'accepted-ids';
  public static readonly ACCEPTED_IDS_BY_REGION = 'region';
  public static readonly ACCEPTED_IDS_BY_COUNTRY = 'country';

  public static readonly SUPPORTED_IDS = 'supported-ids';

  public static readonly DOCUMENT_DETAILS = 'document/';
  public static readonly DOCUMENT_VERIFICATION_SUPPORTED = 'supported';
  public static readonly DOCUMENT_VERIFICATION_CUSTOM = 'custom';

  public static readonly APPLICATION_SETTINGS = 'application-settings';
  public static readonly APPLICATION_SETTINGS_GENERAL = 'general';
  public static readonly APPLICATION_SETTINGS_REDIRECT = 'redirect';

  public static readonly API_CREDENTIALS = 'api-credentials';
  public static readonly API_USERS = 'api-users';
  public static readonly OAUTH_API_TOKENS = 'oauth-tokens';
  public static readonly DEMO_APP_SETUP = 'demo-app-setup';
  public static readonly OFFLINE_TOKEN = 'offline-token';

  public static readonly BATCH_CREDENTIALS = 'batch-credentials';
  public static readonly BATCH_CREDENTIALS_UPLOAD = 'upload';
  public static readonly BATCH_CREDENTIALS_EDIT = 'edit';

  public static readonly DATA_SETTINGS = 'data-settings';
  public static readonly DATA_SETTINGS_MANDATORY_FIELDS = 'mandatory-fields';
  public static readonly DATA_SETTINGS_OPTIONAL_FIELDS = 'optional-fields';
  public static readonly DATA_SETTINGS_ENABLED_FIELDS = 'enabled-fields';
  public static readonly DATA_SETTINGS_RETENTION = 'data-retention';
  public static readonly DATA_SETTINGS_SCREENING = 'screening';

  public static readonly WATCHLIST_SCREENING = 'watchlist-screening';

  public static readonly CUSTOMIZE_CLIENT = 'customize-client';
  public static readonly CUSTOMIZE_CLIENT_GENERAL = 'general';
  public static readonly CUSTOMIZE_CLIENT_REDIRECT = 'redirect';

  public static readonly SUB_PROCESSORS = 'sub-processors';

  public static readonly SUPPORT = 'support';

  // Full URLs
  public static readonly MULTI_DOCUMENTS_URL = `${SettingsUrls.BASE}/${SettingsUrls.MULTI_DOCUMENTS}`;
  public static readonly CUSTOM_MULTI_DOCUMENTS_URL = `${SettingsUrls.BASE}/${SettingsUrls.CUSTOM_MULTI_DOCUMENTS}`;
  public static readonly ADD_CUSTOM_MULTI_DOCUMENTS_URL = `${SettingsUrls.BASE}/${SettingsUrls.ADD_CUSTOM_MULTI_DOCUMENTS}`;

  public static readonly ACCEPTED_IDS_URL = `${SettingsUrls.BASE}/${SettingsUrls.ACCEPTED_IDS}`;
  public static readonly ACCEPTED_IDS_BY_COUNTRY_URL = `${SettingsUrls.ACCEPTED_IDS_URL}/${SettingsUrls.ACCEPTED_IDS_BY_COUNTRY}`;

  // J4 Specific URLS
  public static readonly J4_IV_BASE = ''; // app baseApp and mainUrl are already set to "'/settings/identity-verification'"
}

export class UsersUrls {
  public static readonly BASE = 'users';
  public static readonly GUIDE = 'guide';
  public static readonly LIST = 'list';
}

export class IdpUrls {
  public static readonly BASE = 'idp-management';
  public static readonly UPDATE = `${IdpUrls.BASE}/update`;
}

export class WorkflowsUrls {
  public static readonly BASE = 'workflows';
  public static readonly LIST = 'list';
  public static readonly CREATE_WORKFLOW = 'create-workflow';
}

export class TransactionUrls {
  public static readonly BASE = 'transactions';
  public static readonly LIST = 'list';
  public static readonly CREATE_TRANSACTION = `create`;
}

export class AccountsUrls {
  public static readonly BASE = 'accounts';
}

export class AppUrls {
  public static readonly FOUR_STOP = '4stop';
  public static readonly JTM = 'jtm';
  public static readonly DASHBOARD = 'dashboard';
  public static readonly LOGIN = 'login';

  public static readonly FOUR_STOP_REDIRECT = platformReturnUrls[PlatformNames.FOUR_STOP];
  public static readonly JTM_REDIRECT = platformReturnUrls[PlatformNames.JTM];
}

export class J4UsersUrls {
  public static readonly BASE = 'j4-users';
  public static readonly LIST = 'list';
}
