import {CommonModule} from '@angular/common';
import {Component, ElementRef, inject, Input, NgZone} from '@angular/core';
import {DatadogRumLoggerService, ILogContext} from '@jumio/datadog-rum';
import {DatadogAppNames} from '@jumio/portals.core';

@Component({
  selector: 'j4-image-secure-wrapper',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './image-secure-wrapper.component.html',
  styleUrls: ['./image-secure-wrapper.component.less']
})
export class ImageSecureWrapperComponent {
  private datadogService = inject(DatadogRumLoggerService);
  private elementRef = inject(ElementRef);
  public hideImage = true;

  @Input() public showDownloadButton = false;
  @Input() public imageName = 'document-image.png';
  @Input() public label: string | undefined;
  @Input() public context: ILogContext | undefined;

  private ngZone = inject(NgZone);

  public downloadImage(): void {
    const imageDataUrl = this.getImage();
    const link = document.createElement('a');
    link.href = imageDataUrl;
    link.download = this.buildImageName(this.imageName, imageDataUrl);
    link.click();
    this.datadogService.logAction('downloadImage: ' + this.label, this.context, DatadogAppNames.SHARED);
  }

  public getImage(): string {
    const images = this.elementRef.nativeElement.querySelectorAll('img');
    const canvases = this.elementRef.nativeElement.querySelectorAll('canvas');

    let imageSource = '';
    if (images.length > 0) {
      const image: HTMLImageElement = Array.from(images).pop() as HTMLImageElement;
      imageSource = image.src;
    } else if (canvases.length > 0) {
      const canvas: HTMLCanvasElement = Array.from(canvases).pop() as HTMLCanvasElement;
      imageSource = canvas.toDataURL();
    }

    return imageSource;
  }

  public mouseOver(): void {
    this.ngZone.run(() => {
      this.hideImage = false;
    });
  }

  public mouseOut(): void {
    this.ngZone.run(() => {
      this.hideImage = true;
    });
  }

  private buildImageName(name: string, imageDataUrl: string): string {
    const endsWithType = this.endsWithType(name);

    return endsWithType ? `${this.imageName}` : `${this.imageName}.${this.findImageType(imageDataUrl)}`;
  }

  // From: https://www.npmjs.com/package/base64-image-mime?activeTab=code
  private findImageType(base64Encoded: string): string | null {
    if (base64Encoded.startsWith('data:image')) {
      const found = base64Encoded.match(/data:\S*;base64/g);

      return found && found[0].slice('data:image/'.length, ';base64'.length * -1);
    } else {
      const data = base64Encoded.startsWith('data:')
        ? //@ts-ignore
          base64Encoded.slice(base64Encoded.match(/data:\S*;base64,/g)[0].length, base64Encoded.length)
        : base64Encoded;
      try {
        const prefix = window.atob(data);
        const found = this.matchType(prefix);

        if (!found) {
          const hex = this.decodedBase64ToHex(prefix);
          return hex.startsWith('ffd8ff') ? 'jpeg' : '';
        } else {
          const type = found[0].toLocaleLowerCase();
          return type === 'jfif' ? 'jpeg' : type;
        }
      } catch (e) {
        return '';
      }
    }
  }

  // https://stackoverflow.com/questions/39460182/decode-base64-to-hexadecimal-string-with-javascript
  private decodedBase64ToHex(decodedBase64: string): string {
    return decodedBase64
      .split('')
      .map(aChar => '0' + aChar.charCodeAt(0).toString(16))
      .slice(-2)
      .join('');
  }

  private matchType(data: string): string[] {
    //@ts-ignore
    return data.match(/(webp)|(png)|(gif)|(svg)|(jpg)|(jpeg)|(pjpeg)|(pjp)|(jfif)/gi);
  }

  private endsWithType(data: string): boolean {
    return !!data.match(/(\.webp$)|(\.png$)|(\.gif$)|(\.svg$)|(\.jpg$)|(\.jpeg$)|(\.pjpeg$)|(\.pjp$)|(\.jfif$)/gi);
  }
}
