import {HttpClient} from '@angular/common/http';
import {BaseService} from 'public-shared/services/base-http/base.service';
import {EnvironmentProvider} from 'public-shared/services/environment-provider';
import {Observable} from 'rxjs/internal/Observable';
import {CustomerEnvironmentData} from '@jumio/portals.core';
import {CacheService} from 'shared/services/common/cache.service';
import {OrchestrationEndpoints} from 'shared/services/endpoint-constants';
import {OrchestrationWorkflowResponse} from 'shared/services/merchant/orchestration.dto';
import {SecurityContextHolder} from 'shared/services/security/security-context-holder';

export abstract class BaseOrchestrationService extends BaseService {
  public static ON_SEARCH_ERROR_KEY = 'SEARCH_ERROR';
  public static NO_PUBLIC_ID_ERROR_KEY = 'NO_PUBLIC_ID_ERROR';

  FILTER_CACHE_KEY = 'ORCHESTRATION_CUSTOMER_SPECIFIC_FILTER';
  RESULTS_CACHE_KEY = 'ORCHESTRATION_CUSTOMER_SPECIFIC_RESULTS';

  GLOBAL_RESULTS_CACHE_KEY = 'ORCHESTRATION_GLOBAL_RESULTS';

  constructor(
    protected contextHolder: SecurityContextHolder,
    protected override http: HttpClient,
    protected environmentProvider: EnvironmentProvider<CustomerEnvironmentData>,
    protected cache: CacheService
  ) {
    super(http);
    this.baseUrl = OrchestrationEndpoints.BASE;
  }

  abstract getWorkflows$(_merchantId?: string): Observable<OrchestrationWorkflowResponse>;

  abstract downloadWorkflow$(_workflowId: string, _merchantId?: string): Observable<Blob>;

  abstract uploadNewWorkflow$(_formData: FormData, _merchantId?: string): Observable<void>;

  abstract uploadUpdatedWorkflow$(_workflowId: string, _formData: FormData, _merchantId?: string): Observable<void>;
}
