import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {Injectable, inject} from '@angular/core';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {IPortalConfig, PORTAL_DATA} from '../../../../core/src/lib/interfaces/portal-config.interface';
import {ApiVersionStoreService} from '../services/api-version/api-version-store.service';
import {AppType} from '../services/api-version/app-version';
import {ForceReloadService} from '../services/force-reload/force-reload-modal.service';

/**
 * This interceptor compares the deployed API version with the previous,
 * and if we got a new version initiate a reload on the page
 */
@Injectable()
export class ApiVersionInterceptor implements HttpInterceptor {
  private apiVersionService = inject(ApiVersionStoreService);
  private forceReloadService = inject(ForceReloadService);
  private portalConfig: IPortalConfig = inject(PORTAL_DATA);

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(response => {
        const {apiType, basePath} = this.portalConfig;

        const apiVersionHeaderName = `x-${apiType}`;
        const path = basePath === '' ? '/' : basePath;

        if (response instanceof HttpResponse) {
          const {headers} = response;
          const currentApiVersion = headers.get(apiVersionHeaderName);

          if (this.isApiVersionChanged(currentApiVersion, path)) {
            this.reloadPage();
          }
        }
      })
    );
  }

  private isApiVersionChanged(currentApiVersion: string | null, basePath: string | undefined): boolean {
    const isInitialVersion = this.apiVersionService.isInitialVersion(AppType.CLIENT_FACADE, currentApiVersion, basePath);
    return !!(currentApiVersion && !isInitialVersion);
  }

  private reloadPage(): void {
    this.forceReloadService.reload();
  }
}
