import {Pipe, PipeTransform} from '@angular/core';
import {SafeStyle} from '@angular/platform-browser';
import {Observable} from 'rxjs';
import {ImageService} from 'shared/services/image/image.service';

@Pipe({
  name: 'secureBgImage',
  standalone: true
})
/**
 * A custom pipe that allows images to be requested from the server using the required authorization headers.
 * Returns an Observable of the image's URL, so Angular's built-in {@link AsyncPipe} needs to be used after it in the <img> tag.
 * Usage:
 * ```html
 *   <img [src]="(imageUrl | secure) | async" />```
 */
export class SecureBackgroundImagePipe implements PipeTransform {
  /**
   * If the environment is not production, sets the base URL to the value of the CORS proxy server.
   * @param imageService
   */
  constructor(private imageService: ImageService) {}

  /**
   * Depending on the format, calls the correct method of ImageService.
   * Returns a SafeUrl which can be displayed in the [src] tag.
   */
  //@ts-ignore
  public transform(blobOrUrl: any, format: string): Observable<SafeStyle> {
    if (format === 'url') {
      return this.imageService.getBackgroundImage$(blobOrUrl);
    } else if (format === 'blob') {
      return this.imageService.blobToSafeStyle$(blobOrUrl);
    }
  }
}
