<app-form-group [name]="name" [errorMessages]="errorMessages">
  <div class="image-uploader" #formElement ngProjectAs=".form-element">
    <div class="preview-container">
      <div class="image-preview" *ngIf="isDefaultImage" [style.background-image]="currentThumbnail | secureBgImage : 'url' | async"></div>
      <div class="image-preview" *ngIf="isCustomImage" [style.background-image]="currentBlob | secureBgImage : 'blob' | async"></div>
      <div class="image-preview" *ngIf="isSecureThumbnail" [style.background-image]="currentThumbnail"></div>
      <div class="image-preview" *ngIf="!isDefaultImage && !isCustomImage && !isSecureThumbnail"></div>
    </div>

    <div class="title-container">
      <div class="title">
        {{ label }}
      </div>
      <div class="dimensions" *ngIf="imageWidthRestriction && imageHeightRestriction">
        Max width: {{ imageWidthRestriction }}px, Max height: {{ imageHeightRestriction }}px
      </div>

      <div class="dimensions comment" *ngIf="additionalComment">
        {{ additionalComment }}
      </div>
    </div>

    <div class="controls-container">
      <div class="button-group">
        <label
          class="button upload-button flex-1"
          [class.disabled]="buttonsDisabled"
          (click)="buttonsDisabled ? $event.preventDefault() : null"
        >
          {{ isSecureThumbnail || defaultThumbnail !== currentThumbnail ? 'Change image' : 'Select image' }}
          <input
            type="file"
            #fileinput
            (change)="onFileSelect($any($event), true)"
            [accept]="accept"
            class="fileinput"
            [disabled]="buttonsDisabled"
          />
          <input [name]="$any(name)" [(ngModel)]="currentThumbnail" #model="ngModel" hidden [disabled]="buttonsDisabled" />
        </label>
        <button
          class="button remove-image flex-1"
          type="button"
          (click)="removeSelectedImage()"
          [disabled]="!showRemoveButton || buttonsDisabled"
        >
          Remove image
        </button>
        <button
          class="button flex-1 reset-image"
          type="button"
          (click)="resetToDefaultImage()"
          [disabled]="!showResetToDefaultButton || buttonsDisabled"
        >
          {{ resetToDefaultButtonLabel }}
        </button>
      </div>
    </div>
  </div>
</app-form-group>
