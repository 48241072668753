import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {InfoService} from 'public-shared/services/info/info.service';
import {EMPTY, Observable, Subject} from 'rxjs';
import {map} from 'rxjs/operators';
import {Helpers} from 'shared/components/helpers/helpers';
import {JumioPage} from 'shared/components/table/dto/jumio-page';
import {ExperimentEndpoints} from 'shared/services/endpoint-constants';
import {ExperimentFilter, ExperimentStatistics} from 'shared/services/experiments/experiment.dto';
import {AddExperimentResponse, ExperimentDetail, ExperimentListDetail} from 'shared/services/experiments/experiments.dto';
import {ImageMetadataSearch} from 'shared/services/experiments/image-metadata-search.dto';
import {LegacyExperimentsService} from 'shared/services/experiments/legacy-experiments.service';
import {LegacyTaggingTemplateWrapper} from 'shared/services/experiments/legacy-tagging-template.dto';
import {TaggingTemplateWrapper} from 'shared/services/experiments/tagging-template.dto';
import {FilterService} from 'shared/services/filter.service';
import {SearchService} from 'shared/services/search.service';
import {SecurityContextHolder} from '../security/security-context-holder';
import {EventLog} from '../verification/details-dto/events-log.dto';
import {CrossTablePreviewResponse, SimpleDataset} from './datasets.dto';
import {ExperimentActions} from './experiment-actions.enum';
import {TaggingTemplate, TaggingTemplateFilter} from './tagging-template.dto';

@Injectable()
export class ExperimentsService extends LegacyExperimentsService implements FilterService, SearchService {
  /**
   * Used for watching changes in tagging templates and notifying components about them.
   */
  public taggingTemplatesChanged$ = new Subject();
  public isSandboxEnabled: boolean;

  constructor(
    protected override http: HttpClient,
    protected override infoService: InfoService,
    private contextHolder: SecurityContextHolder
  ) {
    super(http, infoService);
    this.isSandboxEnabled = Helpers.isSandboxEnabled(contextHolder);
    this.baseUrl = this.isSandboxEnabled ? ExperimentEndpoints.BASE_V3 : ExperimentEndpoints.BASE_V2;
  }

  public addExperiment$(formData: FormData): Observable<AddExperimentResponse> {
    return this.post$<AddExperimentResponse>(ExperimentEndpoints.EXPERIMENTS, formData);
  }

  public searchExperiments$(filter: ExperimentFilter): Observable<JumioPage<ExperimentListDetail>> {
    return this.post$<JumioPage<ExperimentListDetail>>(ExperimentEndpoints.EXPERIMENTS_SEARCH, filter);
  }

  public exportExperiment$(filter: ExperimentFilter): Observable<Blob> {
    return this.postCsv$(ExperimentEndpoints.EXPERIMENTS_EXPORT, filter);
  }

  public getExperimentDetail$(id: string): Observable<ExperimentDetail> {
    return this.get$<ExperimentDetail>(ExperimentEndpoints.EXPERIMENT(id));
  }

  public getExperimentStatistics$(id: string): Observable<ExperimentStatistics> {
    return this.get$<ExperimentStatistics>(ExperimentEndpoints.EXPERIMENT_STATISTICS(id));
  }

  public setExperimentStatus$(experimentId: string, action: string, user: string): Observable<string> {
    if (action === ExperimentActions.START) {
      return this.post$<string>(ExperimentEndpoints.EXPERIMENT_ACTION_START(experimentId, user), {});
    }

    return this.post$<string>(ExperimentEndpoints.EXPERIMENT_ACTION(experimentId, action), {});
  }

  public startExperiment$(id: string, user: string): Observable<void> {
    return this.post$<void>(ExperimentEndpoints.EXPERIMENT_ACTION_START(id, user), {});
  }

  public pauseExperiment$(id: string): Observable<void> {
    return this.post$<void>(ExperimentEndpoints.EXPERIMENT_ACTION(id, ExperimentActions.PAUSE), {});
  }

  public resumeExperiment$(id: string): Observable<void> {
    return this.post$<void>(ExperimentEndpoints.EXPERIMENT_ACTION(id, ExperimentActions.RESUME), {});
  }

  public searchTaggingTemplates$(filter: TaggingTemplateFilter): Observable<JumioPage<TaggingTemplate>> {
    return this.post$<JumioPage<TaggingTemplate>>(ExperimentEndpoints.TAGGING_TEMPLATES_SEARCH, filter);
  }

  public getLegacyTaggingTemplateDetails$(id: number): Observable<LegacyTaggingTemplateWrapper> {
    return this.get$<LegacyTaggingTemplateWrapper>(ExperimentEndpoints.LEGACY_TEMPLATE(id));
  }

  public getTaggingTemplateDetails$(id: string): Observable<TaggingTemplate> {
    return this.get$<TaggingTemplate>(ExperimentEndpoints.TAGGING_TEMPLATE(id));
  }

  public createTaggingTemplate$(body: TaggingTemplateWrapper): Observable<TaggingTemplateWrapper> {
    return this.post$<TaggingTemplateWrapper>(ExperimentEndpoints.TAGGING_TEMPLATES, body);
  }

  public updateTaggingTemplate$(id: string, body: TaggingTemplateWrapper): Observable<TaggingTemplateWrapper> {
    return this.put$<TaggingTemplateWrapper>(ExperimentEndpoints.TAGGING_TEMPLATES_UPDATE(id), body);
  }

  public archiveTemplate$(id: string): Observable<void> {
    return this.post$(ExperimentEndpoints.TAGGING_TEMPLATES_ARCHIVE(id), {});
  }

  public unarchiveTemplate$(id: string): Observable<void> {
    return this.post$(ExperimentEndpoints.TAGGING_TEMPLATES_UNARCHIVE(id), {});
  }

  public deleteTaggingTemplate$(id: string): Observable<void> {
    return this.delete$<void>(ExperimentEndpoints.TAGGING_TEMPLATE(id));
  }

  public getQueues$(): Observable<Array<string>> {
    return this.get$<Array<string>>(ExperimentEndpoints.QUEUES);
  }

  public stopExperiment$(id: string): Observable<void> {
    return this.post$<void>(ExperimentEndpoints.EXPERIMENT_ACTION(id, ExperimentActions.STOP), {});
  }

  public updateExperiment$(id: string, formData: FormData): Observable<void> {
    return this.put$<void>(ExperimentEndpoints.EXPERIMENT(id), formData);
  }

  public archiveExperiment$(id: string): Observable<void> {
    return this.post$<void>(ExperimentEndpoints.EXPERIMENT_ARCHIVE(id), {});
  }

  public unarchiveExperiment$(id: string): Observable<void> {
    return this.post$<void>(ExperimentEndpoints.EXPERIMENT_UNARCHIVE(id), {});
  }

  public deleteExperiment$(id: string): Observable<void> {
    return this.delete$<void>(ExperimentEndpoints.EXPERIMENT(id));
  }

  // TODO: In the TI-535 service refactor, extract this to the new DatasetsService
  public getQueryPreviewCount$(imageMetadataSearch: ImageMetadataSearch): Observable<number> {
    return this.post$(ExperimentEndpoints.QUERY_COUNT_PREVIEW, imageMetadataSearch);
  }

  public createPriesJob$(formData: FormData): Observable<void> {
    return this.post$(ExperimentEndpoints.CREATE_PRIES, formData);
  }

  public filter$(): Observable<JumioPage<any>> {
    return EMPTY;
  }

  /**
   * Used by autocomplete component
   * @param query
   */
  public search$(query: string): Observable<SimpleDataset[]> {
    const filter = new TaggingTemplateFilter();
    filter.name = query;
    //@ts-ignore
    return this.post$<JumioPage<TaggingTemplate>>(ExperimentEndpoints.TAGGING_TEMPLATES_SEARCH, filter).pipe(map(val => val.list));
  }

  /**
   * Receives the array of events log details of a given entity from the server.
   * @param {string} reference The ID of the given entity.
   * @returns {Observable<EventLog[]>} An Observable of the array of event log elements.
   */
  public getEventsLog$(reference: string): Observable<EventLog[]> {
    return this.http.get<EventLog[]>(
      (this.isSandboxEnabled ? ExperimentEndpoints.BASE_V3 : ExperimentEndpoints.BASE_V2) + ExperimentEndpoints.EVENTS_LOG(reference)
    );
  }

  public getTaggingTemplate$(id: string): Observable<TaggingTemplate> {
    return this.get$<TaggingTemplate>(ExperimentEndpoints.TAGGING_TEMPLATE(id));
  }

  public previewCrossTableDirectDataset$(imageMetadataSearch: ImageMetadataSearch): Observable<CrossTablePreviewResponse> {
    return this.post$<CrossTablePreviewResponse>(ExperimentEndpoints.CROSS_TABLE_DATASET_QUERY_PREVIEW, imageMetadataSearch);
  }
}
