import {Injectable} from '@angular/core';
import {EnvironmentProvider} from 'public-shared/services/environment-provider';
import {environment} from './environment';
import {IJ4AuthEnvironmentData} from './environment.prod';

@Injectable()
export class AuthEnvironmentProvider extends EnvironmentProvider<IJ4AuthEnvironmentData> {
  public get environment(): IJ4AuthEnvironmentData {
    return environment;
  }
}
