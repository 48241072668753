import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseService} from 'public-shared/services/base-http/base.service';
import {EnvironmentProvider} from 'public-shared/services/environment-provider';
import {Observable} from 'rxjs';
import {JumioPage} from 'shared/components/table/dto/jumio-page';
import {CustomerEnvironmentData} from '@jumio/portals.core';
import {J4GroupEndpoints, J4TenantsEndpoints} from 'shared/services/endpoint-constants';
import {FilterService} from 'shared/services/filter.service';
import {J4GroupsFilter} from 'shared/services/j4-groups/j4-groups-filter.dto';
import {J4GroupFilter} from 'shared/services/j4-groups/j4-groups.dto';
import {J4TenantsFilterInit} from 'shared/services/j4-tenants/j4-tenants-filter.dto';
import {J4TenantsService} from 'shared/services/j4-tenants/j4-tenants.service';

@Injectable()
export class J4GroupsService extends BaseService implements FilterService {
  /**
   * Set base url
   */
  constructor(
    protected override http: HttpClient,
    protected environmentProvider: EnvironmentProvider<CustomerEnvironmentData>,
    private j4TenantsService: J4TenantsService
  ) {
    super(http);
    this.baseUrl = J4GroupEndpoints.BASE;
  }

  /**
   * Get filters
   */
  public initFilter$(): Observable<J4TenantsFilterInit> {
    return this.get$<J4TenantsFilterInit>(J4TenantsEndpoints.TENANTS);
  }

  /**
   * Required for IFilterService
   * Sending the filter values to the backend, receiving the filtered results
   * @param filter
   */
  public filter$(filter: J4GroupsFilter): Observable<JumioPage<J4GroupFilter>> {
    //@ts-ignore
    return this.j4TenantsService.getGroupsByTenant$(filter.tenantId, filter);
  }

  public search$(filter: J4GroupsFilter): Observable<JumioPage<J4GroupFilter>> {
    //@ts-ignore
    return this.j4TenantsService.getGroupsByTenant$(filter.tenantId, filter);
  }
}
