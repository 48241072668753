import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseEndpoints} from 'public-shared/models/endpoints/base-endpoint.constants';
import {Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {BaseService} from '../base-http/base.service';
import {InternalDataDogConfig} from './config.dto';

@Injectable()
export class ConfigService extends BaseService {
  constructor(protected override http: HttpClient) {
    super(http);
  }

  public datadogConfig$(): Observable<Partial<InternalDataDogConfig>> {
    return this.get$<Partial<InternalDataDogConfig>>(BaseEndpoints.INIT_DATADOG_CONFIG).pipe(catchError(() => of({enabled: false})));
  }
}
