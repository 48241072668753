import {BaseErrorCodes} from 'public-shared/models/error/base-error-codes';

/**
 * This class contains the known facade API error codes.
 */
export class ErrorCodes extends BaseErrorCodes {
  // System Error Codes
  // Unknown
  public static readonly UNKNOWN_ERROR_IN_UPSTREAM_SERVICE = 1001;
  public static readonly UNKNOWN_ERROR_IN_AUTH_UPSTREAM_SERVICE = 1002;
  // Communications
  public static readonly TIMEOUT_IN_FACADE = 1010;
  public static override readonly SERVER_IS_DOWN = 1011;
  // Data Format  and Validation
  public static readonly NOT_READABLE_REQUEST = '1020';
  public static readonly INVALID_METHOD = '1022';
  public static readonly INVALID_CSV = '1023';

  // Auth Error Codes
  // General
  public static readonly ACCESS_TOKEN_ALREADY_EXIST = '2000';

  // Feature Error Codes
  // Features: Fraud
  public static readonly CHANNEL_TOKEN_INVALID = '4000';
  public static readonly SCAN_REFERENCE_INVALID = '4001';
  public static readonly FRAUD_ENTRY_DOES_NOT_BELONG_TO_SCAN = '4002';

  // Experiment UI error codes
  public static readonly NO_RESULT_FOUND = '9001';
  public static readonly DUPLICATE_ENTITY = '9002';
  public static readonly INVALID_INPUT_DATA = '9003';
  public static readonly ACTION_NOT_ALLOWED = '9004';
  public static readonly PRIES_ERROR = '9005';
  public static readonly ATHENA_SQL_ERROR = '9006';
  public static readonly NETWORK_ERROR = '9090';
  public static readonly UNKNOWN_ERROR = '9100';
  public static readonly DATASET_MAINTENANCE = '9101';

  // Risk Score error codes
  public static readonly DUPLICATED_RISK_SCORE = '10000';

  // Custom Queue error codes
  public static readonly CUSTOM_Q_GENERIC_ERROR = '20000';
  public static readonly CUSTOM_Q_NAME_ERROR = '20001';
  public static readonly ERROR_CODE_PCILOGQUERY_SERVICE_GET_RESULT_EXCEPTION = '26001';
  public static readonly ERROR_CODE_PCILOGQUERY_SERVICE_GET_RESULT_EXCEPTION_ADMIN = '27001';
}
