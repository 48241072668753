import {Injectable} from '@angular/core';
import {ToastTypes} from '@jtm/jtm-components';
import {AbstractNotificationService} from '../abstract-notification/abstract-notification.service';
import {JtmToastrService} from '@jtm/jtm-services';

@Injectable()
/**
 * Basic service for showing the information box that shows up after a modification request.
 * Uses JTM-toastr
 */
export class ToastrNotificationService extends AbstractNotificationService {
  constructor(private _jtmToastrService: JtmToastrService) {
    super();
  }

  public showSuccess(message: string): void {
    this._jtmToastrService.showToastr({text: message, type: ToastTypes.Success});
  }

  public showError(message: string): void {
    this._jtmToastrService.showToastr({text: message, type: ToastTypes.Error});
  }

  public showWarning(message: string): void {
    this._jtmToastrService.showToastr({text: message, type: ToastTypes.Warning});
  }

  public showInfo(message: string): void {
    this._jtmToastrService.showToastr({text: message, type: ToastTypes.Informative});
  }

  public showNeutral(message: string): void {
    this._jtmToastrService.showToastr({text: message, type: ToastTypes.Neutral});
  }
}
