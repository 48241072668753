import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseService} from 'public-shared/services/base-http/base.service';
import {Observable} from 'rxjs';
import {MaskingEndpoints} from 'shared/services/endpoint-constants';

@Injectable()
export class MaskingService extends BaseService {
  constructor(protected override http: HttpClient) {
    super(http);
    this.baseUrl = MaskingEndpoints.BASE;
  }

  /**
   * Request masking
   * @param {string} scanID
   * @returns {Observable<void>}
   */
  public v2Masking$(scanID: string): Observable<void> {
    return this.put$<void>(MaskingEndpoints.V2_MASKING(scanID), null);
  }

  /**
   * Request masking
   * @param {string} workflowExecutionId
   * @returns {Observable<void>}
   */
  public v3Masking$(workflowExecutionId: string, credentialIds: string[]): Observable<void> {
    return this.put$<void>(MaskingEndpoints.V3_MASKING(workflowExecutionId), {credentialIds});
  }

  /**
   * Request masking
   * @param {string} workflowExecutionId
   * @returns {Observable<void>}
   */
  public kyxMasking$(workflowExecutionId: string, credentialIds: string[]): Observable<void> {
    return this.put$<void>(MaskingEndpoints.KYX_MASKING(workflowExecutionId), {credentialIds});
  }
}
