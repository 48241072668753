import {ICurrentUser, getCurrentTenantName, getCurrentUser} from '@jumio/portals.core';
import {ICommonMetadata} from '../../interfaces/config.interface';

export const createCommonMetadata = (): ICommonMetadata => {
  const defaultString = 'Not available';
  const currentUser: ICurrentUser | {[key: string]: string} = getCurrentUser() || {};

  return {
    user: currentUser?.email || defaultString,
    userId: currentUser?.userId || defaultString,
    tenantId: currentUser?.tenantId || defaultString,
    tenantName: getCurrentTenantName() || defaultString
  };
};
