import {CodeLabel, CodeLabelChecked} from 'public-shared/models/code-label/code-label.dto';
import {BaseDTO, BaseNameDTO} from 'shared/services/base.dto';

export class User extends BaseDTO {
  public userName: string | undefined;
  public email: string | undefined;
  public roles: string[] | undefined;
}

export class UserDetail {
  public id: string | undefined;
  public merchant: BaseNameDTO | undefined;
  public contact: BaseNameDTO | undefined;
  public email: string | undefined;
  public registrationDate: Date | undefined;
  public role: string | undefined;
  public status: string | undefined;
  public verificationStatus: boolean | undefined;
}

export class UserEditDetail {
  public id?: string;
  public accountStatus: CodeLabel | undefined;
  public contactName: string | undefined;
  public createdAt: string | undefined;
  public email: string | undefined;
  public failedLoginAttempts: number | undefined;
  public intruderLockOutUntil: string | null | undefined;
  public jumioUser: boolean | undefined;
  public nextPasswordUpdate: string | undefined;
  public roleModifiable: boolean | undefined;
  public roles: CodeLabel[] | undefined;
  public role: string | undefined;
  public updatedAt: string | undefined;
  public verificationStatus: boolean | undefined;
  public tfaConfirmationStatus: boolean | undefined;
  public tfaEnabled: boolean | undefined;
  public unverifiedEmail: string | undefined;
}

export class UserUpdateRequest {
  public contactName: string | undefined;
  public email: string | undefined;
  public roleName: string | undefined;

  public static fromUserEditDetail(editDetail: UserEditDetail): UserUpdateRequest {
    const obj = new UserUpdateRequest();
    obj.contactName = editDetail.contactName;
    obj.email = editDetail.email;
    obj.roleName = editDetail.role;
    return obj;
  }
}

export class UserAddRequest {
  public contactName: string | undefined;
  public email: string | undefined;
  public roleName: string | undefined;
  public merchantPublicId?: string;
  public uniqueIndex?: number;
}

export class UserBulkOperationRequest {
  public ids: number[] | undefined;

  constructor(ids: string[] | undefined) {
    //@ts-ignore
    this.ids = ids.map(id => +id);
  }
}

export class J4UserAddRequest {
  public userEmail: string | undefined;
  public groupIds: string[] | undefined;
}

export class J4Group {
  public id: string | undefined;
  public label: string | undefined;
}

export class J4Roles {
  public id: string | undefined;
  public label: string | undefined;
  public status: string | undefined;
}

export class J4UserInitResponse {
  public groups: J4Group[] | undefined;
  public roles: J4Roles[] | undefined;
}

export class J4UserUpdateGroupRequest {
  public id: string | undefined;
  public groups: J4Group[] | undefined;
}

export class J4UserDetail {
  public id: string | undefined;
  public email: string | undefined;
  public firstName: string | undefined;
  public lastName: string | undefined;
  public externalProviderId: string | undefined;
  public groups: J4Group[] | CodeLabelChecked[] | undefined;
  public type: string | undefined;
  public verificationStatus: boolean | undefined;
  public registrationDate: string | undefined;
  public updatedAt: string | undefined;
  public status: string | undefined;
}

export const UserTenantState = {
  ENABLED: 'ENABLED',
  DISABLED: 'DISABLED',
  REMOVED: 'REMOVED'
} as const;
