import {InjectionToken} from '@angular/core';
import {IDatadogRumConfig} from '../interfaces/datadog-config.interface';

export const DATADOG_RUM_CONFIG = new InjectionToken<IDatadogRumConfig>('IDatadogRumConfig');

export enum PortalDatadogIdentifier {
  UNIFIED_PORTAL = 'UP',
  CUSTOMER_PORTAL = 'CP'
}

export const DD_BASE_API_URL = new InjectionToken<string>('DD_BASE_API_URL');
