import {NgModule} from '@angular/core';
import {NgClass, NgForOf, NgIf} from '@angular/common';
import {DropdownSelectComponent} from './dropdown-select/dropdown-select.component';
import {DropdownComponent} from './dropdown.component';
import {DateRangeWrapperDirective} from '../daterangepicker/date-range-wrapper.directive';
import {IsEnabledDirective} from '../security/is-enabled.directive';

@NgModule({
  imports: [NgClass, NgIf, NgForOf, DateRangeWrapperDirective, IsEnabledDirective],
  declarations: [DropdownComponent, DropdownSelectComponent],
  exports: [DropdownComponent, DropdownSelectComponent]
})
export class AppDropdownModule {}
