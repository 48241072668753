import {Directive, ElementRef, Input, OnInit, Renderer2} from '@angular/core';
import {SecurityContextHolder} from '../../services/security/security-context-holder';

@Directive({
  selector: '[appIsEnabled]',
  standalone: true
})
/**
 * Disables a given input depending on the input's requirements and the user's permissions.
 */
export class IsEnabledDirective implements OnInit {
  /**
   * The list of roles as a string (separated by commas) or an array of strings that will be required to set the given input enabled.
   */
  @Input('appIsEnabled') public roles: string | Array<string> | undefined;

  /**
   * @param {ElementRef} _elem
   * @param {Renderer2} renderer
   * @param {SecurityContextHolder} contextHolder
   */
  constructor(private _elem: ElementRef, private renderer: Renderer2, private contextHolder: SecurityContextHolder) {}

  /**
   * If the required permissions are given, this function processes them,
   * and checks whether the currently logged in user has at least one of them.
   * If the user has at least one of them, leaves the given input as enabled.
   * Otherwise, sets the given input to disabled.
   */
  ngOnInit(): void {
    if (this.roles) {
      const roles = this.roles instanceof Array ? this.roles : this.roles.split(',');
      const hasRole = roles.some(role => this.contextHolder.authorities.indexOf(role.trim()) >= 0);
      if (!hasRole) {
        this.renderer.setAttribute(this._elem.nativeElement, 'disabled', 'disabled');
      }
    }
  }
}
