import {Injectable} from '@angular/core';
import {ErrorDescriptor} from 'public-shared/models/error/error-descriptor';
import {Subject, Subscription, timer} from 'rxjs';

export class Alert {
  public id: number | undefined;
  public type: string;
  public message: string;
  public showDetails: boolean;
  public errorDescriptor?: ErrorDescriptor;
  public autoClose: boolean;

  constructor(type: string, message: string, autoClose: boolean, errorDescriptor?: ErrorDescriptor) {
    this.type = type;
    this.message = message;
    this.autoClose = autoClose;
    this.showDetails = false;
    this.errorDescriptor = errorDescriptor;
  }
}

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  public alerts: Alert[] = [];
  public state: Subject<Alert[]> = new Subject<Alert[]>();
  public subscriptions: Subscription[] = [];
  public timeout = 5000;

  public success(msgKey: string): void {
    this.showMessage(new Alert('success', msgKey, true));
  }

  public error(error: ErrorDescriptor): void {
    const alert = new Alert('danger', '', false, error);
    if (!this.isDuplicatedError(alert)) {
      this.showMessage(alert);
    }
  }

  public warn(msgKey: string): void {
    this.showMessage(new Alert('warning', msgKey, false));
  }

  public clear(): void {
    this.alerts = [];
    this.state.next(this.alerts);
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  public close(alert: Alert): void {
    const alertIndex = this.alerts.findIndex(val => val.id === alert.id);
    this.alerts.splice(alertIndex, 1);
    this.state.next(this.alerts);

    // The user closed the message so unsubscribe from Timer,
    if (alert.autoClose) {
      this.unsubscribeTimer(alertIndex);
    }
  }

  private showMessage(alert: Alert): void {
    alert.id = this.alerts.push(alert);
    this.state.next(this.alerts);
    if (alert.autoClose) {
      this.setTimer(alert);
    }
  }

  private isDuplicatedError(newAlert: Alert): Alert | undefined {
    return this.alerts.find(alert => alert.errorDescriptor && alert.errorDescriptor.id === newAlert.errorDescriptor?.id);
  }

  private setTimer(alert: Alert): void {
    this.subscriptions.push(timer(this.timeout).subscribe(() => this.close(alert)));
  }

  private unsubscribeTimer(alertIndex: number): void {
    if (this.subscriptions[alertIndex] !== undefined) {
      this.subscriptions[alertIndex].unsubscribe();
    }
  }
}
