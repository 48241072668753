export abstract class AbstractNotificationService {
  public abstract showSuccess(_message: string): void;

  public abstract showError(_message: string): void;

  public abstract showWarning(_message: string): void;

  public abstract showInfo(_message: string): void;

  public abstract showNeutral(_message: string): void;
}
