import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {IPortalConfig, PORTAL_DATA} from '@jumio/portals.core';
import {BaseEndpoints} from 'public-shared/models/endpoints/base-endpoint.constants';
import {Observable} from 'rxjs';
import {CacheService} from 'shared/services/common/cache.service';
import {MerchantEndpoints} from 'shared/services/endpoint-constants';
import {LocaleWithImageUrlList} from 'shared/services/merchant/customize-client-redirect.dto';
import {MerchantService} from 'shared/services/merchant/merchant.service';
import {SecurityContextHolder} from 'shared/services/security/security-context-holder';

/**
 * V2 Override for Merchant Service
 */
@Injectable()
export class MerchantV2Service extends MerchantService {
  constructor(
    protected override contextHolder: SecurityContextHolder,
    protected override http: HttpClient,
    protected override cache: CacheService,
    @Inject(PORTAL_DATA) protected override portalConfig: IPortalConfig
  ) {
    super(contextHolder, http, cache, portalConfig);
    this.baseUrl = this.isAdminPortal ? MerchantEndpoints.BASEV2 : BaseEndpoints.SETTINGSV2;
  }

  /**
   * Sends a modification request for the client customization
   * @param id The ID of the merchant.
   * @param formData The FormData
   * @returns {Observable<LocaleWithImageUrlList>}
   */
  public modifyCustomizeClientV2$(id: string, formData: FormData): Observable<LocaleWithImageUrlList> {
    return this.put$<LocaleWithImageUrlList>(this.prefix(id) + MerchantEndpoints.CUSTOMIZE_CLIENT_GENERAL, formData);
  }
}
