import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseService, EntityWithPassword} from 'public-shared/services/base-http/base.service';
import {Observable} from 'rxjs';
import {MerchantIntegrationSettingsEndpoints} from 'shared/services/endpoint-constants';
import {IntegrationSettings} from './merchant-settings.dto';

/**
 * The service responsible for sending and receiving Merchant data between the client and server.
 */
@Injectable()
export class MerchantIntegrationSettingsService extends BaseService {
  constructor(protected override http: HttpClient) {
    super(http);
    this.baseUrl = MerchantIntegrationSettingsEndpoints.BASE;
  }

  public getIntegrationSettingslist$(merchantid: string): Observable<IntegrationSettings[]> {
    return this.get$<IntegrationSettings[]>(MerchantIntegrationSettingsEndpoints.MERCHANT_SETTINGS(merchantid));
  }

  public updateIntegrationSettings$(
    merchantid: string,
    payload: IntegrationSettings[],
    password: string,
    passCode?: string
  ): Observable<void> {
    return this.post$<void>(
      MerchantIntegrationSettingsEndpoints.MERCHANT_SETTINGS(merchantid),
      new EntityWithPassword(payload, password, passCode)
    );
  }
}
