import {CodeLabel} from 'public-shared/models/code-label/code-label.dto';
import {Country} from '../country/country.dto';
import {WithMerchantName} from './with-merchant-name';

export class MerchantAcceptedIdsCountry implements WithMerchantName {
  public active: boolean | undefined;
  public merchantName: string | undefined;
  public countries: AcceptedId[] | undefined;
  public acceptNewlySupportedIds: boolean | undefined;

  public static clone(settings: MerchantAcceptedIdsCountry): MerchantAcceptedIdsCountry {
    const newSettings = new MerchantAcceptedIdsCountry();
    newSettings.active = settings.active;
    newSettings.merchantName = settings.merchantName;
    newSettings.acceptNewlySupportedIds = settings.acceptNewlySupportedIds;
    newSettings.countries = settings.countries;
    return newSettings;
  }
}

export class AcceptedId {
  public country: Country | undefined;
  public region: CodeLabel | undefined;
  public idCardApproved: boolean | undefined;
  public idCardSupported: boolean | undefined;
  public passportApproved: boolean | undefined;
  public passportSupported: boolean | undefined;
  public driverLicenseApproved: boolean | undefined;
  public driverLicenseSupported: boolean | undefined;
  public visaApproved?: boolean | undefined;

  public static compareByRegionCode(a: AcceptedId, b: AcceptedId): number {
    //@ts-ignore
    return a.region && b.region ? a.region.code.localeCompare(b.region.code) : 0;
  }
}

export class AcceptedIdRequest {
  public country: number | undefined;
  public idCardApproved: boolean | undefined;
  public passportApproved: boolean | undefined;
  public driverLicenseApproved: boolean | undefined;
  public visaApproved?: boolean;

  constructor(acceptedId: AcceptedId) {
    //@ts-ignore
    this.country = +acceptedId.country.code;
    this.idCardApproved = acceptedId.idCardApproved;
    this.passportApproved = acceptedId.passportApproved;
    this.driverLicenseApproved = acceptedId.driverLicenseApproved;
    this.visaApproved = acceptedId.visaApproved;
  }
}

export class AcceptedIdsCountryRequest {
  public active: boolean | undefined;
  public countries: AcceptedIdRequest[] | undefined;
  public acceptNewlySupportedIds: boolean | undefined;

  constructor(ids: MerchantAcceptedIdsCountry) {
    this.active = ids.active;
    this.acceptNewlySupportedIds = ids.acceptNewlySupportedIds;
    if (ids.countries) {
      this.countries = ids.countries.map(id => new AcceptedIdRequest(id));
    }
  }
}

export class AcceptedIdsFilter {
  public countryCode: string | undefined;
}
