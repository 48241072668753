import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseService} from 'public-shared/services/base-http/base.service';
import {Observable} from 'rxjs';
import {DocumentsEndpoints} from 'shared/services/endpoint-constants';
import {Documents} from './documents.dto';

@Injectable()
export class DocumentsService extends BaseService {
  constructor(protected override http: HttpClient) {
    super(http);
    this.baseUrl = DocumentsEndpoints.BASE;
  }

  public getDocuments$(): Observable<Documents> {
    return this.get$(DocumentsEndpoints.DOCUMENTS);
  }
}
