import {SortPropDir} from '@swimlane/ngx-datatable';
import {JumioSort} from 'shared/components/table/dto/jumio-sort';

/**
 * The Pageable is contains the server request initial values
 */
export class JumioPageFilter {
  /**
   * The sort properties
   */
  public sort: JumioSort | undefined;
  /**
   * The offset to skip
   */
  public offset: number | undefined = 0;
  /**
   * The items number we need to load
   */
  public pageSize: number | undefined;

  /**
   * Clone pageable values.
   * Set offset to 0, since paging will reset when the "Search" button is pressed.
   * @param pageable
   */
  public cloneValues(pageable: JumioPageFilter): void {
    this.offset = 0;
    this.pageSize = pageable.pageSize;
    this.sort = pageable.sort;
  }
}

export class ClientFilter {
  public curPage?: number;
  public sort?: SortPropDir;
}
