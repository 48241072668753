import {CommonModule} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {AlertModule} from 'ngx-bootstrap/alert';
import {CookieConsentBarComponent} from './components/cookie-consent-bar/cookie-consent-bar.component';
import {DATADOG_RUM_CONFIG, DD_BASE_API_URL} from './constants/datadog-init.constants';
import {WINDOW} from './constants/window.const';
import {IDatadogRumConfig} from './interfaces/datadog-config.interface';
import {DatadogRumLoggerService} from './services/datadog-rum-logger.service';
import {ConfigService} from './services/http-services/config.service';

@NgModule({
  declarations: [CookieConsentBarComponent],
  imports: [CommonModule, FormsModule, HttpClientModule, ReactiveFormsModule, AlertModule.forRoot()],
  providers: [
    {
      provide: DD_BASE_API_URL,
      useFactory: (win: Window): string => win.location.origin.concat('/api'),
      deps: [WINDOW]
    },
    ConfigService,
    DatadogRumLoggerService
  ],
  exports: [HttpClientModule, CookieConsentBarComponent]
})
export class JumioDatadogRumModule {
  constructor(@Optional() @SkipSelf() parentModule: JumioDatadogRumModule) {
    if (parentModule) {
      throw new Error('JumioDatadogRumModule has already been imported.');
    }
  }

  public static forRoot(config: IDatadogRumConfig): ModuleWithProviders<JumioDatadogRumModule> {
    return {
      ngModule: JumioDatadogRumModule,
      providers: [
        {provide: DATADOG_RUM_CONFIG, useValue: config},
        {
          provide: DatadogRumLoggerService,
          useFactory: (conf: IDatadogRumConfig, configService: ConfigService): DatadogRumLoggerService => {
            const serviceInstance = new DatadogRumLoggerService(conf, configService);
            serviceInstance.load();

            return serviceInstance;
          },
          deps: [DATADOG_RUM_CONFIG, ConfigService]
        }
      ]
    };
  }
}
