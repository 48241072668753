import {CodeLabel} from 'public-shared/models/code-label/code-label.dto';
import {BaseNameDTO} from 'shared/services/base.dto';
import {J4Tenant} from 'shared/services/j4-tenants/j4-tenant.dto';
import {WithMerchantName} from './with-merchant-name';

export class Merchant extends BaseNameDTO {
  public publicId?: string;
  public createdAt?: Date;
  public enabled?: boolean;
  public merchantId?: string;

  public static toRequestDto(merchant: Merchant, withEnabled = true): Merchant {
    const dto = new Merchant();
    if (withEnabled) {
      dto.enabled = merchant.enabled;
    }
    dto.name = merchant.name;
    return dto;
  }

  public static fromJ4Tenant(tenant: J4Tenant): Merchant {
    const merchant = new Merchant();
    merchant.id = tenant.customerId;
    merchant.merchantId = tenant.merchantId;
    merchant.name = tenant.name;
    merchant.publicId = tenant.id;
    merchant.enabled = tenant.status === 'ENABLED';
    //@ts-ignore
    merchant.createdAt = new Date(tenant.createdAt);
    return merchant;
  }
}

export class MerchantTypes {
  public types: CodeLabel[] | undefined;
}

export class MerchantDetail implements WithMerchantName {
  public merchantName: string | undefined;
  public merchantType: string | undefined;
  public contactName: string | undefined;
  public email: string | undefined;
}

export class AccountDuplicationPayload {
  public password: string | undefined;
  public entity:
    | {
        merchantName: string;
        email: string;
        isTenant: boolean;
      }
    | undefined;
}
