import {Injectable} from '@angular/core';
import {BaseSecurityContextHolder} from 'public-shared/services/security/base-security-context-holder';
import {Permission} from '@jumio/portals.core';

@Injectable()
/**
 * This service holds the user's context values that are needed during the life cycle of the application.
 */
export class SecurityContextHolder extends BaseSecurityContextHolder {
  public static readonly NETVERIFY = 'NETVERIFY';

  /**
   * Within a role list, does the user have at least one role
   * @param roles
   */
  public hasAnyRole(roles: string[]): boolean {
    return roles.some(r => this.hasRole(r));
  }

  public hasRole(role: string): boolean {
    return this.authorities.indexOf(role.trim()) > -1;
  }

  /**
   * Returns the product type depending the current user's roles.
   * @returns {string}
   */
  public get productType(): string {
    return SecurityContextHolder.NETVERIFY;
  }

  public get isExperimentUser(): boolean {
    //@ts-ignore
    return (
      this.user &&
      this.user.authorities &&
      this.authorities.length > 0 &&
      (this.authorities.includes(Permission.EXPERIMENT_UI_ADMIN) || this.authorities.includes(Permission.EXPERIMENT_UI_ENGINEER))
    );
  }

  /**
   * The current user's username.
   * @returns {string}
   */
  public get name(): string | undefined | null {
    return this._user ? this._user.name : null;
  }

  /**
   * The current user's username.
   * @returns {string}
   */
  public get username(): string | undefined | null {
    return this._user ? this._user.username : null;
  }

  /**
   * The permissions given to the current user after login.
   * @returns {Array<string>}
   */
  public get authorities(): Array<string> {
    // the authorities is only used in the CP app, remove it after the CP is turned off
    return this._user?.authorities ?? this._user?.permissions ?? [];
  }

  /**
   * Checks whether TFA (Two-Factor Authentication) is already set on the current MUI app.
   * @returns {boolean}
   */
  public get isFirstSetupTfaQrCode(): boolean {
    return !!this._user?.firstSetupTfaQrCode;
  }

  public setIsFirstSetupTfaQrCode(value: string | null): void {
    //@ts-ignore
    this._user.firstSetupTfaQrCode = value;
    localStorage.setItem(SecurityContextHolder.LOCAL_STORAGE_CURRENT_USER, JSON.stringify(this._user));
  }

  /**
   * Checks whether TFA (Two-Factor Authentication) is enabled on the current MUI app.
   * @returns {boolean}
   */
  public get isTfaEnabled(): boolean | undefined | null {
    return this._user ? this._user.tfaEnabled : false;
  }

  /**
   * Returns user Refresh Token
   * @returns {string}
   */
  public get refreshToken(): string | undefined | null {
    return this._user ? this._user.refreshToken : null;
  }

  /**
   * If the first time TFA-setup screen has to be shown, this value contains the QR code to be displayed.
   * @returns {string}
   */
  public get qrCode(): string | undefined | null {
    return this._user ? this._user.firstSetupTfaQrCode : null;
  }
}
