/**
 * This class contains the known facade API error codes.
 */
export class BaseErrorCodes {
  // System Error Codes
  public static readonly SERVER_IS_DOWN = 1011;
  public static readonly UNKNOWN_ERROR_IN_FACADE = 1000;
  public static readonly INVALID_REQUEST = '1021';
  public static readonly BAD_CREDENTIALS = '2001';
  public static readonly CREDENTIALS_EXPIRED = '2002';
  public static readonly TOKEN_INVALID = '3002';
  public static readonly SERVICE_UNAVAILABLE = '7001';

  // Feature Error Codes
  // Features: All
  public static readonly ACCESS_DENIED = '3000';
  public static readonly NOT_FOUND = '3001';

  // Account
  public static readonly ACCOUNT_LOCKED = '2010';
  public static readonly ACCOUNT_DISABLED = '2011';
  public static readonly ACCOUNT_EXPIRED = '2012';
  public static readonly NO_KEY_FOUND = '2013';
}
