import {NgModule} from '@angular/core';
import {ApiVersionStoreService} from './services/api-version/api-version-store.service';
import {ForceReloadService} from './services/force-reload/force-reload-modal.service';
import {ModalComponent} from '@jumio/portals.core-ui';
import {LocalStorageService} from 'public-shared/services/local-storage/local-storage.service';

@NgModule({
  imports: [ModalComponent],
  providers: [ApiVersionStoreService, ForceReloadService, LocalStorageService],
  exports: [ModalComponent]
})
export class ForceReloadModule {}
