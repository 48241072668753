import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {DomSanitizer, SafeStyle, SafeUrl} from '@angular/platform-browser';
import {CustomerEnvironmentData, UntilDestroy} from '@jumio/portals.core';
import {InterceptorHttpParams} from 'public-shared/interceptors/http-interceptor/public-custom-http.interceptor';
import {BaseService} from 'public-shared/services/base-http/base.service';
import {EnvironmentProvider} from 'public-shared/services/environment-provider';
import {Observable, Observer, Subscription} from 'rxjs';
import {ImageEndpoints} from 'shared/services/endpoint-constants';

@UntilDestroy()
@Injectable()
export class ImageService extends BaseService {
  public readonly subscription = new Subscription();

  constructor(
    protected override http: HttpClient,
    private domSanitizer: DomSanitizer,
    private environmentProvider: EnvironmentProvider<CustomerEnvironmentData>
  ) {
    super(http);
    this.baseUrl = ImageEndpoints.BASE;
  }

  /**
   * Deletes a given image.
   * @param imageId The ID of the image.
   */
  public deleteImage$(imageId: string): Observable<void> {
    return this.get$<void>(ImageEndpoints.DELETE(imageId));
  }

  /**
   * Request masking
   * @param {string} imageId
   * @returns {Observable<void>}
   */
  public requestMasking$(imageId: string): Observable<void> {
    return this.get$<void>(ImageEndpoints.REQUEST_MASKING(imageId));
  }

  /**
   * Returns an Observable which will contain the image's URL embedded in a {@link SafeUrl} object.
   * After creating the Observable, we need to give a value to the Observer until the image request subscription doesn't finish.
   * This will be an empty string to start with, so until the image is not loaded, the app will show a small white image.
   * After the request finishes, the method uses a {@link FileReader} to read the Blob as a Base64 data URL.
   * Finally, it passes the sanitized {@link SafeUrl} object to the Observer, and completes the process.
   * If the subscription returned with an error, it discards reading the error (an empty thumbnail will be shown).
   * @param {string} url The URL of the image that will be requested from the server.
   * @param {boolean} ignoreEmpty: If set, then if nothing is returned from the BE, an empty Blob will not be created
   * @returns {Observable<SafeUrl>} An Observable containing the received image's URL embedded in a SafeUrl object.
   */
  public getImage$(url: string, ignoreEmpty = false): Observable<SafeUrl> {
    return new Observable<SafeUrl>((observer: Observer<SafeUrl>) => {
      observer.next('');

      this.subscription.add(
        this.getImageFile$(url).subscribe(
          (blob: Blob | null) => {
            if (blob) {
              this.resolveBlob(blob, observer, ignoreEmpty);
            } else {
              observer.complete();
            }
          },
          () => {
            observer.error(null);
          }
        )
      );
    });
  }

  public getBackgroundImage$(url: string): Observable<SafeStyle> {
    return new Observable<SafeStyle>((observer: Observer<SafeStyle>) => {
      observer.next('');

      this.subscription.add(
        this.getImageFile$(url).subscribe(
          (blob: Blob) => {
            this.resolveBlobToSyle(blob, observer);
          },
          () => {
            observer.error(null);
          }
        )
      );
    });
  }

  public blobToSafeUrl$(blob: Blob): Observable<SafeUrl> {
    return new Observable<SafeUrl>((observer: Observer<SafeUrl>) => {
      observer.next('');
      this.resolveBlob(blob, observer);
    });
  }

  public blobToSafeStyle$(blob: Blob): Observable<SafeStyle> {
    return new Observable<SafeStyle>((observer: Observer<SafeStyle>) => {
      observer.next('');
      this.resolveBlobToSyle(blob, observer);
    });
  }

  public getImageFile$(url: string): Observable<Blob> {
    const httpOptions = this.getOptions(url);
    //@ts-ignore
    return this.http.get(httpOptions.url, {params: httpOptions.params, responseType: 'blob'});
  }

  private resolveBlob(blob: Blob, observer: Observer<SafeUrl>, ignoreEmpty = false): void {
    if (ignoreEmpty && blob && blob.size === 0) {
      observer.complete();
      return;
    }
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = (): void => {
      //@ts-ignore
      observer.next(this.domSanitizer.bypassSecurityTrustUrl(reader.result?.toString()));
      observer.complete();
    };
  }

  private resolveBlobToSyle(blob: Blob, observer: Observer<SafeStyle>): void {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = (): void => {
      observer.next(this.domSanitizer.bypassSecurityTrustStyle(`url(${reader.result as string})`));
      observer.complete();
    };
  }

  private getOptions(url: string): ImageHttpOptions {
    const options = new ImageHttpOptions();
    const divider = 'api/';

    const params = new InterceptorHttpParams();
    if (this.environmentProvider.environment.skipImageApiUrl) {
      // Since image URLs are full urls, we can skip adding the API url to it in the Http Interceptor
      params.skipApiUrl = true;
    } else {
      // Remove unnecessary parts from URL to make it usable in DEV mode
      url = url.slice(url.indexOf(divider) + divider.length, url.length);
      if (url[0] === '/') {
        url = url.slice(1);
      }
    }

    options.params = params;
    options.url = url;
    return options;
  }
}

class ImageHttpOptions {
  public url: string | undefined;
  public params: InterceptorHttpParams | undefined;
}
