import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {HeaderBarBreadcrumb} from '../kyx-header-bar/kyx-header-bar.component';

/**
 * Helps passing data through all the Navigation tab subpages
 */
@Injectable()
export class NavButtonHelperService {
  public changeEmitted$: Observable<HeaderBarBreadcrumb[]>;
  private _emitChangeSource = new Subject<HeaderBarBreadcrumb[]>();

  constructor() {
    this.changeEmitted$ = this._emitChangeSource.asObservable();
  }

  public emitNewHeaderBreadcrumb(change: HeaderBarBreadcrumb[]): void {
    this._emitChangeSource.next(change);
  }
}
