import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {APP_INITIALIZER, InjectionToken, ModuleWithProviders, NgModule} from '@angular/core';
import {appVersion, commitHash} from '@jumio/portals.app-version';
import {ApiVersionInterceptor} from './interceptors/api-version.interceptor';
import {ApiVersionStoreService} from './services/api-version/api-version-store.service';
import {AppType} from './services/api-version/app-version';

export const BASE_PATH = new InjectionToken<string>('BASE_PATH');
export const API_TYPE = new InjectionToken<string>('API_TYPE');

@NgModule()
export class ProvideApiVersionModule {
  public static forRoot(basePath: string = '/', apiType = 'cp'): ModuleWithProviders<ProvideApiVersionModule> {
    return {
      ngModule: ProvideApiVersionModule,
      providers: [
        {
          provide: BASE_PATH,
          useValue: basePath
        },
        {
          provide: API_TYPE,
          useValue: apiType
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ApiVersionInterceptor,
          multi: true
        },
        {
          provide: APP_INITIALIZER,
          useFactory: (apiVersionStoreService: ApiVersionStoreService) => () =>
            new Promise<void>(resolve => {
              apiVersionStoreService.setCurrentApiVersion(AppType.CLIENT_UI, appVersion, basePath, commitHash);
              resolve();
            }),
          deps: [ApiVersionStoreService],
          multi: true
        }
      ]
    };
  }
}
