import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseService} from 'public-shared/services/base-http/base.service';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {CustomQueuesEndpoints, MerchantEndpoints} from 'shared/services/endpoint-constants';
import {
  CreateQueueRequest,
  CustomQueuePriorityResponse,
  QueueItemResponse,
  UpdateQueueRequest
} from 'shared/services/merchant/custom-queues.dto';
import {Merchant} from 'shared/services/merchant/merchant.dto';
import {MerchantSearchQuery} from 'shared/services/merchant/merchant.service';

@Injectable()
export class CustomQueuesService extends BaseService {
  constructor(protected override http: HttpClient) {
    super(http);
  }

  public getQueuePriorityData$(): Observable<CustomQueuePriorityResponse> {
    return this.get$<CustomQueuePriorityResponse>(CustomQueuesEndpoints.INIT);
  }

  public getCustomQueues$(): Observable<Array<QueueItemResponse>> {
    return this.get$<Array<QueueItemResponse>>(CustomQueuesEndpoints.BASE);
  }

  public createCustomQueue$(body: CreateQueueRequest): Observable<Array<QueueItemResponse>> {
    return this.post$<Array<QueueItemResponse>>(CustomQueuesEndpoints.CREATE, body);
  }

  public updateCustomQueue$(body: UpdateQueueRequest): Observable<Array<QueueItemResponse>> {
    return this.post$<Array<QueueItemResponse>>(CustomQueuesEndpoints.UPDATE, body);
  }

  public deleteCustomQueue$(queueName: string): Observable<void> {
    return this.delete$<void>(CustomQueuesEndpoints.DELETE(queueName));
  }

  /**
   * Sends a search query to the backend for receiving a list of merchants. The name is extended by the ID as well.
   * Used by the typeahead component
   * @param query The query string we send towards the backend,
   * @returns {Observable<Array<Merchant>>} An Observable of merchants that met the search query.
   */
  public search$(query: string): Observable<Array<Merchant>> {
    return this.post$<Array<Merchant>>(MerchantEndpoints.BASE + MerchantEndpoints.SEARCH, new MerchantSearchQuery(query)).pipe(
      map(this.merchantMapper)
    );
  }

  /**
   * Maps every merchant.name -> merchant.name + merchant.id
   */
  public merchantMapper(merchants: Array<Merchant>): Array<Merchant> {
    merchants.forEach(merchant => (merchant.name = merchant.name + ' [' + merchant.id + ']'));
    return merchants;
  }
}
