import {Component, ViewChild, inject, input} from '@angular/core';
import {AlertComponent} from 'ngx-bootstrap/alert';
import {saveTokenCookiesClaimToLocalStorage} from '../../helpers/cookies-consent/cookies-consent-url.helper';
import {getCookiesClaimFromToken} from '../../helpers/jwt-token/jwt-token.helper';
import {
  areCookiesAllowed,
  getAccessToken,
  isCookiePreferenceSet,
  setCookiePreference
} from '../../helpers/local-storage/local-storage.helper';
import {DatadogRumLoggerService} from '../../services/datadog-rum-logger.service';

@Component({
  selector: 'app-cookie-consent-bar',
  templateUrl: './cookie-consent-bar.component.html',
  styleUrls: ['./cookie-consent-bar.component.less']
})
export class CookieConsentBarComponent {
  @ViewChild('alertBox') public alertBox: AlertComponent | undefined;
  public checkTokenClaim = input(false);

  public readonly privacyPolicyUrl =
    'https://www.jumio.com/legal-information/privacy-policy/jumio-corp-privacy-policy-for-online-services/';

  private datadogService = inject(DatadogRumLoggerService);

  public accept(): void {
    setCookiePreference('true');
    this.datadogService.cookiesAccepted$.next(true);
    this.alertBox?.close();
  }

  public decline(): void {
    setCookiePreference('false');
    this.datadogService.cookiesAccepted$.next(false);
    this.alertBox?.close();
  }

  public get showCookieConsentPopup(): boolean {
    // Set cookies token claim to local storage - if flag is enabled
    if (this.checkTokenClaim()) {
      try {
        this.setCookiesConsent();
      } catch (error) {
        return false;
      }
    }

    return !isCookiePreferenceSet();
  }

  private setCookiesConsent(): void {
    const token = getAccessToken();
    const cookiesClaim = getCookiesClaimFromToken(token);

    if (cookiesClaim !== undefined) {
      saveTokenCookiesClaimToLocalStorage(cookiesClaim);
      const cookiesAllowed = areCookiesAllowed();

      this.datadogService.cookiesAccepted$.next(cookiesAllowed);
    }
  }
}
