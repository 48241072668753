import {LOCALSTORAGE_CONST} from '../constants/localstorage.constants';
import {ICurrentTenant, ICurrentUser} from '../interfaces/localstorage.interface';

export const getCurrentTenantName = (): string => {
  const currentUser: ICurrentUser | {[key: string]: string | string[]} = getCurrentUser();
  const currentTenantId = currentUser?.tenantId;

  const tenants = (currentUser?.tenants as ICurrentTenant[]) || [];

  return tenants.filter((t: {tenantId: string; tenantName: string}) => t.tenantId === currentTenantId)[0]?.tenantName;
};

export const getCurrentTenantId = (): string => {
  const currentUser: ICurrentUser | {[key: string]: string} = getCurrentUser();
  return currentUser?.tenantId;
};

export const getCurrentUserEmail = (): string => {
  const currentUser: ICurrentUser | {[key: string]: string} = getCurrentUser();
  return currentUser?.email;
};

export const getCurrentUser = (): ICurrentUser | {[key: string]: string} => {
  try {
    // Is payload is a valid json string
    return JSON.parse(localStorage.getItem(LOCALSTORAGE_CONST.CURRENT_USER) ?? '{}');
  } catch (_error) {
    // Otherwise send empty object
    return {};
  }
};
