<div class="overlay" *ngIf="isBusy">
  <j4-loader></j4-loader>
</div>
<app-j4-auth-container>
  <!-- if tenant list is null (no login response), the tenant selection form should not be available -->
  <form *ngIf="tenants" [formGroup]="form">
    <div class="title">Sign in</div>
    <div class="fields">
      <p class="email-text">{{ email }}</p>
      <div class="form-group-input-label dropdown-title">Select organization account</div>
      <!-- if tenant list is empty, dropdown should be disabled but visible -->
      <jtm-dropdown
        data-cy="merchant-select"
        [options]="$any(tenants)"
        optionSelector="label"
        [isSearchable]="false"
        [isIconEnabled]="false"
        [itemHeight]="40"
        [label]="'label'"
        [isDisabled]="!tenants.length"
        [isSearchable]="true"
        (optionSelected)="onOptionSelected($any($event))"
      >
      </jtm-dropdown>
      <!-- if tenant list is empty, inform that user has no tenants -->
      <div *ngIf="!tenants.length" class="login-error-row">
        <div class="login-error-text">User is not assigned to any tenant</div>
      </div>
      <div class="login-row">
        <!-- if tenant list is empty, button should be disabled -->
        <jtm-button
          (clicked)="directToAuth0Login()"
          [type]="buttonTypes.Button"
          [disabled]="formIsInvalid() || !tenants.length"
          [autoWidth]="true"
          [size]="buttonSizes.Large"
        >
          Continue
        </jtm-button>
      </div>
    </div>
  </form>
  <div *ngIf="showGoBackToLoginButton" class="login-row">
    <!-- if tenant list is null (no login response), then something's wrong - user should be able to restart login flow -->
    <div *ngIf="!tenants" class="something-wrong error-wrapper">Something went wrong!</div>
    <!-- Button to trigger auth0 logout and restart login flow - should be availabke even if user can see the list of tenants -->
    <jtm-button
      (clicked)="goToLoginPage()"
      [type]="buttonTypes.Button"
      [autoWidth]="true"
      [size]="buttonSizes.Large"
      [variant]="buttonVariants.Secondary"
    >
      Go back to Login
    </jtm-button>
  </div>
</app-j4-auth-container>
