import {inject, Injectable} from '@angular/core';
import {WINDOW} from '@jumio/portals.core';
import {LocalStorageService} from 'public-shared/services/local-storage/local-storage.service';
import {AppType, IVersion} from './app-version';

/**
 * The goal of this service is to store the client and facade versions and compare it
 */
@Injectable()
export class ApiVersionStoreService {
  private window: Window & {deployedAppVersions?: IVersion[]} = inject(WINDOW);
  private localStorageService = inject(LocalStorageService);

  private appVersionsList: IVersion[] = this.localStorageService.getAppVersionList() ?? [];

  public isInitialVersion(appType: AppType, version: string | null, path: string | undefined): boolean {
    if (!version) {
      return true;
    }

    const currentServiceVersion: IVersion | undefined = this.localStorageService.getAppVersionList().find(app => app.path === path);

    const currentVersion: string | undefined = currentServiceVersion ? currentServiceVersion[appType]?.version : '';

    if (currentVersion !== version) {
      this.setCurrentApiVersion(appType, version, path);
      return true;
    }

    return currentVersion === version;
  }

  public setCurrentApiVersion(appType: AppType, version: string, path: string = '/', commitHash: string = ''): void {
    const versionCommit = {version, commitHash};

    const appFound: IVersion | undefined = this.appVersionsList.find((app: IVersion) => app.path === path);
    if (appFound) {
      appFound[appType] = versionCommit;
    } else {
      const newVersionCommit: IVersion = {path};
      newVersionCommit[appType] = versionCommit;
      this.appVersionsList.push(newVersionCommit);
    }

    this.localStorageService.setAppVersionList(this.appVersionsList);
    this.window['deployedAppVersions'] = this.appVersionsList;
  }
}
