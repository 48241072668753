import {Component, Input} from '@angular/core';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-j4-auth-container',
  templateUrl: './auth-container.component.html',
  styleUrls: ['./auth-container.component.less']
})
export class J4AuthContainerComponent {
  @Input() public isWide = false;
  @Input() public noSpaceTop = false;

  // TODO: Follow up ticket: https://jira.int.jumio.com/browse/PORT-871
  // current year should be fetched from API, the end-user's system clock may not provide the correct time.
  public readonly currentYear = new Date().getFullYear();

  public readonly IMAGES_PREFIX = `${environment.deployUrl}assets/images/`;
  public readonly LOGOS = {
    JUMIO: 'jumio_logo.png'
  };
  public readonly logoUrl = this.IMAGES_PREFIX + this.LOGOS.JUMIO;
}
