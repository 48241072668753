import {AbstractControl, ValidatorFn} from '@angular/forms';

export const numberSizeValidator =
  (min: number, max: number): ValidatorFn =>
  (control: AbstractControl): {[key: string]: any} | null => {
    if (control.value) {
      return control.value < min || control.value > max ? {numberSize: true} : null;
    }
    return null;
  };
