import {SafeUrl} from '@angular/platform-browser';
import {Annotation} from 'shared/services/common/annotation.dto';

/**
 * Base image struct for SmartCanvas
 */
export class SmartCanvasBaseImage {
  // Source of the image
  public source: SafeUrl;

  // Label of the image (aligned left)
  // Can be used for thumbnail bar or other places
  // Can be empty
  public label?: string;

  // Note of the image (aligned right)
  // Can be empty
  public note?: string;

  // Does the image has any annotation
  // Can be empty
  public annotations?: Annotation[];

  // Image's unique id
  // Can be empty
  public uniqueId?: string;

  // If the image is only relevant for the code but not for the thumbnail bar
  public hideFromThumbnailBar?: boolean;

  public stepId?: string;

  constructor(source: SafeUrl, label?: string) {
    this.source = source;
    this.label = label;
  }
}
