import {CookiesClaimType} from '../jwt-token/jwt-token.helper';
import {DatadogLocalStorageKeys} from './local-storage.helper.dto';

export const setCookiePreference = (cookiesAreAllowed: CookiesClaimType): void => {
  setItem(DatadogLocalStorageKeys.COOKIE_PREFERENCE_SET, 'true');
  setItem(DatadogLocalStorageKeys.COOKIES_ALLOWED, cookiesAreAllowed);
};

export const areCookiesAllowed = (): boolean =>
  !!getItem(DatadogLocalStorageKeys.COOKIE_PREFERENCE_SET) && getItem(DatadogLocalStorageKeys.COOKIES_ALLOWED) === 'true';

export const isCookiePreferenceSet = (): boolean => !!getItem(DatadogLocalStorageKeys.COOKIE_PREFERENCE_SET);

export const clearCookiePreferences = (): void => {
  removeItem(DatadogLocalStorageKeys.COOKIES_ALLOWED);
  removeItem(DatadogLocalStorageKeys.COOKIE_PREFERENCE_SET);
};

export const cookiesAllowed = (): string | null => getItem(DatadogLocalStorageKeys.COOKIES_ALLOWED);

export const getAccessToken = (): string => {
  const currentUser = localStorage.getItem('currentUser');
  const {accessToken} = currentUser ? JSON.parse(currentUser) : '';

  return accessToken;
};

/**
 * Custom getItem for error handling
 */
const getItem = (key: DatadogLocalStorageKeys): string | null => localStorage?.getItem(key);

/**
 * Custom setItem for error handling
 */
const setItem = (key: DatadogLocalStorageKeys, value: string): void => {
  localStorage?.setItem(key, value);
};

/**
 * Custom removeItem for error handling
 */
const removeItem = (key: DatadogLocalStorageKeys): void => {
  localStorage?.removeItem(key);
};
