import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseService} from 'public-shared/services/base-http/base.service';
import {EnvironmentProvider} from 'public-shared/services/environment-provider';
import {Observable} from 'rxjs';
import {CustomerEnvironmentData} from '@jumio/portals.core';
import {CacheService} from 'shared/services/common/cache.service';
import {DialEndpoints} from 'shared/services/endpoint-constants';
import {SecurityContextHolder} from 'shared/services/security/security-context-holder';
@Injectable()
export class DialService extends BaseService {
  constructor(
    protected contextHolder: SecurityContextHolder,
    protected override http: HttpClient,
    protected environmentProvider: EnvironmentProvider<CustomerEnvironmentData>,
    protected cache: CacheService
  ) {
    super(http);
  }

  /**
   * Upload a ZIP file for Dial rules
   * @param body
   * @param password
   */
  public uploadDialRulesZip$(formData: FormData): Observable<void> {
    return this.put$<void>(DialEndpoints.BASE, formData);
  }
}
