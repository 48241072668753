import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {J4AlertComponent} from '@jumio/portals.core-ui';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {ColorPickerModule} from 'ngx-color-picker';
import {DomainValidatorDirective} from 'public-shared/directives/validators/domain-validator.directive';
import {ColorpickerComponent} from '../colorpicker/colorpicker.component';
import {NaturalNumberInputComponent} from '../natural-number-directive/natural-number-input.component';
import {NaturalNumberValidatorDirective} from '../natural-number-directive/natural-number-validator.directive';
import {IsEnabledDirective} from '../security/is-enabled.directive';
import {IsRolesDisabled} from '../security/is-roles-disabled.pipe';
import {FormGroupCheckboxComponent} from './form-group-checkbox/form-group-checkbox.component';
import {FormGroupInputComponent} from './form-group-input/form-group-input.component';
import {FormGroupSelectComponent} from './form-group-select/form-group-select.component';
import {FormGroupSwitchComponent} from './form-group-switch/form-group-switch.component';
import {FormGroupComponent} from './form-group.component';

@NgModule({
  imports: [
    J4AlertComponent,
    FormsModule,
    PopoverModule.forRoot(),
    IsEnabledDirective,
    DomainValidatorDirective,
    IsRolesDisabled,
    CommonModule,
    NaturalNumberInputComponent,
    NaturalNumberValidatorDirective,
    ColorPickerModule
  ],
  declarations: [
    FormGroupComponent,
    FormGroupSwitchComponent,
    FormGroupSelectComponent,
    FormGroupInputComponent,
    FormGroupCheckboxComponent,
    ColorpickerComponent
  ],
  exports: [
    FormGroupComponent,
    FormGroupSwitchComponent,
    FormGroupSelectComponent,
    FormGroupInputComponent,
    FormGroupCheckboxComponent,
    ColorpickerComponent
  ]
})
export class FormGroupModule {}
