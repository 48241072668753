import {Injectable} from '@angular/core';
import {fromEvent, merge, Observable, throwError, timer} from 'rxjs';
import {catchError, first, mergeMap, shareReplay, switchMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ExternalScriptsService {
  private readonly _retryDelay: number = 1000;
  private readonly _retryCount: number = 10;

  private readonly _scripts = new Map<HTMLScriptElement, Observable<Event>>();

  private loadWithRetry$(source: string, retryCount?: number): Observable<Event> {
    const currentRetryCount = (typeof retryCount === 'number' ? retryCount : this._retryCount) - 1;
    return this.load$(source).pipe(
      catchError(e => {
        if (currentRetryCount > 0) {
          return timer(this._retryDelay).pipe(mergeMap(() => this.loadWithRetry$(source, currentRetryCount)));
        } else {
          return throwError(e);
        }
      })
    );
  }

  private load$(source: string): Observable<Event> {
    const scriptElement = this._getScriptElement(source);

    return this._register$(scriptElement);
  }

  private _register$(element: HTMLScriptElement): Observable<Event> {
    if (this._scripts.has(element)) {
      //@ts-ignore
      return this._scripts.get(element);
    }

    this._scripts.set(element, this._createScriptListener$(element));
    this.head.appendChild(element);

    //@ts-ignore
    return this._scripts.get(element);
  }

  private _createScriptListener$(element: HTMLScriptElement): Observable<Event> {
    const sourceUrl = element.getAttribute('src');

    const load$ = fromEvent(element, 'load');
    const error$ = fromEvent(element, 'error').pipe(switchMap(error => throwError(error)));

    return merge(load$, error$).pipe(
      catchError(e => {
        console.error(`"${sourceUrl}" couldn't be loaded`);
        element.remove();
        this._scripts.delete(element);
        return throwError(e);
      }),
      first(),
      shareReplay()
    );
  }

  private _getScriptElement(source: string): HTMLScriptElement {
    const queriedElement = this.head.querySelector(`script[src="${source}"]`) as HTMLScriptElement;

    if (queriedElement) {
      return queriedElement;
    } else {
      const scriptElement = this.document.createElement('script');
      scriptElement.setAttribute('async', 'true');
      scriptElement.setAttribute('src', `${source}`);

      return scriptElement;
    }
  }

  private get document(): Document {
    return document;
  }

  private get head(): HTMLHeadElement {
    return this.document.getElementsByTagName('head')[0];
  }
}
