export class ErrorUrls {
  public static readonly BASE = 'error';
  public static readonly ERROR_404 = '404';
  public static readonly ERROR_403 = '403';
}

export class LoginUrls {
  public static readonly BASE = '';
  public static readonly LOGIN = 'login';
}
