import {Component, forwardRef, Input} from '@angular/core';
import {NgForm, NG_VALUE_ACCESSOR, FormsModule} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import {AbstractImageUploaderComponent} from 'shared/components/abstract-image-uploader/abstract-image-uploader.component';
import {ImageService} from 'shared/services/image/image.service';
import {FormGroupModule} from '../form-group/form-group.module';
import {CommonModule} from '@angular/common';
import {SecureBackgroundImagePipe} from 'shared/pipes/secure-background-image-pipe/secure-background-image.pipe';

@Component({
  selector: 'app-image-uploader',
  templateUrl: './image-uploader.component.html',
  styleUrls: ['./image-uploader.component.less'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ImageUploaderComponent),
      multi: true
    }
  ],
  standalone: true,
  imports: [FormGroupModule, FormsModule, CommonModule, SecureBackgroundImagePipe]
})
export class ImageUploaderComponent extends AbstractImageUploaderComponent {
  @Input() public label: string | undefined;
  @Input() public errorMessages: {[key: string]: string} | undefined;
  @Input() public buttonsHidden: boolean = false;
  @Input() public additionalComment: string | undefined;
  @Input() public override maxFileSize: number | undefined;

  constructor(
    protected override sanitizer: DomSanitizer,
    protected override ngForm: NgForm,
    protected override imageService: ImageService
  ) {
    super(sanitizer, ngForm, imageService);
  }

  public override writeValue(): void {}
}
