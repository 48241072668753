import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {InfoService} from 'public-shared/services/info/info.service';
import {EMPTY, Observable} from 'rxjs';
import {JumioPage} from 'shared/components/table/dto/jumio-page';
import {ExperimentEndpoints} from 'shared/services/endpoint-constants';
import {DatasetContent} from 'shared/services/experiments/dataset-content.enum';
import {
  Dataset,
  DatasetExportInfo,
  DatasetFilter,
  DatasetRemainingTimeResponse,
  DatasetResponse,
  DatasetUploadFinalizeDto,
  DatasetUploadStatusDto,
  ExperimentsByDatasetResponse,
  PreviewIndirectDataset,
  SimpleDataset
} from 'shared/services/experiments/datasets.dto';
import {ExperimentsService} from 'shared/services/experiments/experiments.service';
import {FilterService} from 'shared/services/filter.service';
import {SearchService} from 'shared/services/search.service';
import {SecurityContextHolder} from '../security/security-context-holder';
import {EventLog} from '../verification/details-dto/events-log.dto';
import {ExperimentDetail} from './experiments.dto';

@Injectable()
export class DatasetsService extends ExperimentsService implements FilterService, SearchService {
  constructor(protected override http: HttpClient, protected override infoService: InfoService, context: SecurityContextHolder) {
    super(http, infoService, context);
  }

  public getDataset$(id: string): Observable<DatasetResponse> {
    return this.get$<DatasetResponse>(ExperimentEndpoints.DATASET(id));
  }

  public getAllDataset$(): Observable<Array<Dataset>> {
    return this.get$<Array<Dataset>>(ExperimentEndpoints.DATASETS);
  }

  public getFilteredDatasets$(filter: DatasetFilter): Observable<JumioPage<Dataset>> {
    return this.post$<JumioPage<Dataset>>(ExperimentEndpoints.DATASETS_SEARCH, filter);
  }

  public updateDataset$(id: string, body: Partial<Dataset>): Observable<any> {
    return this.put$<any>(ExperimentEndpoints.DATASET(id), body);
  }

  public archiveDataset$(id: string): Observable<any> {
    return this.post$<any>(ExperimentEndpoints.DATASET_ARCHIVE(id), {});
  }

  public copyDatasetImages$(formData: FormData): Observable<any> {
    return this.post$<any>(ExperimentEndpoints.DATASET_COPY_IMAGES, formData);
  }

  public unarchiveDataset$(id: string): Observable<any> {
    return this.post$<any>(ExperimentEndpoints.DATASET_UNARCHIVE(id), {});
  }

  public getExprerimentsByDataset$(datasetId: string): Observable<ExperimentsByDatasetResponse[]> {
    return this.get$<ExperimentsByDatasetResponse[]>(ExperimentEndpoints.EXPERIMENTS_BY_DATASET(datasetId));
  }

  public getExperimentsByTargetDataset$(datasetId: string): Observable<ExperimentDetail> {
    return this.get$<ExperimentDetail>(ExperimentEndpoints.EXPERIMENT_BY_TARGET_DATASET(datasetId));
  }

  public deleteDataset$(experimentId: string): Observable<void> {
    return this.delete$(ExperimentEndpoints.DATASET(experimentId));
  }

  public cancelDataset$(experimentId: string): Observable<void> {
    return this.post$(ExperimentEndpoints.DATASET_CANCEL(experimentId), {});
  }

  public addRegressionScans$(formData: FormData): Observable<void> {
    return this.post$<void>(ExperimentEndpoints.ADD_REGRESSION_EVENT_SCANS, formData);
  }

  public deleteRegressionScans$(formData: FormData): Observable<void> {
    return this.post$<void>(ExperimentEndpoints.DELETE_REGRESSION_EVENT_SCANS, formData);
  }

  public addDirectDataset$(formData: FormData): Observable<any> {
    return this.post$<number>(ExperimentEndpoints.DIRECT_DATASETS, formData);
  }

  public addIndirectDataset$(indirectDataset: any): Observable<string> {
    return this.post$<string>(ExperimentEndpoints.INDIRECT_DATASETS, indirectDataset);
  }

  /**
   * Initializes a preview for indirect datasets
   * @return UUID
   */
  public previewIndirectDatasetInitCall$(sql: string): Observable<string> {
    return this.post$<string>(ExperimentEndpoints.PREVIEW_INDIRECT_DATASETS_ASYNC, sql);
  }

  /**
   * After the init of the dataset call is successful
   * this get call will be polled until it returns the list
   * @param id - UUID from the init call
   */
  public previewIndirectDataset$(id: string): Observable<PreviewIndirectDataset> {
    return this.get$<PreviewIndirectDataset>(ExperimentEndpoints.PREVIEW_INDIRECT_DATASETS(id));
  }

  public getDatasetExportInfo$(id: string): Observable<DatasetExportInfo> {
    return this.get$<DatasetExportInfo>(ExperimentEndpoints.DATASET_EXPORT_INFO(id));
  }

  public getDatasetExportDownload$(id: string, datasetContent: DatasetContent): Observable<Blob> {
    return this.getJson$(ExperimentEndpoints.DATASET_EXPORT_DOWNLOAD(id, datasetContent));
  }

  public datasetExport$(id: string): Observable<Blob> {
    return this.post$(ExperimentEndpoints.DATASET_EXPORT(id), {});
  }

  public datasetResetExport$(id: string): Observable<Blob> {
    return this.post$(ExperimentEndpoints.DATASET_RESET_EXPORT(id), {});
  }

  /**
   * Upload Step 1) Upload the dataset CSV
   * @param {string} body - CSV to upload
   * @returns {string} - The new dataset UUID
   */
  public datasetUpload$(body: FormData): Observable<string> {
    return this.post$(ExperimentEndpoints.DATASET_UPLOAD, body);
  }

  /**
   * Upload Step 2) Get the dataset upload status
   * @param {string} id  - New dataset UUID
   * @returns {DatasetUploadStatusDto} - The new dataset id and error/success message
   */
  public datasetUploadStatus$(id: string): Observable<DatasetUploadStatusDto> {
    return this.get$(ExperimentEndpoints.DATASET_UPLOAD_RESULT(id));
  }

  /**
   * Upload Step 3) Finalize the dataset upload
   */
  public datasetUploadFinalize$(id: string, body: DatasetUploadFinalizeDto): Observable<DatasetResponse> {
    return this.put$(ExperimentEndpoints.DATASET_UPLOAD_FINALIZE(id), body);
  }

  public getDatasetInputs$(id: string): Observable<Array<string>> {
    return this.get$<Array<string>>(ExperimentEndpoints.DATASET_INPUTS(id));
  }

  public getDatasetInputFile$(id: string, filename: string): Observable<Blob> {
    return this.getJson$(ExperimentEndpoints.DATASET_INPUT_FILE(id, filename));
  }

  public override filter$(): Observable<JumioPage<any>> {
    return EMPTY;
  }
  /**
   * Used by autocomplete component
   * @param query
   */
  public override search$(query: string): Observable<SimpleDataset[]> {
    return this.post$<SimpleDataset[]>(ExperimentEndpoints.AVAILABLE_DATASETS, new DatasetSearchQuery(query));
  }

  /**
   * Receives the array of events log details of a given entity from the server.
   * @param {string} reference The ID of the given entity.
   * @returns {Observable<EventLog[]>} An Observable of the array of event log elements.
   */
  public override getEventsLog$(reference: string): Observable<EventLog[]> {
    return this.http.get<EventLog[]>(
      (this.isSandboxEnabled ? ExperimentEndpoints.BASE_V3 : ExperimentEndpoints.BASE_V2) + ExperimentEndpoints.EVENTS_LOG(reference)
    );
  }

  /**
   * Receives the dataset remaining time to be created
   * @param {string} id The ID of the given entity.
   * @returns {Observable<DatasetRemainingTimeResponse>} An Observable of the dataset remaining time to be created.
   */
  public remainingTime$(id: string): Observable<DatasetRemainingTimeResponse> {
    return this.http.get<DatasetRemainingTimeResponse>(
      (this.isSandboxEnabled ? ExperimentEndpoints.BASE_V3 : ExperimentEndpoints.BASE_V2) + ExperimentEndpoints.DATASET_REMAINING_TIME(id)
    );
  }

  /**
   * Receives the remaining time for the dataset to be exported
   * @param {string} id The ID of the given entity.
   * @returns {Observable<DatasetRemainingTimeResponse>} An Observable of the remaining time for the dataset to be exported.
   */
  public remainingExportTime$(id: string): Observable<DatasetRemainingTimeResponse> {
    return this.http.get<DatasetRemainingTimeResponse>(ExperimentEndpoints.BASE_V3 + ExperimentEndpoints.DATASET_REMAINING_EXPORT_TIME(id));
  }
}

/**
 * A helper class to contain the search query field.
 */
export class DatasetSearchQuery {
  constructor(public name: string) {}
}
