export class FormGroupConstants {
  public static readonly EMAIL_ERROR = 'Wrong e-mail format!';
  public static readonly PATTERN_ERROR = 'Wrong format!';
  public static readonly OTHER_ERROR = 'Incorrect input';
  public static readonly OTHER_BACKEND_ERROR = 'Incorrect backend input';
  public static readonly DATE_RANGE_FORMAT_ERROR = 'Dates should be in YYYY-MM-DD format.';
  public static readonly DATE_RANGE_ORDER_ERROR = 'The order of the dates is incorrect.';
  public static readonly DATE_NO_PAST_ERROR = "The selected date can't be in the past.";
  public static readonly VALIDATION_ERROR_CODE = '1021';
  public static readonly PASSWORD_REQUIRED_ERROR = 'pwdRequired';
  public static readonly SECURITY_CODE_REQUIRED_ERROR = 'passCodeRequired';
  public static readonly WRONG_PASSWORD_ERROR = 'wrongPassword';
  public static readonly WRONG_SECURITYPASS_ERROR = 'wrongpassCode';
  public static readonly BACKEND_VALIDATION_ERROR = 'backendValidationFailed';

  public static readonly REQUIRED_ERROR = (label: string): string => `${label} is required!`;
}
