<div class="row fixed-bottom">
  <div class="col-lg-6 col-md-6 col-sm-12 col-lg-offset-3 col-md-offset-3" *ngFor="let notification of notifications">
    <alert class="mt-3 mr-3 ml-3 shadow-lg" [dismissible]="true" [type]="notification.type" (onClosed)="close(notification)">
      <div *ngIf="notification.message">
        {{ notification.message }}
      </div>
      <div *ngIf="isErrorDescriptor(notification)">
        An error occurred. Please contact the administrator with error id: <b>{{ notification.errorDescriptor?.id }}</b
        >.
        <a (click)="toggleDetials($event, notification)" class="text-danger">
          <span *ngIf="!notification.showDetails">[show details]</span>
          <span *ngIf="notification.showDetails">[hide details]</span>
        </a>
        <div class="data-block" *ngIf="notification.showDetails">
          <div class="data-row">
            <span class="data-label">ID: </span>
            <span class="value">{{ notification.errorDescriptor?.id | toDashesIfEmpty }}</span>
          </div>
          <div class="data-row">
            <span class="data-label">Code: </span>
            <span class="value">{{ notification.errorDescriptor?.errorCode | toDashesIfEmpty }}</span>
          </div>
          <div class="data-row">
            <span class="data-label">Status: </span>
            <span class="value">{{ notification.errorDescriptor?.status | toDashesIfEmpty }}</span>
          </div>
          <div class="data-row">
            <span class="data-label">Timestamp: </span>
            <span class="value">{{ notification.errorDescriptor?.timestamp | utcDateFormat | toDashesIfEmpty }}</span>
          </div>
          <div class="data-row">
            <span class="data-label">Message: </span>
            <span class="value"
              ><b>{{ notification.errorDescriptor?.error | toDashesIfEmpty }}</b>
            </span>
          </div>
        </div>
      </div>
    </alert>
  </div>
</div>
