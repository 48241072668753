import {Type} from '@angular/core';
import {Subscription} from 'rxjs';

const unsubscribe = (property: unknown): void => {
  if (property instanceof Subscription) {
    property.unsubscribe();
  }
};

const decorateNgOnDestroy = (ngOnDestroy: (() => void) | null | undefined) =>
  function <T>(this: Type<T> & {subscription: unknown}): void {
    // Invoke the original `ngOnDestroy` if it exists
    if (ngOnDestroy) {
      ngOnDestroy.call(this);
    }

    if (this.hasOwnProperty('subscription')) {
      //@ts-ignore
      unsubscribe(this['subscription']);
    }
  };

const decorateProviderDirectiveOrComponent = <T>(type: Type<T>): void => {
  type.prototype.ngOnDestroy = decorateNgOnDestroy(type.prototype.ngOnDestroy);
};

/**
 * Decorator that automatically unsubscribes from all subscriptions when the component is destroyed.
 * The component must have a subscription property with a type of Subscription or the compiler will throw an error.
 *
 * Example: @UntilDestroy()
 */
export const UntilDestroy =
  () =>
  <T>(type: Type<T extends ClassWithSubscription ? T : never>): void => {
    decorateProviderDirectiveOrComponent(type);
  };

interface ClassWithSubscription {
  subscription: Subscription;
}
