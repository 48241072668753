import {Injectable} from '@angular/core';
import {InfoMessage} from 'public-shared/components/info/info-message';
import {Observable, Subject} from 'rxjs';
import {AbstractNotificationService} from '../abstract-notification/abstract-notification.service';

@Injectable()
/**
 * Basic service for showing the information box that shows up after a modification request.
 */
export class InfoService extends AbstractNotificationService {
  /**
   * The Subject that holds the information text.
   * @type {Subject<string>}
   */
  private infoSubject = new Subject<InfoMessage | null>();

  /**
   * Shows the currently loaded info.
   * @param {string} info
   * @param isError
   * @param url
   * @param title
   */
  public show(info: string, isError = false, url: string | null = null, title: string | null = null): void {
    this.infoSubject.next(new InfoMessage(info, isError, url, title));
  }

  public showNeutral(info: string): void {
    this.infoSubject.next(new InfoMessage(info, false));
  }

  public showSuccess(info: string): void {
    this.infoSubject.next(new InfoMessage('Success! ' + info, false));
  }

  public showError(info: string): void {
    this.infoSubject.next(new InfoMessage(info, true));
  }

  public showWarning(info: string): void {
    this.infoSubject.next(new InfoMessage('Warning! ' + info, false));
  }

  public showInfo(info: string): void {
    this.infoSubject.next(new InfoMessage(info, false));
  }

  /**
   * Hides the currently loaded info.
   */
  public hide(): void {
    this.infoSubject.next(null);
  }

  /**
   * Returns the Subject as an Observable.
   * @returns {Observable<string>} The Subject as an Observable.
   */
  public getInfo$(): Observable<InfoMessage | null> {
    return this.infoSubject.asObservable();
  }
}
