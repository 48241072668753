import {Component, ErrorHandler, OnInit, ViewChild, ViewContainerRef, inject} from '@angular/core';
import {ServiceUnavailableService} from '@jumio/portals.core-ui';
import {ErrorDescriptor} from 'public-shared/models/error/error-descriptor';
import {AuthGlobalErrorHandler} from './services/auth-global-error-handler';

@Component({
  selector: 'app-customer-root',
  templateUrl: './customer-app-root.component.html'
})
export class CustomerAppRootComponent implements OnInit {
  @ViewChild('serviceUnavailable', {read: ViewContainerRef, static: true}) private serviceUnavailablePlaceholder:
    | ViewContainerRef
    | undefined;
  private serviceUnavailableService = inject(ServiceUnavailableService);
  private errorHandler = inject(ErrorHandler);

  ngOnInit(): void {
    this.serviceUnavailableService.register(this.serviceUnavailablePlaceholder);
    (this.errorHandler as AuthGlobalErrorHandler).unavailableServiceSubject?.subscribe((_error: ErrorDescriptor) => {
      this.serviceUnavailableService.show();
    });
  }
}
