import {Component, ElementRef, HostListener, Input, OnInit, ViewChild} from '@angular/core';
import {UntilDestroy} from '@jumio/portals.core';
import {Subject, Subscription} from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.less']
})
export class DropdownComponent implements OnInit {
  @Input() public type = 'normal';
  @Input() public direction = 'down';
  @Input() public closeTrigger: Subject<void> | undefined;
  @ViewChild('dropdownElement') public dropdownElement: ElementRef | undefined;

  public isOpen = false;
  public readonly subscription = new Subscription();

  ngOnInit(): void {
    if (this.closeTrigger) {
      this.subscription.add(
        this.closeTrigger.subscribe(() => {
          this.closeDropdown();
        })
      );
    }
  }

  public closeDropdown(): void {
    this.isOpen = false;
  }

  public openDropdown(): void {
    this.isOpen = true;
  }

  @HostListener('document:click', ['$event.target'])
  public onClick(targetElement: HTMLElement): void {
    const clickedInside = this.dropdownElement?.nativeElement?.contains(targetElement);
    if (!clickedInside && this.isOpen) {
      const datepickerClick =
        document.getElementsByClassName('daterangepicker')[0] &&
        document.getElementsByClassName('daterangepicker')[0].contains(targetElement);
      if (!datepickerClick) {
        this.closeDropdown();
      }
    }
  }
}
