import {Injectable, OnDestroy, inject} from '@angular/core';
import {WINDOW} from '@jumio/portals.core';
import {Subject, Subscription, of} from 'rxjs';
import {delay, take, tap} from 'rxjs/operators';

@Injectable()
export class ForceReloadService implements OnDestroy {
  private readonly window = inject(WINDOW);
  private readonly RELOAD_DELAY = 5000;
  private readonly reloadSubscription = new Subscription();
  private readonly forceReloadSubject = new Subject<void>();

  public reloadTriggered$ = this.forceReloadSubject.asObservable();

  public reload(): void {
    this.forceReloadSubject.next();
    this.reloadSubscription.add(
      of(undefined)
        .pipe(
          delay(this.RELOAD_DELAY),
          tap(() => {
            this.reloadBrowser();
          }),
          take(1)
        )
        .subscribe()
    );
  }

  ngOnDestroy(): void {
    this.reloadSubscription.unsubscribe();
  }

  private reloadBrowser(): void {
    this.window.location.reload();
  }
}
