import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

interface FileUploadModel {
  data: File;
  progress: number;
}

@Injectable()
export class FileUploadCleanupService {
  fileCleanup$ = new Subject<FileUploadModel>();
  allFilesCleanup$ = new Subject<boolean>();

  public cleanFile(file: FileUploadModel): void {
    this.fileCleanup$.next(file);
  }

  public allFilesCleanup(): void {
    this.allFilesCleanup$.next(true);
  }
}
