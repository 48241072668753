import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {BaseService} from 'public-shared/services/base-http/base.service';
import {Observable} from 'rxjs';
import {AccountsEndpoints} from '../endpoint-constants';
import {WorkflowDetail} from '../workflow-executions/workflow-executions.interfaces';

@Injectable()
export class AccountsService extends BaseService {
  constructor(protected override http: HttpClient) {
    super(http);
    this.baseUrl = AccountsEndpoints.BASE;
  }

  public getWorkflows$(id: string): Observable<WorkflowDetail[]> {
    return this.get$(AccountsEndpoints.WORKFLOWS(id));
  }
}
