import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {EntityWithPassword} from 'public-shared/services/base-http/base.service';
import {Observable} from 'rxjs';
import {OAuthCredentialsEndpoints} from '../endpoint-constants';
import {ApiTokenActivation, ApiTokenCreation, ApiTokenDeactivation, IApiTokens} from './api-tokens.dto';
import {BaseCredentialsService} from './base-credentials.service';

@Injectable()
export class OAuthCredentialsService extends BaseCredentialsService {
  constructor(protected override http: HttpClient) {
    super(http);
    this.baseUrl = OAuthCredentialsEndpoints.BASE;
  }

  public getApiTokens$(): Observable<IApiTokens> {
    return this.get$('');
  }

  public activateApiToken$(request: ApiTokenActivation, password: string, passcode?: string): Observable<any> {
    return this.post$<any>(OAuthCredentialsEndpoints.ACTIVATE_API_TOKEN, new EntityWithPassword(request, password, passcode));
  }

  public deactivateApiToken$(request: ApiTokenDeactivation, password: string, passcode?: string): Observable<any> {
    return this.post$<any>(OAuthCredentialsEndpoints.DEACTIVATE_API_TOKEN, new EntityWithPassword(request, password, passcode));
  }

  public createApiToken$(request: ApiTokenCreation, password: string, passcode?: string): Observable<any> {
    return this.post$<any>(OAuthCredentialsEndpoints.CREATE_API_TOKEN, new EntityWithPassword(request, password, passcode));
  }
}
