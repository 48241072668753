import {Injectable} from '@angular/core';

@Injectable()
export class HeaderService {
  private _title: string | undefined;

  public getTitle(): string | undefined {
    return this._title;
  }

  public setTitle(title: string): void {
    this._title = title;
  }
}
